import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'main-app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  isMenuOpen: boolean = false;
  currentPageUrl = this.router.url;
  constructor(private router: Router) {}
  scrollToPlans(){
    if (this.currentPageUrl !== '/') {
      this.router.navigateByUrl('/').then(() => {
        window.scroll({ 
          top: 3550, 
          left: 0, 
          behavior: 'smooth' 
        });
      }).catch(error => {
        console.error('Navigation error:', error);
      });
    } else {
      window.scroll({ 
        top: 3550, 
        left: 0, 
        behavior: 'smooth' 
      });
    }
  }
  scrollToDownloads(){

    if (this.currentPageUrl !== '/') {
      this.router.navigateByUrl('/').then(() => {
        window.scroll({ 
          top: 4000, 
          left: 0, 
          behavior: 'smooth' 
        });
      }).catch(error => {
        console.error('Navigation error:', error);
      });
    } else {
      window.scroll({ 
        top: 4000, 
        left: 0, 
        behavior: 'smooth' 
      });
    }
  }
  scrollToContacts(){
  
    if (this.currentPageUrl !== '/') {
      this.router.navigateByUrl('/').then(() => {
        window.scroll({ 
          top: 8000, 
          left: 0, 
          behavior: 'smooth' 
        });
      }).catch(error => {
        console.error('Navigation error:', error);
      });
    } else {
      window.scroll({ 
        top: 8000, 
        left: 0, 
        behavior: 'smooth' 
      });
    }
  }
  scrollToDiv(divId: string): void {
    const element = document.getElementById(divId);
    
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      console.error(`Element with ID '${divId}' not found.`);
    }
  }
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
}
