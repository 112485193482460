import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as AOS from 'aos';
import { TK_CONFIG, Config, AcceptJSService, CreditCard } from '@openutility/acceptjs-angular-wrapper';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs/internal/Subscription';

import { Observable } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  showScrol = false
  constructor(private router: Router, private _acceptJSSrv: AcceptJSService, private ngCookiesService: NgcCookieConsentService) {
    setTimeout(() => { AOS.refresh(); }, 500);
    this.router.events.subscribe(event => {
      if (event.constructor.name === "NavigationEnd") {
        // do something...
        if (router.url == '/') {
          this.showScrol = true
        } else {
          this.showScrol = false
        }
      }
    });
  }
  async onSubmit(cc: CreditCard): Promise<void> {
    try {
      const nonce = await this._acceptJSSrv.generatePaymentNonce(cc);
      // submit nonce to your server with payment amount
    } catch (ex) {
      console.error(ex);
    }
  }

  index = 1
  section = ['container', 'section', 'second-grid-img', 'section3', 'section4']

  ngOnInit() {
    AOS.init({
      // Global settings
      delay: 100,
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      once: true, // whether animation should happen only once - while scrolling down
      mirror: true, // whether elements should animate out while scrolling past them

    });
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ngCookiesService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
        this.ngCookiesService.getConfig().content
      });

    this.popupCloseSubscription = this.ngCookiesService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.initializeSubscription = this.ngCookiesService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.statusChangeSubscription = this.ngCookiesService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.ngCookiesService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.noCookieLawSubscription = this.ngCookiesService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
  

  scrollTo(className: string): void {
    if (this.index == 5) {
      this.index = 1

      const elementList = document.querySelectorAll('.' + 'video-wrapper');
      const element = elementList[0] as HTMLElement;
      element.scrollIntoView({ behavior: 'smooth' });
    } else {

      const elementList = document.querySelectorAll('.' + this.section[this.index]);
      const element = elementList[0] as HTMLElement;
      element.scrollIntoView({ behavior: 'smooth' });
      this.index = this.index + 1
    }

  }
}
