<div class="wrapper" *ngIf="thankyou">
  <div class="thankyou-bg">
    <div class="logo pointer">
      <img (click)="goTOHome()" src="./assets/images/logo.png" />
      <span style="margin-top: 20px !important; margin-left: 90px !important" class="trademark2">™</span>
    </div>
    <div class="thankyou-box box-shadow">
      <img src="./assets/images/thankyou-img.png" />
      <p *ngIf="!individualPlan">Thank you for verifying, you can now use the Titanium™-Secure application! To manage your plan and users please Sign in.</p>
      <p *ngIf="individualPlan">Thank you for verifying, you can now use the Titanium™-Secure application!.</p>
      <div>
        <button [routerLink]="[ '/login' ]" style="background-color: #ee0000; margin-bottom: 25px;" routerLinkActive="active"
                class="btn btn-lg btn-outline-primary">
          Sign In
        </button>
      </div>
      <div class="download-btn-group">
        <a href="https://apps.apple.com/us/app/titanium-secure-messaging/id6455696374" target="_black" style="margin-top: 15px;" class="btn btn-blue pointer"><i class="fab fa-apple"></i> iOS (Beta)</a>
        <a href="https://www.titaniumsecure.io:3000/download/getlatestapk" target="_black" style="margin-top: 15px;" class="btn btn-blue pointer"><i class="fab fa-android"></i> Android (APK)</a>
        <a href="https://play.google.com/store/apps/details?id=io.titanium" target="_black" style="margin-top: 15px;" class="btn btn-blue pointer"><i class="fab fa-google-play"></i> Android (Google Play)</a>
        <a href="https://www.titaniumsecure.io:3000/download/getlatestmac" target="_black" style="margin-top: 15px;" class="btn btn-blue pointer"><i class="fab fa-app-store-ios"></i> MacOS</a>
        <a href="https://www.titaniumsecure.io:3000/download/getlatestwin" target="_black" style="margin-top: 15px;" class="btn btn-blue pointer"><i class="fab fa-windows"></i> Windows</a>
      </div>
    </div>
  </div>
</div>
<div class="wrapper" *ngIf="error">
  <div class="thankyou-bg">
    <div class="logo pointer">
      <img (click)="goTOHome()" src="./assets/images/logo.png" />
      <span style="margin-top: 20px !important; margin-left: 90px !important" class="trademark2">™</span>
    </div>
    <div class="thankyou-box box-shadow">
      <img src="" />
      <p>User already verified or invalid token!</p>
      <div class="download-btn-group">
      </div>
    </div>
  </div>
</div>
