import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/services/user.service';
import { UserAuthService } from '../shared/services/auth/user-auth.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { from } from 'rxjs';
@Component({
  selector: 'app-dashboard-embedded-initial',
  templateUrl: './dashboard-embedded-initial.component.html',
  styleUrls: ['./dashboard-embedded-initial.component.css']
})
export class DashboardEmbeddedInitialComponent implements OnInit {
phone_number: string;
fam_password: string;
error: string;


  constructor(private userService : UserService, private userAuth : UserAuthService, route: ActivatedRoute, private ngxloader: NgxUiLoaderService, private router: Router
    , private ccService: NgcCookieConsentService) {
    this.phone_number = route.snapshot.params.phone;
    this.fam_password = route.snapshot.params.password;
    from(this.userService.dashboardAutoLogin(this.phone_number, this.fam_password)).subscribe(
      (resp: any) => {
        this.ngxloader.stop(); // Stop the loader
        if (resp.message === 'Login successful!') {
          this.router.navigate(['/dashboard-embedded']);
        } else {
          this.error = resp.message;
        }
      },
      (err) => {
        this.ngxloader.stop(); // Stop the loader
        this.error = 'Invalid Password!';
      }
    );
   
  }
  ngOnInit(): void {
    this.ccService.destroy();
  }

}
