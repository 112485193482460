// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//const baseUrl = 'http://192.168.15.163:5500'; // local stable
const baseUrl = 'https://api.authorize.net/xml/v1/request.api'; // Dev stable
const baseAPIUrl = 'https://www.titaniumsecure.io:3000' // live
const flexiAPIUrl = 'https://www.titaniumsecure.io:3001/api/user/'
//const baseUrl = 'https://titaniumsecure.io:5500' // live 
export const environment = {
  production: true,
  baseUrl,
  baseApiUrl: `${baseAPIUrl}/api/`,
  //flexiAPIUrl:`${flexiAPIUrl}/api/user/`,
  apiSecretKey: 'acwa3pdd2k5eluh5',
  flexiApiURL: `${flexiAPIUrl}`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
