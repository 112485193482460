import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

declare function phoneOrEmail(): any;

@Component({
  selector: 'app-cheat-sheet',
  templateUrl: './cheat-sheet.component.html',
  styleUrls: ['./cheat-sheet.component.css']
})
export class CheatSheetComponent implements OnInit {

  phoneOption: boolean = false;
  phoneCheck = "checked";
  emailCheck: string;

  constructor(private _location: Location, private router: Router, private ccService: NgcCookieConsentService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.phoneOption = phoneOrEmail(), 1000
    });
    this.ccService.destroy();
  }


  backClicked() {
    if (this.router.navigated) {
      this._location.back();
    } else {
      this.router.navigate(['/'])
    }

  }

  selectedOption() {
    this.phoneOption = phoneOrEmail()
    if (this.phoneOption == true) {
      this.phoneCheck = "checked";
      this.emailCheck = '';
    }
    else {
      this.emailCheck = "checked";
      this.phoneCheck = '';
    }
  }
}
