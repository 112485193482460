import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserAuthService } from '../auth/user-auth.service';
import { ToastrService } from 'ngx-toastr';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Observable, generate, throwError } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SHA256 } from 'crypto-js';
@Injectable()
export class CommonTelesignHttpClient {
    private httpOptions;
    private logsReqResData = true; // Make it True For Debugging purpose only
    constructor(private http: HttpClient,
        private userAuthService: UserAuthService,
        private toastr: ToastrService,
        private router: Router,
        private ngxService: NgxUiLoaderService,
    ) {


    }

    private async _configHeaders() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Accept', 'application/json');
        headers = headers.set('Authorization', 'Basic OTI3N0UzQzUtMkFDQi00MDk2LTgwNTgtNThBRTU5MTVGNkEyOjRPRFUvdDZjMlV1ampjdmxNVUZPQ0Q4VTN6QjNpeHBHR3p2T2hyb2RNcDZWOE1BWldnRGF3ODZNS3gwbHh0VmFjL0hWTWIwQm5LTUtKRlJTcE5Uck13PT0='
        );
        headers = headers.set('Access-Control-Allow-Origin', '*');

        this.httpOptions = { headers };
    }


    post(url, data, showToastr = false) {
        this._configHeaders();

        // With Ecryption
        return this.http.post(url, data, this.httpOptions)
            .pipe(
                // Without Ecryption
                // return this.http.post(this._baseApiUrl + url,  data , this.httpOptions).pipe(
                tap((resp: any) => {
                    if (resp.status === 'success' && showToastr) {
                        this.showToastr('success', resp.message, '');
                    } else if (resp.status === 'failed' && showToastr) {
                        this.showToastr('error', resp.message, '');
                    }
                    this.consoleLogData({ method: 'POST', url, data, resp });
                }),
                catchError(err => {
                    this.handleError(err, showToastr);
                    this.consoleLogData(err);
                    return throwError(err);
                })
            );
    }


    handleError(error, showToastr) {
        this.ngxService.stop();
        if (showToastr) {
            this.showToastr('error', error.message, error.status);
        }
        this.userAuthService.removeUser();
        if (error.status === 401) {
            this.router.navigate(['/login']);
        } else if (error.status === 500) {
            this.router.navigate(['/server-error']);
        } else if (error.status === 0) {
            this.router.navigate(['/server-error']);
        }
    }
    handleErrorSpecific(error, showToastr) {
        this.ngxService.stop();
        if (showToastr) {
            this.showToastr('error', error.message, error.status);
        }
        if (error.status === 401) {
            this.router.navigate(['/login']);
        } else if (error.status === 500) {
            this.router.navigate(['/server-error']);
        } else if (error.status === 0) {
            this.router.navigate(['/server-error']);
        }
    }

    consoleLogData(data) {
        if (this.logsReqResData) {
            // console.log('------ API LOGS -----', data);
        }
    }

    showToastr(type, msg, title) {
        this.toastr[type](msg, title, {
            progressBar: true,
            progressAnimation: 'increasing',
            timeOut: 1500,
            positionClass: 'toast-bottom-right',
        });
    }
}
