import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserAuthService } from './auth/user-auth.service';
import { ToastrService } from 'ngx-toastr';
;
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonTelesignHttpClient } from './common/common-telesign-http.service';




@Injectable()
export class TelesignService {
    private logsReqResData = true; // Make it True For Debugging purpose only

    constructor(private http: HttpClient,
        private userAuthService: UserAuthService,
        private toastr: ToastrService,
        private router: Router,
        private ngxService: NgxUiLoaderService,
        private thttp: CommonTelesignHttpClient
    ) {}

    silentVerifyInit = () => {
        return this.thttp
          .post("https://verify.telesign.com/silent/initiate", {phone_number: "+359877423272"}, false
     
          )  
      }

    handleError(error, showToastr) {
        this.ngxService.stop();
        if (showToastr) {
            this.showToastr('error', error.message, error.status);
        }
        this.userAuthService.removeUser();
        if (error.status === 401) {
            this.router.navigate(['/login']);
        } else if (error.status === 500) {
            this.router.navigate(['/server-error']);
        } else if (error.status === 0) {
            this.router.navigate(['/server-error']);
        }
    }
    handleErrorSpecific(error, showToastr) {
        this.ngxService.stop();
        if (showToastr) {
            this.showToastr('error', error.message, error.status);
        }
        if (error.status === 401) {
            this.router.navigate(['/login']);
        } else if (error.status === 500) {
            this.router.navigate(['/server-error']);
        } else if (error.status === 0) {
            this.router.navigate(['/server-error']);
        }
    }

    consoleLogData(data) {
        if (this.logsReqResData) {
            // console.log('------ API LOGS -----', data);
        }
    }

    showToastr(type, msg, title) {
        this.toastr[type](msg, title, {
            progressBar: true,
            progressAnimation: 'increasing',
            timeOut: 1500,
            positionClass: 'toast-bottom-right',
        });
    }
}
