<div class="wrapper">
  <div class="payment-header">
    <div class="payment-logo">
      <img src="./assets/images/logo.png" />
      <span style="padding-top: 0px !important; font-size: 0.5rem !important; margin-left: 45px !important; margin-top:5px!important" class="trademark">™</span>
      <h3>Manage Plan</h3>
    </div>
  </div>
  <div class="container">
    <div *ngIf="startSection" class="trial-getstarted-section section3">
      <div *ngIf="planName === 'Dependant' ">
        <h3>Current plan: <br><span>Dependant user</span></h3>
        <div class="banner-btn-container">
          <p>Add users at any time</p>
          <a data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" (click)="upgradePlan()"
            routerLinkActive="active" class="btn btn-orange banner-btn pointer">Change Plan</a>
        </div>
      </div>
      <div *ngIf="planName != 'Dependant'">
        <h3>Current plan: <br><span>{{ totalSeatsGET }} seats at ${{ pricePerSeat }} per seat</span></h3>
        <div class="banner-btn-container">
          <p>Add users at any time</p>
          <a data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" (click)="upgradePlan()"
            routerLinkActive="active" class="btn btn-orange banner-btn pointer">Change Plan</a>
        </div>
      </div>
      <!-- <div>
        <p>Billing on: <br>{{billingDate}}th every month</p>
      </div> -->
    </div>
  </div>
  <div class="thankyou-bg privacy-policy-bg">
    <div class="logo"><a [routerLink]="['/dashboard']" style="visibility: hidden;"><img
          src="./assets/images/logo.png" /></a>
    <span style="margin-top:-20px" class="trademark2">™</span>
    </div>
    <div class="backlink pointer"><a (click)="backClicked()"><i class="fas fa-chevron-left"></i>Dashboard</a></div>
    <div *ngIf="addUser" class="thankyou-box box-shadow privacy-policy-box">
      <div class="map-form">
        <div>
          <div class="table_padding">
            <input type="text" id="searchInput" onkeyup="searchFunction()" placeholder="Search..">
            <table id="myTable" class="responsive-table mat-elevation-z8">
              <thead>
                <tr id="headerTable" class="table-head-lable">
                  <th class="headerSortDown" onclick="sortTableDateAdded()" style="cursor:pointer" scope="col">Date
                    added</th>
                  <th class="headerSortDown" onclick="sortTableExpDate()" style="cursor:pointer" scope="col">Exp Date
                  </th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Active</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of paginatedData"> 
                  <td data-title="Date added">{{user.date_added | date: 'shortDate' }}</td>
                  <td *ngIf="user.date_expire != null else elseBlock" data-title="Exp date">{{user.date_expire | date:
                    'shortDate'}}</td>
                  <ng-template #elseBlock>
                    <td data-title="Exp Date">Ongoing</td>
                  </ng-template>
                  <td data-title="First Name">{{user.first_name}}</td>
                  <td data-title="Last Name">{{user.last_name}}</td>
                  <td data-title="Email">{{user.email_id}}</td>
                  <td data-title="Phone Number">{{user.phone_number.internationalNumber}}</td>
                  <td [ngClass]="{'active-yes': user.is_active, 'active-no': !user.is_active}" data-title="Is Active">
                    {{ user.is_active ? 'YES' : 'NO' }}
                  </td>
                  <td><button class="btn btn-orange submitBtn mobile-btn tablet-width"
                      (click)="onEdit(user)">Edit</button></td>
                  <td>
                    <button class="btn btn-orange submitBtn mobile-btn tablet-width" (click)="confirmDelete(user)">Delete</button>
                    </td>
                </tr>
              </tbody>
            </table>
            <div class="toolbar">
              <div class="toolbar-text"><strong>{{paginatedData.length}} / {{totalSeatsGET}} users</strong></div>
              <div *ngIf="paginatedData.length >= totalSeatsGET" class="toolbar-text">Seats are full! To add more users
                please free up seats from current users.</div>
            </div>
            <div class="pagination">
              <div class="pagination-left">
                <div class="pagination-counter">Size:</div>
                <select class="form-select" [(ngModel)]="itemsPerPage" (change)="changePageSize($event)">
                  <option *ngFor="let size of pageSizes" [value]="size">{{ size }}</option>
                </select>
              </div>
              <div class="pagination-right">
                <div class="pagination-counter">Page: {{ currentPage }}/{{ totalPages }}</div>
                <button class="btn btn-lg btn-outline-primary" (click)="previousPage()"
                  [disabled]="currentPage === 1">Previous</button>
                <button class="btn btn-lg btn-outline-primary" (click)="nextPage()"
                  [disabled]="currentPage === totalPages">Next</button>
              </div>

            </div>
            <button style="font-size: 1.2rem;" class="btn btn-orange submitBtn mobile-btn" (click)="openModal()"
              [disabled]="isSubmitBtnDisabled">Add User</button>
          </div>
        </div>
      </div>

      <!-- <div *ngIf="addUserPop" class="modal-backdrop" (click)="closeModal()"></div> -->
      <div *ngIf="addUserPop" class="modal" (click)="$event.stopPropagation()">
        <div class="addFormBackground" data-backdrop="static" data-keyboard="false">
          <button (click)="closeModal()" class="close-button">X</button>
          <h3 class="topText" style="color:red">Please enter user details.</h3>
          <div class="form-group plan-box-shadow">
            <div id="emailOrPhoneCheck">
              <form autocomplete="on" [formGroup]="userForm" id="signup-form">
                <ul>
                  <li>
                    <input [ngClass]="(signSubmit && f['first_name']?.errors?.required ? 'form-error' : '')" type="text"
                      formControlName="first_name" name="first_name" placeholder="First Name*" />
                  </li>
                  <li>
                    <input [ngClass]="(signSubmit && f['last_name']?.errors?.required ? 'form-error' : '')" type="text"
                      formControlName="last_name" name="last_name" placeholder="Last Name*" />
                  </li>
                  <li class="country-dropdown">
                    <ngx-intl-tel-input
                      [ngClass]="(signSubmit && f['phone_number']?.errors?.validatePhoneNumber ? 'form-error' : '')"
                      [cssClass]="'custom'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true" [customPlaceholder]="'Phone Number*'" [searchCountryFlag]="true"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                      [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15"
                      [phoneValidation]="true" [separateDialCode]="separateDialCode"
                      [numberFormat]="PhoneNumberFormat.International" name="phone" formControlName="phone_number">
                    </ngx-intl-tel-input>
                  </li>
                  <li *ngIf="f.phone_number.touched && f.phone_number.invalid">
                    <div *ngIf="f.phone_number.hasError('required') || f.phone_number.hasError('validatePhoneNumber')">
                      <div class="text-danger">
                        Invalid Phone Number!
                      </div>
                    </div>
                  </li>
                  <li id="showEmail">
                    <input
                      [ngClass]="(signSubmit && f['email_id']?.errors?.required || signSubmit && f['email_id']?.errors?.email ? 'form-error' : '')"
                      type="email" formControlName="email_id" name="email_id" placeholder="Email Address*" email />
                  </li>
                  <li>
                    <input type="number" formControlName="expiry_date_days" name="expiry_date_days" max="90"
                      placeholder="Days to expire" />
                  </li>
                  <li *ngIf="f.expiry_date_days.touched || f.expiry_date_days.invalid">
                    <div *ngIf="errorExpDays">
                      <div class="text-danger">
                        {{errorExpDays}}
                      </div>
                    </div>
                  </li>
                  <li *ngIf="error" class="show__alert__message" style="padding-bottom: 5px;">
                    <div class="alert alert-danger">
                      {{error}}
                    </div>
                  </li>
                  <li *ngIf="!editUser" class="login-form">
                    <input type="submit" autocomplete="off" [disabled]="isSubmitBtnDisabled || !userForm.valid"
                      (click)="verifyUserSubscription()" class="btn btn-orange submitBtn" name="submit"
                      value="Confirm" />
                  </li>
                  <li *ngIf="editUser" class="login-form">
                    <input type="submit" autocomplete="off" [disabled]="isSubmitBtnDisabled || !userForm.valid"
                      (click)="confirmUserEdit()" class="btn btn-orange submitBtn" name="submit" value="Confirm" />
                  </li>
                </ul>
              </form>
              <form [formGroup]="userForm">
                <div ngModelGroup="userData"></div>
              </form>
              <hr>
              <span style="font-size:11px">
                *Days to expire refers to after how many days the created temporary users will expire, the field is not
                required.
              </span>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="deleteUserPop" class="modal-delete" (click)="cancelDelete()">
      <div class="modal-content" (click)="$event.stopPropagation()">
        <button (click)="cancelDelete()" class="close-button">X</button>
        <h3 class="topText" style="color:red">Are you sure you want to delete this user?</h3>
        <div class="modal-footer">
          <button (click)="onDelete()" class="btn btn-danger btn">Yes</button>
          <button (click)="cancelDelete()" class="btn btn-secondary btn">Cancel</button>
        </div>
      </div>
    </div>
    <div>
      <div *ngIf="upgradePlanCheck" class="thankyou-box box-shadow privacy-policy-box">
        <div class="map-form">
          <div class="login-form-group" [@slideInOut]>
            <div class="form-group">
              <div id="emailOrPhoneCheck">
                <form autocomplete="on" [formGroup]="planForm" id="signup-form">
                  <ul>
                    <li class="plan-details plan-padding" style="display:grid">
                      <!-- <h3 class="topText" style="color: red;">Current subscription {{totalSeatsGET}} at {{pricePerAdditionalSeat}}$ per seat.</h3> -->
                      <h3 class="topText" style="color: red;">Select a plan</h3>
                      <p class="downgrade-label">To downgrade a plan please empty seats to the desired plan range!</p>

                      <select formControlName="plan_option"
                        [ngClass]="(fp['plan_option']?.errors?.required ? 'form-error' : '')">
                        <option value="" disabled>Plan type</option>
                        <option value="Individual" [disabled]="ind_check">Individual</option>
                        <option value="2-4 users" [disabled]="second_check">2-4 users/$5 per seat</option>
                        <option value="5-50 users" [disabled]="third_check">5-50 users/$4 per seat</option>
                        <option value="51-300 users" [disabled]="fourth_check">51-300 users/$3 per seat</option>
                        <option value="301-unlimited users" [disabled]="fifth_check">301-unlimited users/$2 per seat
                        </option>
                      </select>
                      <h3 class="topText" style="color: red;">Number of seats</h3>
                      <select formControlName="number_seats"
                        [ngClass]="(fp['number_seats']?.errors?.required ? 'form-error' : '')">
                        <option value="" disabled selected>Seats</option>
                        <option *ngIf="individualPlan" value="0">0</option>
                        <option *ngIf="familyOrSmallOfficePlan" value="2">2</option>
                        <option *ngIf="familyOrSmallOfficePlan" value="3">3</option>
                        <option *ngIf="familyOrSmallOfficePlan" value="4">4</option>
                        <option *ngIf="smallBusinessPlan" value="5">5</option>
                        <option *ngIf="smallBusinessPlan" value="6">6</option>
                        <option *ngIf="smallBusinessPlan" value="7">7</option>
                        <option *ngIf="smallBusinessPlan" value="8">8</option>
                        <option *ngIf="smallBusinessPlan" value="9">9</option>
                        <option *ngIf="smallBusinessPlan" value="5">5</option>
                        <option *ngIf="smallBusinessPlan" value="6">6</option>
                        <option *ngIf="smallBusinessPlan" value="7">7</option>
                        <option *ngIf="smallBusinessPlan" value="8">8</option>
                        <option *ngIf="smallBusinessPlan" value="9">9</option>
                        <option *ngIf="smallBusinessPlan" value="10">10</option>
                        <option *ngIf="smallBusinessPlan" value="11">11</option>
                        <option *ngIf="smallBusinessPlan" value="12">12</option>
                        <option *ngIf="smallBusinessPlan" value="13">13</option>
                        <option *ngIf="smallBusinessPlan" value="14">14</option>
                        <option *ngIf="smallBusinessPlan" value="15">15</option>
                        <option *ngIf="smallBusinessPlan" value="16">16</option>
                        <option *ngIf="smallBusinessPlan" value="17">17</option>
                        <option *ngIf="smallBusinessPlan" value="18">18</option>
                        <option *ngIf="smallBusinessPlan" value="19">19</option>
                        <option *ngIf="smallBusinessPlan" value="20">20</option>
                        <option *ngIf="smallBusinessPlan" value="21">21</option>
                        <option *ngIf="smallBusinessPlan" value="22">22</option>
                        <option *ngIf="smallBusinessPlan" value="23">23</option>
                        <option *ngIf="smallBusinessPlan" value="24">24</option>
                        <option *ngIf="smallBusinessPlan" value="25">25</option>
                        <option *ngIf="smallBusinessPlan" value="26">26</option>
                        <option *ngIf="smallBusinessPlan" value="27">27</option>
                        <option *ngIf="smallBusinessPlan" value="28">28</option>
                        <option *ngIf="smallBusinessPlan" value="29">29</option>
                        <option *ngIf="smallBusinessPlan" value="30">30</option>
                        <option *ngIf="smallBusinessPlan" value="31">31</option>
                        <option *ngIf="smallBusinessPlan" value="32">32</option>
                        <option *ngIf="smallBusinessPlan" value="33">33</option>
                        <option *ngIf="smallBusinessPlan" value="34">34</option>
                        <option *ngIf="smallBusinessPlan" value="35">35</option>
                        <option *ngIf="smallBusinessPlan" value="36">36</option>
                        <option *ngIf="smallBusinessPlan" value="37">37</option>
                        <option *ngIf="smallBusinessPlan" value="38">38</option>
                        <option *ngIf="smallBusinessPlan" value="39">39</option>
                        <option *ngIf="smallBusinessPlan" value="40">40</option>
                        <option *ngIf="smallBusinessPlan" value="41">41</option>
                        <option *ngIf="smallBusinessPlan" value="42">42</option>
                        <option *ngIf="smallBusinessPlan" value="43">43</option>
                        <option *ngIf="smallBusinessPlan" value="44">44</option>
                        <option *ngIf="smallBusinessPlan" value="45">45</option>
                        <option *ngIf="smallBusinessPlan" value="46">46</option>
                        <option *ngIf="smallBusinessPlan" value="47">47</option>
                        <option *ngIf="smallBusinessPlan" value="48">48</option>
                        <option *ngIf="smallBusinessPlan" value="49">49</option>
                        <option *ngIf="smallBusinessPlan" value="50">50</option>
                        <option *ngIf="midSizeBusinessPlan" value="51">51</option>
                        <option *ngIf="enterprisePlan" value="301">301</option>
                      </select>
                    </li>
                    <li *ngIf="error" class="show__alert__message" style="padding-bottom: 5px;">
                      <div class="alert alert-danger">
                        {{error}}
                      </div>
                    </li>
                    <li *ngIf="additionalSeats">
                      <input type="number" formControlName="additional_seats" name="additional_seats" min="0" max="249"
                        placeholder="Additional seats" />
                    </li>
                    <li *ngIf="errorSeats" class="show__alert__message">
                      <div class="alert alert-danger">
                        {{errorSeats}}
                      </div>
                    </li>
                    <li class="login-form plan-padding">
                      <input type="submit" autocomplete="off" [disabled]="!planForm.valid || disableButton"
                        (click)="paymentScreen()" class="btn btn-orange submitBtn" name="submit" value="Confirm" />
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="payment" class="form-group plan-box-shadow" style="font-size:17px">
        <div class="subcription-amount">
          <div class="amount">
            <div>
              <span>$</span> <strong> {{newPlanPrice}}</strong><br />
            </div>
            <span class="amount-label">Per Month.</span>
          </div>
          <div class="subscription-des">
            <h4 class="try-text">{{planSelected}}</h4>
            <h4 *ngIf="!individualPlan" class="try-text">Users: {{totalSeatsCount}}</h4>

          </div>
        </div>
        <h3 class="topText">Please enter your credit card details.</h3>
        <form [formGroup]="creditCardForm" (ngSubmit)="onSubmit()" id="cc-form">
          <ul>
            <li>
              <input
                [ngClass]="( ccSubmit && ccf['cardNumber']?.errors?.required || ccSubmit && ccf['cardNumber']?.errors?.ccNumber ? 'form-error' : '')"
                type="text" maxlength="20" type="tel" formControlName="cardNumber" #ccNumber="ccNumber"
                autocomplete="cc-number" ccNumber placeholder="Credit Card Number">

              <!-- {{ccNumber.resolvedScheme$ | async}} -->
            </li>
            <li *ngIf="ccf.cardNumber.touched && ccf.cardNumber.invalid">
              <div *ngIf="ccf.cardNumber.hasError('required') || ccf.cardNumber.hasError('ccNumber')">
                <div class="text-danger">
                  Invalid Card Number!
                </div>
              </div>
            </li>
            <li class="card-details">
              <select formControlName="month" class="plan-background-color"
                [ngClass]="( ccSubmit && ccf['month']?.errors?.required ? 'form-error' : '')">
                <option value="" disabled selected>MM</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
              <select formControlName="year" class="plan-background-color"
                [ngClass]="( ccSubmit && ccf['year']?.errors?.required ? 'form-error' : '')">
                <option value="" disabled selected>YY</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
                <option value="2029">2029</option>
                <option value="2030">2030</option>
                <option value="2031">2031</option>
                <option value="2032">2032</option>
                <option value="2033">2033</option>
              </select>
              <input [ngClass]="( ccSubmit && ccf['cardCode']?.errors?.required ? 'form-error' : '')" type="text"
                maxlength="4" name="cardCode" formControlName="cardCode" type="tel" autocomplete="off" ccCvc
                placeholder="CVV">
              <i class="fas fa-info-circle"></i>
            </li>
            <li *ngIf="ccf.month.touched && ccf.month.invalid || ccf.year.touched && ccf.year.invalid">
              <div *ngIf="ccf.month.hasError('required') || ccf.year.hasError('required')">
                <div class="text-danger">
                  Expiration date Is Required!
                </div>
              </div>
            </li>
            <li *ngIf="ccf.cardCode.touched && ccf.cardCode.invalid">
              <div *ngIf="ccf.cardCode.hasError('required')">
                <div class="text-danger">
                  CVV Number Is Required!
                </div>
              </div>
            </li>
            <li>
              <input [ngClass]="( ccSubmit && ccf['fullName']?.errors?.required ? 'form-error' : '')" type="text"
                name="fullName" formControlName="fullName" placeholder="Full Name on Credit or Debit card">

            </li>
            <li *ngIf="ccf.fullName.touched && ccf.fullName.invalid">
              <div *ngIf="ccf.fullName.hasError('required')">
                <div class="text-danger">
                  Full Name Is Required!
                </div>
              </div>
            </li>
            <li>
              <input [ngClass]="( ccSubmit && ccf['zip']?.errors?.required ? 'form-error' : '')" type="text"
                maxlength="15" name="zip" formControlName="zip" placeholder="Postal Code">
            </li>
            <li *ngIf="ccf.zip.touched && ccf.zip.invalid">
              <div *ngIf="ccf.zip.hasError('required')">
                <div class="text-danger">
                  Postal Code Is Required!
                </div>
              </div>
            </li>
            <li class="login-form">
              <input type="submit" [disabled]="!creditCardForm.valid" class="btn btn-orange submitBtn" name="submit"
                value="Confirm">
            </li>
            <li *ngIf="error" class="show__alert__message">
              <div class="alert alert-danger">
                {{error}}
              </div>
            </li>
          </ul>
        </form>
      </div>
      <div>
      </div>
    </div>
  </div>
</div>
