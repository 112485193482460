import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserAuthService } from '../auth/user-auth.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Observable, generate, throwError, from } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SHA256 } from 'crypto-js';
import { map, switchMap, catchError, tap} from 'rxjs/operators';

@Injectable()
export class CommonHttpClient {
    private httpOptions;
    private logsReqResData = true; // Make it True For Debugging purpose only
    private _baseApiUrl = environment.baseApiUrl;
    private getKey = "4c0254db-rrmz-8092-cqcn-d6579d47cd88";
    private mongoKey;
    constructor(private http: HttpClient,
        private userAuthService: UserAuthService,
        private toastr: ToastrService,
        private router: Router,
        private ngxService: NgxUiLoaderService,
    ) {
        
        if (!this.mongoKey) {
            setTimeout(() => {
                console.log('delay 4s');
            }, 2000);
            const headerDict = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Key': this.getKey
            }
            const requestOptions = {
                headers: new HttpHeaders(headerDict),
            };
            this.http.get('https://www.titaniumsecure.io:3000/api/api-key', requestOptions
            ).subscribe(resp => {
                if (resp != null) {
                    this.mongoKey = resp.toString();
                }
            });
        }
    }

    // private async _configHeaders() {
    //     await this.fetchApiKey();
    //     let headers = new HttpHeaders();
    //     //headers = headers.set('Access-Control-Allow-Headers', 'Accept,Accept-Language,Content-Language,Content-Type');
    //     headers = headers.set('Content-Type', 'application/json');
    //     headers = headers.set('Access-Control-Allow-Origin', '*');
    //     if (this.mongoKey) {
    //         headers = headers.set('Key', this.mongoKey);
    //     }
    //     //headers = headers.set('Keytest', this.mongoKey);
    //     if (localStorage.getItem('0') && localStorage.getItem("id_token")) {
    //         headers = headers.set('Authorization', localStorage.getItem("id_token"));
    //     }
    //     this.httpOptions = { headers };
    // }
    private _configHeaders(): Observable<void> {
        return from(this.fetchApiKey()).pipe(
            map(() => {
                let headers = new HttpHeaders();
                headers = headers.set('Content-Type', 'application/json');
                headers = headers.set('Access-Control-Allow-Origin', '*');
    
                if (this.mongoKey) {
                    headers = headers.set('Key', this.mongoKey);
                }
    
                if (localStorage.getItem('0') && localStorage.getItem("id_token")) {
                    headers = headers.set('Authorization', localStorage.getItem("id_token"));
                }
    
                this.httpOptions = { headers };
            })
        );
    }
    
    // async generateSHA256Hash(input: string): Promise<string> {
    //     const encoder = new TextEncoder();
    //     const data = encoder.encode(input);
    //     const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    //     const hashArray = Array.from(new Uint8Array(hashBuffer));
    //     const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    //     return hashHex;
    //   }

    async getApiKey(): Promise<string> {
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'Key': this.getKey
        }
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        var key = "";
        await this.http.get('https://www.titaniumsecure.io:3000/api/api-key', requestOptions
        ).subscribe(resp => {
            if (resp != null) {
                key = resp.toString();
                this.mongoKey = resp.toString();
            }
        });
        return new Promise((apiKey) => {            
            setTimeout(() => {
                apiKey(key);
              }, 2000);
        });
    }
    fetchApiKey(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!this.mongoKey) {
                const headerDict = {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Key': this.getKey
                };
                const requestOptions = {
                    headers: new HttpHeaders(headerDict),
                };
                this.http.get('https://www.titaniumsecure.io:3000/api/api-key', requestOptions)
                    .subscribe(resp => {
                        if (resp != null) {
                            this.mongoKey = resp.toString();
                            resolve();
                        } else {
                            reject('Failed to retrieve API key');
                        }
                    }, error => {
                        reject(error);
                    });
            } else {
                resolve();
            }
        });
    }
    // get(url, showToastr = false) {
    //     this._configHeaders();
    //     return this.http.get(this._baseApiUrl + url, this.httpOptions).pipe(
    //         tap((resp: any) => {
    //             if (resp.status === 'success' && showToastr) {
    //                 this.showToastr('success', resp.message, '');
    //             } else if (resp.status === 'failed' && showToastr) {
    //                 this.showToastr('error', resp.message, '');
    //             }
    //         }),
    //         map((resp: any) => {
    //             if (resp.data) {
    //                 // With Decryption
    //                 //resp.data = this.decrypt(resp.data);
    //                 // Without Decryption
    //                 resp.data = resp.data;
    //             }
    //             this.consoleLogData({ method: 'GET', url, resp });
    //             return resp;
    //         }),
    //         catchError(err => {
    //             this.handleError(err, showToastr);
    //             this.consoleLogData(err);
    //             return throwError(err);
    //         })
    //     );
    // }
    get(url: string, showToastr = false): Observable<any> {
        return this._configHeaders().pipe(
            switchMap(() => 
                this.http.get(this._baseApiUrl + url, this.httpOptions).pipe(
                    tap((resp: any) => {
                        if (resp.status === 'success' && showToastr) {
                            this.showToastr('success', resp.message, '');
                        } else if (resp.status === 'failed' && showToastr) {
                            this.showToastr('error', resp.message, '');
                        }
                    }),
                    map((resp: any) => {
                        if (resp.data) {
                            // With Decryption
                            //resp.data = this.decrypt(resp.data);
                            // Without Decryption
                            resp.data = resp.data;
                        }
                        this.consoleLogData({ method: 'GET', url, resp });
                        return resp;
                    }),
                    catchError(err => {
                        this.handleError(err, showToastr);
                        this.consoleLogData(err);
                        return throwError(err);
                    })
                )
            )
        );
    }
    
    getBody(url: string, data: any, showToastr = false): Observable<any> {
        return this._configHeaders().pipe(
            switchMap(() => 
                this.http.get(this._baseApiUrl + url, this.httpOptions).pipe(
                    tap((resp: any) => {
                        if (resp.status === 'success' && showToastr) {
                            this.showToastr('success', resp.message, '');
                        } else if (resp.status === 'failed' && showToastr) {
                            this.showToastr('error', resp.message, '');
                        }
                    }),
                    map((resp: any) => {
                        if (resp.data) {
                            resp.data = resp.data;
                        }
                        this.consoleLogData({ method: 'GET', url, resp });
                        return resp;
                    }),
                    catchError(err => {
                        this.handleError(err, showToastr);
                        this.consoleLogData(err);
                        return throwError(err);
                    })
                )
            )
        );
    }
    

    post(url: string, data: any, showToastr = false): Observable<any> {
        return this._configHeaders().pipe(
            switchMap(() =>
                this.http.post(this._baseApiUrl + url, data, this.httpOptions).pipe(
                    tap((resp: any) => {
                        if (resp.status === 'success' && showToastr) {
                            this.showToastr('success', resp.message, '');
                        } else if (resp.status === 'failed' && showToastr) {
                            this.showToastr('error', resp.message, '');
                        }
                        this.consoleLogData({ method: 'POST', url, data, resp });
                    }),
                    catchError(err => {
                        this.handleError(err, showToastr);
                        this.consoleLogData(err);
                        return throwError(err);
                    })
                )
            )
        );
    }
    
    postValidate(url: string, data: any, showToastr = false): Observable<any> {
        return this._configHeaders().pipe(
            switchMap(() =>
                this.http.post(this._baseApiUrl + url, data, this.httpOptions).pipe(
                    tap((resp: any) => {
                        if (resp.status === 'success' && showToastr) {
                            this.showToastr('success', resp.message, '');
                        } else if (resp.status === 'failed' && showToastr) {
                            this.showToastr('error', resp.message, '');
                        }
                        this.consoleLogData({ method: 'POST', url, data, resp });
                    }),
                    catchError(err => {
                        this.handleErrorSpecific(err, showToastr);
                        this.consoleLogData(err);
                        return throwError(err);
                    })
                )
            )
        );
    }
    
    postRequest(url: string, data: any): Observable<any> {
        return this._configHeaders().pipe(
            switchMap(() => 
                this.http.post(this._baseApiUrl + url, data, this.httpOptions)
            )
        );
    }
    
    put(url: string, data: any, showToastr = false): Observable<any> {
        return this._configHeaders().pipe(
            switchMap(() =>
                this.http.put(this._baseApiUrl + url, data, this.httpOptions).pipe(
                    tap((resp: any) => {
                        if (resp.status === 'success' && showToastr) {
                            this.showToastr('success', resp.message, '');
                        } else if (resp.status === 'failed' && showToastr) {
                            this.showToastr('error', resp.message, '');
                        }
                        this.consoleLogData({ method: 'PUT', url, data, resp });
                    }),
                    catchError(err => {
                        this.handleError(err, showToastr);
                        this.consoleLogData(err);
                        return throwError(err);
                    })
                )
            )
        );
    }
    
    delete(url: string, showToastr = false): Observable<any> {
        return this._configHeaders().pipe(
            switchMap(() => 
                this.http.delete(this._baseApiUrl + url, this.httpOptions).pipe(
                    tap((resp: any) => {
                        if (resp.status === 'success' && showToastr) {
                            this.showToastr('success', resp.message, '');
                        } else if (resp.status === 'failed' && showToastr) {
                            this.showToastr('error', resp.message, '');
                        }
                    }),
                    map((resp: any) => {
                        if (resp.data) {
                            resp.data = resp.data;
                        }
                        this.consoleLogData({ method: 'Delete', url, resp });
                        return resp;
                    }),
                    catchError(err => {
                        this.handleError(err, showToastr);
                        this.consoleLogData(err);
                        return throwError(err);
                    })
                )
            )
        );
    }
    

    handleError(error, showToastr) {
        this.ngxService.stop();
        if (showToastr) {
            this.showToastr('error', error.message, error.status);
        }
        this.userAuthService.removeUser();
        if (error.status === 401) {
            this.router.navigate(['/login']);
        } else if (error.status === 500) {
            this.router.navigate(['/server-error']);
        } else if (error.status === 0) {
            this.router.navigate(['/server-error']);
        }
    }
    handleErrorSpecific(error, showToastr) {
        this.ngxService.stop();
        if (showToastr) {
            this.showToastr('error', error.message, error.status);
        }
        if (error.status === 401) {
            this.router.navigate(['/login']);
        } else if (error.status === 500) {
            this.router.navigate(['/server-error']);
        } else if (error.status === 0) {
            this.router.navigate(['/server-error']);
        }
    }

    consoleLogData(data) {
        if (this.logsReqResData) {
            // console.log('------ API LOGS -----', data);
        }
    }

    showToastr(type, msg, title) {
        this.toastr[type](msg, title, {
            progressBar: true,
            progressAnimation: 'increasing',
            timeOut: 1500,
            positionClass: 'toast-bottom-right',
        });
    }
}
