import { Component, HostListener, OnInit } from '@angular/core';
import * as AOS from 'aos'
import { UserAuthService } from '../shared/services/auth/user-auth.service'
import { UserService } from '../shared/services/user.service';
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  planName: string;
  numberOfSeats: number = 0;

  user:any;
  constructor(private userService: UserService, private userAuth: UserAuthService
    //private ngxLoader: NgxUiLoaderService
  ) {
    setTimeout(() => {AOS.refresh();}, 500);
    this.user = this.userAuth.getStoredUser();
    //this.ngxLoader.start();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 1 ||     
    document.documentElement.scrollTop > 1) {
      document.body.classList.add('show-video-bg');
    }else{
      document.body.classList.remove('show-video-bg');
    }
  }

  ngOnInit(): void {}

   // TODO: Cross browsing
   gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  logOut(){
    this.userService.logout();
}
scrollToPlans(){
  window.scroll({ 
    top: 1000, 
    left: 0, 
    behavior: 'smooth' 
  });
}

  autopopulatePlanIndividual() {
    this.planName = "Individual";
    localStorage.setItem('planName', this.planName);
    localStorage.setItem('numberOfSeats', JSON.stringify(this.numberOfSeats));
  }

  autopopulatePlanFamily() {
    this.planName = "2-4 users";
    this.numberOfSeats = 2;
    localStorage.setItem('planName', this.planName);
    localStorage.setItem('numberOfSeats', JSON.stringify(this.numberOfSeats));
  }

  autopopulatePlanSmallBusiness() {
    this.planName = "5-50 users";
    this.numberOfSeats = 5;
    localStorage.setItem('planName', this.planName);
    localStorage.setItem('numberOfSeats', JSON.stringify(this.numberOfSeats));
  }

  autopopulatePlanMidBusiness() {
    this.planName = "51-300 users";
    this.numberOfSeats = 51;
    localStorage.setItem('planName', this.planName);
    localStorage.setItem('numberOfSeats', JSON.stringify(this.numberOfSeats));
  }

  autopopulatePlanEnterprise() {
    this.planName = "301-unlimited users";
    this.numberOfSeats = 301;
    localStorage.setItem('planName', this.planName);
    localStorage.setItem('numberOfSeats', JSON.stringify(this.numberOfSeats));
  }
}
