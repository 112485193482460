import { Component, OnInit, TemplateRef } from '@angular/core';
import { UserService } from '../shared/services/user.service';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare function viewPassword(): any;
declare function viewCPassword(): any;
declare function viewCCPassword(): any;

@Component({
  selector: 'ngbd-modal-content',
  template: `
		<div class="modal-header">
			<h4 style="color:black" class="modal-title">Profile update</h4>
			<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
		</div>
		<div class="modal-body">
			<h5 style="color:black">Your displayname has been successfully updated.</h5>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-blue submitBtn" (click)="activeModal.close('Close click')">OK</button>
		</div>
	`,
})
export class NgbdModalContent {
  constructor(public activeModal: NgbActiveModal) { }
}

@Component({
  selector: 'app-change-username',
  templateUrl: './change-username.component.html',
  styleUrls: ['./change-username.component.css'],
  animations: [
    trigger(
      'slideView',
      [
        state('true', style({ transform: 'translateX(100%)', opacity: 0 })),
        state('false', style({ transform: 'translateX(0)', opacity: 1 })),
        transition('0 => 1', animate('500ms', style({ transform: 'translateX(0)', 'opacity': 1 }))),
        transition('1 => 1', animate('500ms', style({ transform: 'translateX(100%)', 'opacity': 0 }))),
      ]),

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)', 'opacity': 1 }))
      ]),

      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('0ms ease-in', style({ transform: 'translateX(100%)', 'opacity': 0 }))
      ])
    ])
  ]
})
export class ChangeUsernameComponent implements OnInit {

  signSubmit: boolean = false;
  //get from html forms
  newDisplayName: string;
  error: string;
  //get from html forms
  oldPassword: string;
  PasswordChanged: boolean = false;
  changeDisplayNameForm: FormGroup;
  submit: boolean = false;
  passwordNotMatchError: string;
  closeResult = '';
  constructor(private userService: UserService, private _location: Location, private router: Router, private ngxloader: NgxUiLoaderService,
    private modalService: NgbModal) {
    this.initChangeDisplayNameForm();

  }

  ngOnInit(): void {
  }

  changeDisplayName() {
    this.ngxloader.start();
    this.newDisplayName = this.changeDisplayNameForm.get("display_name").value;
      if (localStorage.getItem('0') != null) {
      if (localStorage.getItem('0').includes('@')) {
        var email = localStorage.getItem('0')
        let dataMongoDisplayName = {
          display_name: this.newDisplayName
        }
        this.userService.changeDisplayNameByEmail(dataMongoDisplayName, email).subscribe(resp => {
          if (resp.message === 'Display name changed!') {
            //fam request
            this.userService.changeDisplayNameMariaAPIByEmail(email, dataMongoDisplayName).subscribe(famResp => {
              if (famResp.message === 'Display name changed successfully!') {
                  this.ngxloader.stop();
                  //success pop up
                  this.open();
                }
              });
          }
        }, error => { this.error = 'Error changing display name!' })
      }

      if (!localStorage.getItem('0').includes('@')) {
        var phone = localStorage.getItem('0')

        let dataMongoDisplayName = {
          display_name: this.newDisplayName
        }
        this.userService.changeDisplayNameByPhone(dataMongoDisplayName, phone).subscribe(resp => {
          if (resp.message === 'Display name changed!') {
            //fam request
            this.userService.changeDisplayNameMariaAPIByPhone(phone, dataMongoDisplayName).subscribe(famResp => {
              if (famResp.message === 'Display name changed successfully!') {
                this.ngxloader.stop();
                //success pop up
                this.open();
              }
            });
          }
        }, error => { this.error = 'Error changing display name!' })
      }
    }
    else {
      this.error = 'Error changing display name!'
    }
  }

  //changePassword() {
  //  this.ngxloader.start();
  //  this.oldPassword = this.changePasswordForm.get("old_password").value;


  //  var famData = {
  //    algorithm: "SHA-256", old_password: this.oldPassword, 
  //  }
  //  if (localStorage.getItem('0') != null) {
  //    if (localStorage.getItem('0').includes('@')) {
  //      var email = localStorage.getItem('0')

  //      this.userService.changePasswordByEmail(email, this.oldPassword).subscribe(resp => {
  //        if (resp.message === 'Password changed!') {
  //          //onhold for now

  //        }
  //      }, error => { this.error = 'Error changing password!' })
  //    }

  //    if (!localStorage.getItem('0').includes('@')) {
  //      var phone = localStorage.getItem('0')
  //      this.userService.changePasswordByPhone(this.oldPassword, phone).subscribe(resp => {
  //        if (resp.message === 'Password changed!') {
  //          this.userService.changePasswordFAM(phone, famData).subscribe(famResp => {
  //            if (famResp.message === 'Password changed!') {
  //              this.ngxloader.stop();
  //              this.PasswordChanged = true;
  //              //success pop up
  //              this.open();
  //            }

  //          });
  //        }
  //      }, error => { this.passwordNotMatchError = 'The password you are changing is the same as the current!' })
  //    }
  //  }
  //  else {
  //    this.passwordNotMatchError = 'Error changing password!'
  //  }
  //}
  get f() {
    return this.changeDisplayNameForm.controls;
  }

  initChangeDisplayNameForm() {
    this.changeDisplayNameForm = new FormGroup({
      old_password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_={}|:;"'<,>.])[A-Za-z\d@$!%*?&~`#^()_={}|:;"'<,>.]{8,}$/)]),
      display_name: new FormControl('', [Validators.required, Validators.maxLength(40), Validators.minLength(2)]),
    });
  }

  backClicked() {
    if (this.router.navigated) {
      this._location.back();
    }

  }
  clearPasswordNotMatchError() {
    this.passwordNotMatchError = null;
  }

  open() {
    const modalRef = this.modalService.open(NgbdModalContent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.backClicked();
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.backClicked();
      },
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


}
