import { Component, OnInit } from '@angular/core';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UserService } from '../shared/services/user.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment'
import { NgxUiLoaderService } from "ngx-ui-loader";
import { UserAuthService } from '../shared/services/auth/user-auth.service'
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { Subscription, timer } from 'rxjs';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare function phoneOrEmail(): any;
declare function viewPassword(): any;
@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
      <h4 style="color:black" class="modal-title">Password reset request</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <h5 style="color:black">We have sent you the password reset link. Please check your email.</h5>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-blue submitBtn" (click)="activeModal.close('Close click')">OK</button>
    </div>
  `,
})
export class NgbdModalContent {
  constructor(public activeModal: NgbActiveModal) { }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [
    trigger(
      'slideView',
      [
        state('true', style({ transform: 'translateX(100%)', opacity: 0 })),
        state('false', style({ transform: 'translateX(0)', opacity: 1 })),
        transition('0 => 1', animate('500ms', style({ transform: 'translateX(0)', 'opacity': 1 }))),
        transition('1 => 1', animate('500ms', style({ transform: 'translateX(100%)', 'opacity': 0 }))),
      ]),

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('600ms ease-in', style({ transform: 'translateX(0%)', 'opacity': 1 }))
      ]),

      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('0ms ease-in', style({ transform: 'translateX(100%)', 'opacity': 0 }))
      ])
    ])
  ]
})
export class LoginComponent implements OnInit {

  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 4,
    autofocus: true,
    classList: {
      inputBox: "my-super-box-class",
      input: "my-super-class",
      inputFilled: "my-super-filled-class",
      inputDisabled: "my-super-disable-class",
      inputSuccess: "my-super-success-class",
      inputError: "my-super-error-class"
    }
  };

  emailOTP: boolean = false;
  phoneOTP: boolean = false;
  ConfirmationToken: string;
  OTPcounter: number = 0;
  phoneCheck = "checked";
  emailCheck: string;
  data: any;
  phoneOption: boolean = false;
  error: string;
  success: string;
  loginForm: FormGroup;
  steps = 1;
  phone_number = '';
  password: string;
  passwordNotMatchError: string;
  //to show/hide Password
  show: boolean = false
  //To show otp screen
  showOTP: boolean = false;
  showResendOTP: boolean = false;

  windowRef: any;
  recaptchaVerifier: any;

  verificationConfirmation: any;
  ///
  //variables for int number 
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];
  ////end

  //for otp timer
  countDown: Subscription;
  counter = 120;
  tick = 1000;
  closeResult: string;
  phone: any;
  email: any;
  phoneNumber: any;
  emailAddress: any;
  dynamicText: string;
  ////

  constructor(private userService: UserService, private ngxloader: NgxUiLoaderService,
    private authService: UserAuthService,
    private router: Router, private modalService: NgbModal) {
    this.initLoginForm();
  }

  ngOnInit(): void {
    this.loginForm.reset();
    setTimeout(() => {
      this.phoneOption = phoneOrEmail(), 1000
      /*this.withoutCaptha();*/
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  showPassword() {
    this.show = !this.show;
  }

  initLoginForm() {
    this.loginForm = new FormGroup({
      phone_number: new FormControl('', [Validators.required, Validators.nullValidator, Validators.maxLength(50)]),
      email_id: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/), Validators.maxLength(254)]),
      password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_={}|:;"'<,>.])[A-Za-z\d@$!%*?&~`#^()_={}|:;"'<,>.]{8,}$/)]),
      OtpCode: new FormControl('', [Validators.required]),
    }, { validators: this.passwordMatchingValidatior });
  }

  login() {
    if (this.loginForm.get('phone_number').value != null || this.loginForm.get('email_id').value != null) {
      if (this.loginForm.get('phone_number').value != null) {
        let phone = this.loginForm.get('phone_number').value;        
        this.data = {
          phone_number: this.removePlusFromstring(phone.e164Number),
          password: this.loginForm.get('password').value
        }
        if (this.data != null) {
          this.userService.Login(this.data).subscribe(resp => {
            if (resp.message === 'Login successful!') {   
              this.router.navigate(['/dashboard'])              
            }
            else {
              this.error = resp.message;
            }
          }, err => {
            this.error = 'Invalid Password!';
            this.ngxloader.stop();
          });
        }
        this.ngxloader.stop();
      } else {
        this.data = {
          email_id: this.loginForm.get('email_id').value,
          password: this.loginForm.get('password').value,
        }
        if (this.data != null) {
          this.userService.Login(this.data).subscribe(resp => {
            if (resp.message === 'Login successful!') {       
              this.router.navigate(['/dashboard'])
            }
            else {
              this.error = resp.message;
            }
          }, err => {
            this.error = 'Invalid Password!';
            this.ngxloader.stop();
          });
        }
      }
      this.ngxloader.stop();
    }
  }

  verifyUserSubscription() {
    this.clearInfoMessages();
    let phone = this.loginForm.get('phone_number').value;
    let email = this.loginForm.get('email_id').value;
    let obj = this;
    if (phone) {
      this.phone_number = this.removePlusFromstring(phone.e164Number);
      let data = {
        phone_number: this.removePlusFromstring(phone.e164Number),
      }
      this.userService.validateUserPhoneNumber(data).subscribe(resp => {
        if (resp.message === 'success (phone number already exists)') {
          /* this.sendOtp();*/
          this.ngxloader.start();
          this.userService.getPhoneOTP(this.phone_number).subscribe(resp => {
            if (resp.OTP) {
              this.userService.sendPhoneOTP(this.phone_number).subscribe(resp => {
                if (resp.message === 'Phone OTP was successfully sent!') {
                  this.phoneOTP = true;
                  this.showOTP = true;
                  this.startOTPTimer();
                  var data = {
                    otp: resp.otp,
                    phone: this.phone_number,
                    otp_sent_counter: 0,
                    otp_sent_date: new Date(),
                    telesign_ref_id: resp.telesign_ref_id
                  }
  
                }
                else {
                  /* if (tempSteps != 3) { obj.steps = tempSteps; }*/
                }
                this.ngxloader.stop();
              });
            }
            else {
              this.userService.sendPhoneOTP(this.phone_number).subscribe(resp => {
                if (resp.message === 'Phone OTP was successfully sent!') {
                  this.phoneOTP = true;
                  this.showOTP = true;
                  this.startOTPTimer();
                  var data = {
                    otp: resp.otp,
                    phone_number: this.phone_number,
                    otp_sent_counter: this.OTPcounter,
                    otp_sent_date: new Date(),
                    telesign_ref_id: resp.telesign_ref_id
                  }  
                }
                else {
                  /* if (tempSteps != 3) { obj.steps = tempSteps; }*/
                }
                this.ngxloader.stop();
              });
            }
          });
        } else {
          this.error = resp.message;
          this.ngxloader.stop();
        }
      }, err => {
        this.error = 'User not found.';
        this.ngxloader.stop();
        /* obj.steps = 1;*/
      });

    }//else{
    //  this.error = "Please enter phone number";
    //}
    if (email) {
      let data = {
        email_id: email
      }
      /* this.ngxloader.start();*/
      this.userService.validateUserEmailAddress(data).subscribe(resp => {
        if (resp.message === 'success (email already exists)') {
          //obj.steps += 1;
          //this.ngxloader.stop();
          /* this.sendOtp();*/
          this.ngxloader.start();
          this.userService.getEmailOTP(email).subscribe(resp => {
            if (resp.OTP) {
              this.userService.sendEmail(email).subscribe(resp => {
                if (resp.message === 'Email was successfully sent!') {
                  this.emailOTP = true;
                  this.showOTP = true;
                  this.startOTPTimer();
                  var data = {
                    otp: resp.otp,
                    email: email,
                    otp_sent_counter: 0,
                    otp_sent_date: new Date()
                  }
    
                }
                else {
                  /* if (tempSteps != 3) { obj.steps = tempSteps; }*/
                }
                this.ngxloader.stop();
              });
            }
            if (!resp.OTP) {
              this.userService.sendEmail(email).subscribe(resp => {
                if (resp.message === 'Email was successfully sent!') {
                  this.showOTP = true;
                  this.emailOTP = true;
                  this.startOTPTimer();
                  var data = {
                    otp: resp.otp,
                    email: email,
                    otp_sent_counter: this.OTPcounter,
                    otp_sent_date: new Date()
                  }
    
                }
                else {
                  /* if (tempSteps != 3) { obj.steps = tempSteps; }*/
                }
                this.ngxloader.stop();
              });
            }
          });
        } else {
          this.error = resp.message;
          this.ngxloader.stop();
        }
      }, err => {
        this.error = 'User not found.';
        this.ngxloader.stop();
        /* obj.steps = 1;*/
      });
    }// else {
    //  this.error = "Please enter email address";
    //}

  }


  verifyLoginCode() {
    if (this.loginForm.get('password').value) {
      this.ngxloader.start();
      this.windowRef.confirmationResult
        .confirm(this.loginForm.get('password').value)
        .then(result => {
          this.ngxloader.start();
          let obj = this;
          result.user.getIdToken(true).then(function (idToken) {

          });
        })
        .catch(error => {
          this.ngxloader.stop();
          this.error = "Incorrect code entered"
        });
    } else {
      this.error = "Please enter OTP ";
    }
  }

  checkEmailOTP() {
    let email = this.loginForm.get('email_id').value;
    let OTPinput = this.loginForm.get('OtpCode').value;
    this.userService.checkEmailOTP(email, OTPinput).subscribe(resp => {
      /* if (res.OTP) {*/
      if (resp.message == 'OTP matches!') {
          let obj = this;
          obj.steps += 1;
          //this.ngxloader.stop();
          this.clearInfoMessages();
          //delete temptable record
          // this.userService.deleteTempEmailRecord(email).subscribe(resp => { });  
      }
      else {
        this.error = "Invalid Code!"
      }
    }
    )
  }
  checkPhoneOTP() {
    let phone = this.loginForm.get('phone_number').value;
    var phone_number = this.removePlusFromstring(phone.e164Number);
    let OTPinput = this.loginForm.get('OtpCode').value;
    this.userService.checkPhoneOTP(phone_number, OTPinput).subscribe(resp => {
      /* if (res.OTP) {*/
      if (resp.message == 'OTP matches!') {        

          let obj = this;
          obj.steps += 1;
          //this.ngxloader.stop();
          this.clearInfoMessages();
          //delete temptable record
          // this.userService.deleteTempPhoneRecord(phone_number).subscribe(resp => { });
        }
    
      
      else {
        this.error = "Invalid Code!"
      }
    }
    )
  }
  

  resendOTP() {
    let email = this.loginForm.get('email_id').value;
    let phone = this.loginForm.get('phone_number').value;

    if (email) {
      this.ngxloader.start();
      this.userService.getEmailOTP(email).subscribe(resp => {
        this.OTPcounter = resp.counter;
        if (this.OTPcounter < 3) {
          this.userService.sendEmail(email).subscribe(resp => {
            if (resp.message === 'Email was successfully sent!') {
              this.showOTP = true;
              this.startOTPTimer();
              this.ngxloader.stop();
              this.OTPcounter = this.OTPcounter + 1;
              var data = { otp: resp.otp, email: email, otp_sent_counter: this.OTPcounter, otp_sent_date: new Date() }

            } else {
              this.ngxloader.stop();
            }
          });
        }
        else {
          var dateHours;
          var DateNow = new Date();
          var dateSpan = new Date(resp.OTPSentDate);
          if (dateSpan.getHours() != 23) {
            dateHours = dateSpan.getHours() + 1
          }
          else {
            dateHours = 0;
          }
          dateSpan.setHours(dateHours);
          //var DateError = OTPDate - DateNow;
          if (DateNow >= dateSpan) {
            this.userService.sendEmail(email).subscribe(resp => {
              if (resp.message === 'Email was successfully sent!') {
                this.showOTP = true;
                this.startOTPTimer();
                this.OTPcounter = this.OTPcounter + 1;
                var data = { otp: resp.otp, email: email, otp_sent_counter: 0, otp_sent_date: new Date() }

                this.ngxloader.stop();
                this.clearInfoMessages();
              }
            });
            this.ngxloader.stop();
          }
          else {
            this.error = "You must wait an hour before resending again!";
            this.ngxloader.stop();
          }
        }
      });
    }
    if (phone) {
      this.ngxloader.start();
      let phone_number = this.removePlusFromstring(phone.e164Number);
      this.userService.getPhoneOTP(phone_number).subscribe(resp => {
        this.OTPcounter = resp.counter;
        if (this.OTPcounter < 3) {
          this.userService.sendPhoneOTP(phone_number).subscribe(resp => {
            if (resp.message === 'Phone OTP was successfully sent!') {
              this.showOTP = true;
              this.startOTPTimer();
              this.ngxloader.stop();
              this.OTPcounter = this.OTPcounter + 1;
              var data = {
                telesign_ref_id: resp.telesign_ref_id,
                otp: resp.otp,
                phone: phone_number,
                otp_sent_counter: this.OTPcounter,
                otp_sent_date: new Date()
              }
  
              this.ngxloader.stop();
            } else {
              this.ngxloader.stop();
            }
          });
        }
        else {
          var dateHours;
          var DateNow = new Date();
          var dateSpan = new Date(resp.OTPSentDate);
          if (dateSpan.getHours() != 23) {
            dateHours = dateSpan.getHours() + 1
          }
          else {
            dateHours = 0;
          }
          dateSpan.setHours(dateHours);
          if (DateNow >= dateSpan) {
            this.ngxloader.start();
            this.userService.sendPhoneOTP(phone_number).subscribe(resp => {
              if (resp.message === 'Phone OTP was successfully sent!') {
                this.showOTP = true;
                this.startOTPTimer();
                this.OTPcounter = this.OTPcounter + 1;
                var data = { otp: resp.otp, phone: phone_number, otp_sent_counter: 0, otp_sent_date: new Date() }
  
                this.ngxloader.stop();
                this.clearInfoMessages();
              }
            });
            this.ngxloader.stop();
          }
          else {
            this.error = "You must wait an hour before resending again!";
            this.ngxloader.stop();
          }
        }
      });
    }
  }


  resetPasswordSendNotification() {
    this.ngxloader.start();
    this.clearInfoMessages();
    let phoneNumber = this.loginForm.get('phone_number').value;
    let emailAddress = this.loginForm.get('email_id').value;

    //get email and phone
    if (emailAddress != null) {   
          this.userService.sendPasswordResetNotification({email:emailAddress}).subscribe(async (resp) => {
            this.dynamicText = 'email address';
            this.open();
            this.ngxloader.stop();
          }, error => {
            this.ngxloader.stop();
            this.passwordNotMatchError = 'Error sending reset password link!'
          });  
     
    } else {
      if (phoneNumber != null) {
        this.phone_number = this.removePlusFromstring(phoneNumber.e164Number);
            this.userService.sendPasswordResetNotification({phone:this.phone_number}).subscribe(async (resp) => {
              this.dynamicText = 'phone number';
              this.open();
              this.ngxloader.stop();
            }, error => {
              this.ngxloader.stop();
              this.passwordNotMatchError = 'Error sending reset password link!'
            });   
   
      }
    }
  }


  handeOtpChange(value: string[]): void {
    value.forEach(input => {
      if (input == '') {
        this.loginForm.get('OtpCode').setValue(null);
      }
    });
  }

  handleFillEvent(value: string): void {
    this.loginForm.get('OtpCode').setValue(value);

    //for auto-login 
    // setTimeout(() => {
    //   this.verifyLoginCode();  
    // });

  }

  removePlusFromstring(phoneNumber) {
    return phoneNumber.split('+').join('')
  }

  resetOTPTimer() {
    this.showResendOTP = false;
    this.counter = 120
  }

  startOTPTimer() {
    this.resetOTPTimer();
    this.countDown = timer(0, this.tick).subscribe((count) => {
      if (count == 120) {
        //console.log('timer completed', count, this.counter);
        //show resent otp button
        this.showResendOTP = true;
        if (this.countDown) {
          this.countDown.unsubscribe();
        }
      }
      --this.counter;
    });
  }

  goTOHome() {
    this.loginForm.reset();
    this.router.navigate(['/'])
  }

  formStepPrev() {
    this.showOTP = false;
  }

  ngOnDestroy() {
    if (this.countDown) {
      this.countDown.unsubscribe();
    }
  }

  clearInfoMessages() {
    this.error = null;
    this.success = null;
  }

  selectedOption() {
    this.phoneOption = phoneOrEmail()
    if (this.phoneOption == true) {
      this.clearInfoMessages();
      this.phoneCheck = "checked";
      this.emailCheck = '';
    }
    else {
      this.clearInfoMessages();
      this.emailCheck = "checked";
      this.phoneCheck = '';
    }
  }

  clearPasswordNotMatchError() {
    this.passwordNotMatchError = null;
  }

  passwordMatchingValidatior: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get('password');

    if (password.value != '' && password.value != null) {
      // var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_={}|:;"'<,>.])[A-Za-z\d@$!%*?&~`#^()_={}|:;"'<,>.]{8,}$/);
      // let currentPassword = password.value;
      // var result = currentPassword.match(pattern);
      this.clearPasswordNotMatchError();
    }
    else{
      this.passwordNotMatchError = "Password field is required."
    }

    // if (!result) {
    //   this.passwordNotMatchError = "Password field is required."
    // } else {
    //   this.clearPasswordNotMatchError();
    // }

    return { notmatched: false };
  };

  open() {
    const modalRef = this.modalService.open(NgbdModalContent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.router.navigate(['/login'])
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.router.navigate(['/login'])
      },
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
