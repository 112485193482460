import { Component, OnInit, TemplateRef } from '@angular/core';
import { UserService } from '../shared/services/user.service';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, timer } from 'rxjs';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { UserAuthService } from '../shared/services/auth/user-auth.service';
declare function viewPassword(): any;
declare function viewCPassword(): any;
declare function viewCCPassword(): any;


@Component({
  selector: 'ngbd-modal-content',
  template: `
		<div class="modal-header">
			<h4 style="color:black" class="modal-title">Profile update</h4>
			<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
		</div>
		<div class="modal-body">
			<h5 style="color:black">Your password has been successfully updated.</h5>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-blue submitBtn" (click)="activeModal.close('Close click')">OK</button>
		</div>
	`,
})
export class NgbdModalContent {
  constructor(public activeModal: NgbActiveModal) { }
}

@Component({
  selector: 'app-change-password-embedded',
  templateUrl: './change-password-embedded.component.html',
  styleUrls: ['./change-password-embedded.component.css'],
  animations: [
    trigger(
      'slideView',
      [
        state('true', style({ transform: 'translateX(100%)', opacity: 0 })),
        state('false', style({ transform: 'translateX(0)', opacity: 1 })),
        transition('0 => 1', animate('500ms', style({ transform: 'translateX(0)', 'opacity': 1 }))),
        transition('1 => 1', animate('500ms', style({ transform: 'translateX(100%)', 'opacity': 0 }))),
      ]),

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)', 'opacity': 1 }))
      ]),

      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('0ms ease-in', style({ transform: 'translateX(100%)', 'opacity': 0 }))
      ])
    ])
  ]
})
export class ChangePasswordEmbeddedComponent implements OnInit {
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 4,
    autofocus: true,
    classList: {
      inputBox: "my-super-box-class",
      input: "my-super-class",
      inputFilled: "my-super-filled-class",
      inputDisabled: "my-super-disable-class",
      inputSuccess: "my-super-success-class",
      inputError: "my-super-error-class"
    }
  };
  //get from html forms
  newPassword: string;
  oldPassword: string;
  error: string;
  PasswordChanged: boolean = false;
  changePasswordForm: FormGroup;
  submit: boolean = false;
  passwordNotMatchError: string;
  closeResult = '';
  phoneCheck = false;
  emailCheck = false;

  countDown: Subscription;
  counter = 120;
  tick = 1000;
  success: any;
  changePhoneByPhoneCheck: boolean;

  emailOTP: boolean = false;
  phoneOTP: boolean = false;
  ConfirmationToken: string;
  OTPcounter: number = 0;
  response: string;
  localPhone = '';
  localEmail = '';

  windowRef: any;
  showOTP: boolean = true; //this should false when init
  showResendOTP: boolean = false // to show resend otp button -- should be false when init
  oldEmail: any;
  apiCalled: boolean;
  user: any;
  phoneNumber: any;
    teststring: string;
  userId: any;

  constructor(private userService: UserService, private _location: Location, private router: Router, private ngxloader: NgxUiLoaderService,
    private modalService: NgbModal, private userAuth: UserAuthService) {
    this.getUserID();
    this.initChangePasswordForm();
    this.initOTP();
    this.user = this.userAuth.getStoredUser();
    this.getSubscriptionDetails();
  }

  ngOnInit(): void {
  }

  getUserID()
  {
   if (localStorage.getItem('0').includes('@'))
   {
     var email = localStorage.getItem('0')
     this.userService.getIdMariaAPIByEmail(email).subscribe(resp => {
       if (resp != null) {
         this.userId = resp[0].id
       }
     });
   }
   else
   {
     var phone = localStorage.getItem('0')
     this.userService.getIdMariaAPIByPhone(phone).subscribe(resp => {
       if (resp != null) {
         this.userId = resp[0].id
       }
     });
   }
  }

  changePassword() {
    this.ngxloader.start();
    this.clearInfoMessages();
    this.oldPassword = this.changePasswordForm.get("old_password").value;
    this.newPassword = this.changePasswordForm.get("new_password").value;
    var phone = localStorage.getItem('0')
    var famData = {
      algorithm: "SHA-256", old_password: this.oldPassword, password: this.newPassword,
      token: this.result,
      id: this.userId,
      phone_number: '+' + phone,
    }
    if (localStorage.getItem('0') != null) {
      if (localStorage.getItem('0').includes('@')) {
        var email = localStorage.getItem('0')

        this.userService.changePasswordByEmail(this.oldPassword, this.newPassword, email).subscribe(resp => {
          if (resp.message === 'Password changed!') {
            this.userService.changePasswordFAMProv(famData).subscribe(famResp => {
              if (famResp.message === 'Password changed successfully!') {
                this.ngxloader.stop();
                this.PasswordChanged = true;
                //success pop up
                this.open();
                var linkData = { email: this.oldEmail, phone_number: this.phoneNumber }
                this.userService.sendPasswordChangeNotification(linkData).subscribe(async (resp) => {
                  //user press ?
                });
              } else {
                this.ngxloader.stop();
                this.PasswordChanged = false;
                this.error = 'Password change failed!'
              }
            });

          }
          else {
            if (resp.message === 'Old password is incorrect.') {
              this.passwordNotMatchError = resp.message;
              this.ngxloader.stop();
            }
            if (resp.message === "Invalid Password! Password is the same as the current one!") {
              this.passwordNotMatchError = resp.message;
              this.ngxloader.stop();
            }
          }
        }, error => { this.passwordNotMatchError = 'Error changing password!' })
      }

      if (!localStorage.getItem('0').includes('@')) {
        this.userService.changePasswordByPhone(this.oldPassword, this.newPassword, phone).subscribe(resp => {
          if (resp.message === 'Password changed!') {
            this.userService.changePasswordFAMProv(famData).subscribe(famResp => {
              if (famResp.message === 'Password changed successfully!') { 
                this.PasswordChanged = true;
                //success pop up
                this.openExceptionProv();
                this.ngxloader.stop();
                var linkData = { email: this.oldEmail, phone_number: phone }
                this.userService.sendPasswordChangeNotification(linkData).subscribe(async (resp) => {
                  //user press ?
                });
              } else {
                this.ngxloader.stop();
                this.PasswordChanged = false;
                this.error = 'Password change failed!'
              }

            });
          }
          else {
            if (resp.message === 'Old password is incorrect.') {
              this.passwordNotMatchError = resp.message;
              this.ngxloader.stop();
            }
            if (resp.message === "Invalid Password! Password is the same as the current one!") {
              this.passwordNotMatchError = resp.message;
              this.ngxloader.stop();
            }
          }
        }, error => { this.passwordNotMatchError = "Invalid Password! Password is the same as the current one!" })
      }
    }
    else {
      this.passwordNotMatchError = 'Error changing password!'
    }
  }
  get f() {
    return this.changePasswordForm.controls;
  }
  openExceptionProv() {
    var phone = localStorage.getItem('0')
    const modalRef = this.modalService.open(NgbdModalContent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.ngxloader.start();
        this.userService.generateXMLFileFAM(this.localPhone, this.result)
        .subscribe((resp) => {
          this.ngxloader.stop();
           window.location.href = 'https://www.titaniumsecure.io:3000/api/xml/' + resp.filename;
          // window.location.href = 'https://sip2.titaniumsecure.io/flexiapi/provisioning/' + this.ProvisioningToken;
        });

      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.ngxloader.start(); 
        this.userService.generateXMLFileFAM(this.localPhone, this.result)
        .subscribe((resp) => {
          this.ngxloader.stop();          
           window.location.href = 'https://www.titaniumsecure.io:3000/api/xml/' + resp.filename;           
          // window.location.href = 'https://sip2.titaniumsecure.io/flexiapi/provisioning/' + this.ProvisioningToken;
        });
      },
    );
  }
  async generateSHA256Hash(input: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }
  initChangePasswordForm() {
    this.changePasswordForm = new FormGroup({
      old_password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_={}|:;"'<,>.])[A-Za-z\d@$!%*?&~`#^()_={}|:;"'<,>.]{8,}$/)]),
      new_password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_={}|:;"'<,>.])[A-Za-z\d@$!%*?&~`#^()_={}|:;"'<,>.]{8,}$/)]),
      confirm_new_password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_={}|:;"'<,>.])[A-Za-z\d@$!%*?&~`#^()_={}|:;"'<,>.]{8,}$/)]),
      OtpCode: new FormControl('', [Validators.required])
    }, { validators: this.passwordMatchingValidatior });
  }

  backClicked() {
    if (this.router.navigated) {
      this._location.back();
    }

  }
  clearPasswordNotMatchError() {
    this.passwordNotMatchError = null;
  }

  passwordMatchingValidatior: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const oldPassword = control.get('old_password');
    const newPassword = control.get('new_password');
    const confirm_new_password = control.get('confirm_new_password');

    if (confirm_new_password.value != '' && newPassword.value != '' && confirm_new_password.value != null && newPassword.value != null) {
      var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_={}|:;"'<,>.])[A-Za-z\d@$!%*?&~`#^()_={}|:;"'<,>.]{8,}$/);
      let newCurrentPassword = newPassword.value;
      var result = newCurrentPassword.match(pattern);
    }

    if (oldPassword.value === newPassword.value && oldPassword.value != null && newPassword.value != null && oldPassword.value != '' && newPassword.value != '') {
      this.passwordNotMatchError = "Current Password and New Password cannot be the same."
    } else {
      this.clearPasswordNotMatchError();
    }

    if (newPassword.value != confirm_new_password.value && newPassword.value != null && confirm_new_password.value != null && newPassword.value != '' && confirm_new_password.value != '' &&
      confirm_new_password.value != newPassword) {
      this.passwordNotMatchError = "New Password does not match the Confirm New Password!"
      this.changePasswordForm.invalid;
    }
    else {
      this.clearPasswordNotMatchError();
    }
    if (confirm_new_password.value === newPassword.value && confirm_new_password.value != null && newPassword.value != null && confirm_new_password.value != '' && newPassword.value != '') {
      if (!result) {
        this.passwordNotMatchError = "Password must be at least eight characters, with one uppercase letter, one lowercase letter, one number and one special character."
      } else {
        this.clearPasswordNotMatchError();
      }
    }

    return newPassword?.value === confirm_new_password?.value ? null : { notmatched: true };
  };

  open() {
    const modalRef = this.modalService.open(NgbdModalContent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.backClicked();
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.backClicked();
      },
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  clearInfoMessages() {
    this.error = null;
    this.passwordNotMatchError = null;
    this.success = null;
  }

  initOTP() {
    this.clearInfoMessages();
    if (!localStorage.getItem('0').includes('@')) {
      this.localPhone = localStorage.getItem('0')
      this.phoneCheck = true;
    }
    else {
      this.localEmail = localStorage.getItem('0')
      this.emailCheck = true;
    }
    if (this.localPhone) {
      this.ngxloader.start();
      this.userService.getPhoneOTP(this.localPhone).subscribe(resp => {
        if (resp.OTP) {
          this.userService.sendPhoneOTP(this.localPhone).subscribe(resp => {
            if (resp.message === 'Phone OTP was successfully sent!') {
              this.phoneOTP = true;
              this.showOTP = true;
              this.startOTPTimer();
              var data = {
                otp: resp.otp,
                phone: this.localPhone,
                otp_sent_counter: 0,
                otp_sent_date: new Date(),
                telesign_ref_id: resp.telesign_ref_id
              }
            }
            this.ngxloader.stop();
          });
        }
        else {
          this.userService.sendPhoneOTP(this.localPhone).subscribe(resp => {
            if (resp.message === 'Phone OTP was successfully sent!') {
              this.phoneOTP = true;
              this.showOTP = true;
              this.startOTPTimer();
              var data = {
                otp: resp.otp,
                phone_number: this.localPhone,
                otp_sent_counter: this.OTPcounter,
                otp_sent_date: new Date(),
                telesign_ref_id: resp.telesign_ref_id
              }
 
            }
            this.ngxloader.stop();
          });
        }
      });

    }
    if (this.localEmail) {
      this.ngxloader.start();
      this.userService.getEmailOTP(this.localEmail).subscribe(resp => {
        if (resp.OTP) {
          this.userService.sendEmail(this.localEmail).subscribe(resp => {
            if (resp.message === 'Email was successfully sent!') {
              this.emailOTP = true;
              this.showOTP = true;
              this.startOTPTimer();
              var data = {
                otp: resp.otp,
                email: this.localEmail,
                otp_sent_counter: 0,
                otp_sent_date: new Date()
              }
            }
            this.ngxloader.stop();
          });
        }
        if (!resp.OTP) {
          this.userService.sendEmail(this.localEmail).subscribe(resp => {
            if (resp.message === 'Email was successfully sent!') {
              this.showOTP = true;
              this.emailOTP = true;
              this.startOTPTimer();
              var data = {
                otp: resp.otp,
                email: this.localEmail,
                otp_sent_counter: this.OTPcounter,
                otp_sent_date: new Date()
              }

            }
            this.ngxloader.stop();
          });
        }
      });
    }
  }

  checkPhoneOTP() {
    if (this.localPhone) {
      let OTPinput = this.changePasswordForm.get('OtpCode').value;
      this.userService.checkPhoneOTP(this.localPhone, OTPinput).subscribe(resp => {
        /* if (res.OTP) {*/
        if (resp.message == 'OTP matches!') {

          this.clearInfoMessages();
          this.showOTP = false;
          //delete temptable record
          // this.userService.deleteTempPhoneRecord(this.localPhone).subscribe(resp => {            

          // });
        }

        else {
          this.error = "Invalid Code!"
        }
      }

      )
    }
    if (this.localEmail) {
      let OTPinput = this.changePasswordForm.get('OtpCode').value;
      this.userService.checkEmailOTP(this.localEmail, OTPinput).subscribe(resp => {
        /* if (res.OTP) {*/
        if (resp.message == 'OTP matches!') {


          this.clearInfoMessages();
          this.showOTP = false;
          //delete temptable record
          // this.userService.deleteTempEmailRecord(this.localEmail).subscribe(resp => {

          // });
        }
        else {
          this.error = "Invalid Code!"
        }
      }

      )
    }

  }
  resendOTP() {
    this.ngxloader.start();
    if (this.localPhone) {
      this.userService.getPhoneOTP(this.localPhone).subscribe(resp => {
        this.OTPcounter = resp.counter;
        if (this.OTPcounter < 3) {
          this.userService.sendPhoneOTP(this.localPhone).subscribe(resp => {
            if (resp.message === 'Phone OTP was successfully sent!') {
              this.showOTP = true;
              this.startOTPTimer();
              this.ngxloader.stop();
              this.OTPcounter = this.OTPcounter + 1;
              var data = {
                telesign_ref_id: resp.telesign_ref_id,
                otp: resp.otp,
                phone: this.localPhone,
                otp_sent_counter: this.OTPcounter,
                otp_sent_date: new Date()
              }  
              this.ngxloader.stop();
            } else {
              this.ngxloader.stop();
            }
          });
        }
        else {
          var dateHours;
          var DateNow = new Date();
          var dateSpan = new Date(resp.OTPSentDate);
          if (dateSpan.getHours() != 23) {
            dateHours = dateSpan.getHours() + 1
          }
          else {
            dateHours = 0;
          }
          dateSpan.setHours(dateHours);
          if (DateNow >= dateSpan) {
            this.ngxloader.start();
            this.userService.sendPhoneOTP(this.localPhone).subscribe(resp => {
              if (resp.message === 'Phone OTP was successfully sent!') {
                this.showOTP = true;
                this.startOTPTimer();
                this.OTPcounter = this.OTPcounter + 1;
                var data = { otp: resp.otp, phone: this.localPhone, otp_sent_counter: 0, otp_sent_date: new Date() }  
                this.ngxloader.stop();
                this.clearInfoMessages();
              }
            });
            this.ngxloader.stop();
          }
          else {
            this.error = "You must wait an hour before resending again!";
            this.ngxloader.stop();
          }
        }
      });
      this.ngxloader.stop();
    }
  }
  handeOtpChange(value: string[]): void {
    value.forEach(input => {
      if (input == '') {
        this.changePasswordForm.get('OtpCode').setValue(null);
      }
    });
  }

  handleFillEvent(value: string): void {
    this.changePasswordForm.get('OtpCode').setValue(value);

 

  }

  resetOTPTimer() {
    this.showResendOTP = false;
    this.counter = 120
  }

  startOTPTimer() {
    this.resetOTPTimer();
    this.countDown = timer(0, this.tick).subscribe((count) => {
      if (count == 120) {

        //show resent otp button
        this.showResendOTP = true;
        if (this.countDown) {
          this.countDown.unsubscribe();
        }
      }
      --this.counter;
    });
  }

  getSubscriptionDetails() {
    this.ngxloader.start();
    if (this.user.includes("@")) {
      this.userService.getSubscriptionDetailsWithEmail(this.user).subscribe(resp => {
        if (resp.message == 'success') {
          this.oldEmail = resp.email_id
          this.phoneNumber = resp.phone_number;
          this.userService.getIdMariaAPIByPhone(this.phoneNumber).subscribe(resp => {
            if (resp != null) {
              this.userId = resp[0].id
            }
            this.apiCalled = true;
            this.ngxloader.stop();
          }, error => {
            this.apiCalled = true;
            this.ngxloader.stop();
          })
        }
        this.apiCalled = true;
        this.ngxloader.stop();
      }, error => {
        this.apiCalled = true;
        this.ngxloader.stop();
      })
    } else {
      this.userService.getSubscriptionDetailsWithPhoneNumber(this.user).subscribe(resp => {
        if (resp.message == 'success') {
          this.oldEmail = resp.email_id
          this.userService.getIdMariaAPIByPhone(this.user).subscribe(resp => {
            if (resp != null) {
              this.userId = resp[0].id
            }
            this.apiCalled = true;
            this.ngxloader.stop();
          }, error => {
            this.apiCalled = true;
            this.ngxloader.stop();
          })
        }
        this.apiCalled = true;
        this.ngxloader.stop();
      }, error => {
        this.apiCalled = true;
        this.ngxloader.stop();
      })
    }

  }

  makeString(): string {
    let outString: string = '';
    let inOptions: string = 'abcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 10; i++) {

      outString += inOptions.charAt(Math.floor(Math.random() * inOptions?.length));

    }
    this.teststring = outString;
    return outString;
  }

  result: string = this.makeString();
}
