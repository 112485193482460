import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component'
import { SignupEmbeddedComponent } from './signup-embedded/signup-embedded.component'
import { ValidationLinkComponent } from './validation-link/validation-link.component'
import { DashboardComponent } from './dashboard/dashboard.component'
import { DashboardEmbeddedComponent } from './dashboard-embedded/dashboard-embedded.component'
import { SubscriptionHistoryComponent } from './subscription-history/subscription-history.component'
import { SubscriptionCancelComponent } from './subscription-cancel/subscription-cancel.component'
import { SubscriptionRenewComponent } from './subscription-renew/subscription-renew.component'
import { ServerErrorComponent } from './server-error/server-error.component'
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component'
import { PrivacyPolicyEmbeddedComponent } from './privacy-policy-embedded/privacy-policy-embedded.component'
//auth guards
import { FrontAntiAuthGuard } from './shared/services/auth/front-anti-auth.guard';
import { FrontAuthGuard } from './shared/services/auth/front-auth.guard'
import { ServiceAggrementComponent } from './service-aggrement/service-aggrement.component';
import { ServiceAggrementEmbeddedComponent } from './service-aggrement-embedded/service-aggrement-embedded.component';
import { DashboardEmbeddedInitialComponent } from './dashboard-embedded-initial/dashboard-embedded-initial.component';
import { ChangePaymentComponent } from './change-payment/change-payment.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangePasswordLinkComponent } from './change-password-link/change-password-link.component';
import { ChangeEmailComponent } from './change-email/change-email.component';
import { ChangeEmailLinkComponent } from './change-email-link/change-email-link.component';
import { ChangePhonenumberComponent } from './change-phonenumber/change-phonenumber.component';
import { ChangeUsernameComponent } from './change-username/change-username.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordEmbeddedComponent } from './forgot-password-embedded/forgot-password-embedded.component';
import { ChangePasswordEmbeddedComponent } from './change-password-embedded/change-password-embedded.component';
import { ChangePhonenumberEmbeddedComponent } from './change-phonenumber-embedded/change-phonenumber-embedded.component';
import { FeedbackComponent } from './feedback/feedback.component'
import { CheatSheetComponent } from './cheat-sheet/cheat-sheet.component'
import { InstructionsComponent } from './instructions/instructions.component'
import { PlansComponent } from './plans/plans.component'
import { AboutComponent } from './about/about.component';
import { ValidationOtpComponent } from './validation-otp/validation-otp.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full', },
  { path: 'login', component: LoginComponent, pathMatch: 'full', canActivate: [FrontAntiAuthGuard] },
  { path: 'signup', component: SignupComponent, pathMatch: 'full', canActivate: [FrontAntiAuthGuard] },
  { path: 'signup-embedded', component: SignupEmbeddedComponent, pathMatch: 'full'},
  { path: 'validation-link/:param/:token', component: ValidationLinkComponent },
  { path: 'embedded-dashboard/:phone/:password', component: DashboardEmbeddedInitialComponent },
  { path: 'dashboard', component: DashboardComponent, pathMatch: 'full', canActivate: [FrontAuthGuard] },
  { path: 'dashboard-embedded', component: DashboardEmbeddedComponent, pathMatch: 'full', canActivate: [FrontAuthGuard] },
  { path: 'subscription-history', component: SubscriptionHistoryComponent, pathMatch: 'full', canActivate: [FrontAuthGuard] },
  { path: 'subscription-cancel', component: SubscriptionCancelComponent, pathMatch: 'full', canActivate: [FrontAuthGuard] },
  { path: 'subscription-renew', component: SubscriptionRenewComponent, pathMatch: 'full', canActivate: [FrontAuthGuard] },
  { path: 'change-payment', component: ChangePaymentComponent, pathMatch: 'full', canActivate: [FrontAuthGuard] },
  { path: 'change-password', component: ChangePasswordComponent, pathMatch: 'full', canActivate: [FrontAuthGuard] },
  { path: 'change-password-embedded', component: ChangePasswordEmbeddedComponent, pathMatch: 'full', canActivate: [FrontAuthGuard] },
  { path: 'change-password-link/:param', component: ChangePasswordLinkComponent },
  { path: 'forgot-password/:param', component: ForgotPasswordComponent },
  { path: 'validation-otp/:token', component: ValidationOtpComponent },
  { path: 'forgot-password-embedded', component: ForgotPasswordEmbeddedComponent },
  { path: 'change-email', component: ChangeEmailComponent, pathMatch: 'full', canActivate: [FrontAuthGuard] },
  { path: 'change-email-link/:param', component: ChangeEmailLinkComponent },
  { path: 'change-phonenumber', component: ChangePhonenumberComponent, pathMatch: 'full', canActivate: [FrontAuthGuard] },
  { path: 'change-phonenumber-embedded', component: ChangePhonenumberEmbeddedComponent, pathMatch: 'full', canActivate: [FrontAuthGuard] },
  { path: 'change-username', component: ChangeUsernameComponent, pathMatch: 'full', canActivate: [FrontAuthGuard] },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, pathMatch: 'full' },
  { path: 'privacy-policy-embedded', component: PrivacyPolicyEmbeddedComponent, pathMatch: 'full' },
  { path: 'manage-plans', component: PlansComponent, pathMatch: 'full' },
  { path: 'server-error', component: ServerErrorComponent, pathMatch: 'full' },
  { path: 'service-aggrement', component: ServiceAggrementComponent, pathMatch: 'full' },
  { path: 'service-aggrement-embedded', component: ServiceAggrementEmbeddedComponent, pathMatch: 'full' },
  { path: 'feedback', component: FeedbackComponent, pathMatch: 'full' },
  { path: 'cheat-sheet', component: CheatSheetComponent, pathMatch: 'full' },
  { path: 'instructions', component: InstructionsComponent, pathMatch: 'full' },
  { path: 'about-us', component: AboutComponent, pathMatch: 'full' },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', // Add options right here
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
