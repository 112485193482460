import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserAuthService } from '../shared/services/auth/user-auth.service';
import { UserService } from '../shared/services/user.service';
import { Location } from '@angular/common';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { CommonHttpClient } from '../shared/services/common/common-http.service';

@Component({
  selector: 'app-dashboard-embedded',
  templateUrl: './dashboard-embedded.component.html',
  styleUrls: ['./dashboard-embedded.component.css']
})
export class DashboardEmbeddedComponent implements OnInit {

  user: any;
  rateId: string;
  amount: number;
  isActive: boolean = true;;
  subscriptionDetails: any;
  apiCalled: boolean = false;
  showNoData: boolean = false;
  isFreeUser: boolean = true;
  transId: string;
  error: string = null;
  showExpiredCardMessage: boolean = false;
  showTerminatedSubMessage: boolean = false;

  constructor(private userService: UserService, private userAuth: UserAuthService, private _location: Location,
    private ngxloader: NgxUiLoaderService, private router: Router, private ccService: NgcCookieConsentService, private commonHttpInit: CommonHttpClient) {
    this.user = this.userAuth.getStoredUser();
    this.initCommonHTTP();
  }

  ngOnInit(): void {
    this.ccService.destroy();
  }
  async initCommonHTTP()
  {
    this.ngxloader.start();
    var key = await this.commonHttpInit.getApiKey()
      if(key){
        this.getSubscriptionDetails();
      }   
  }
  getSubscriptionDetails() {
    this.ngxloader.start();
    if (this.user.includes("@")) {
      this.userService.getSubscriptionDetailsWithEmail(this.user).subscribe(resp => {
        if (resp.message == 'success') {
          this.rateId = resp.rate_id;
          this.isActive = resp.is_active;
          this.transId = resp.sub_id;
  
          //this.getSubscriptionStatus();
          this.userService.getRateAmount(this.rateId).subscribe(rateResp => {
            this.amount = rateResp.Amount;
            this.subscriptionDetails = resp;
            if (this.amount > 0) {
              this.isFreeUser = false;
            } else {
              this.isFreeUser = true;
            }
          }, error => { })
        }
        this.apiCalled = true;
        this.ngxloader.stop();
      }, error => {
        this.showNoData = true;
        this.apiCalled = true;
        this.ngxloader.stop();
      })
    } else {
      this.userService.getSubscriptionDetailsWithPhoneNumber(this.user).subscribe(resp => {
        if (resp.message == 'success') {
          this.rateId = resp.rate_id;
          this.isActive = resp.is_active;
          this.transId = resp.sub_id;
          //this.getSubscriptionStatus();
          this.userService.getRateAmount(this.rateId).subscribe(rateResp => {
            this.amount = rateResp.Amount;
            this.subscriptionDetails = resp;
            if (this.amount > 0) {
              this.isFreeUser = false;
            } else {
              this.isFreeUser = true;
            }
          }, error => { })
        }
        this.apiCalled = true;
        this.ngxloader.stop();
      }, error => {
        this.showNoData = true;
        this.apiCalled = true;
        this.ngxloader.stop();
      })
    }

  }

  //async getSubscriptionStatus() {
  //  if (this.transId != null) {
  //    this.ngxloader.start();
  //    if (localStorage.getItem('0').includes('@')) {
  //      try {
  //        const rply = await this.userService.subHistoryWithEmail({
  //          transactionId: this.transId
  //        }).toPromise();

  //        let str = JSON.stringify(rply);
  //        let jsonResponse = JSON.parse(str);

  //        if (rply && (rply as any).respCode === 'I00001') {
  //          //check status and display if expired
  //          console.log(rply.status);
  //          console.log(rply.lastTransResponse);
  //          if (rply.lastTransResponse === 'The credit card has expired.' && rply.status === 'active' ||
  //            rply.lastTransResponse === 'The credit card has expired.' && rply.status === 'suspended') {
  //            this.showExpiredCardMessage = true;
  //          }

  //          if (rply.lastTransResponse === 'The credit card has expired.' && rply.status === 'terminated') {
  //            this.showTerminatedSubMessage = true;
  //            // update mongo
  //            //update fam
  //            this.isActive = false;
  //            this.terminateUser();
  //          }

  //          this.apiCalled = true;
  //          this.ngxloader.stop();
  //        } else {
  //          this.apiCalled = true;
  //          this.ngxloader.stop();
  //          this.error = jsonResponse.msgCode;
  //        }

  //        if (rply != null && (rply as any).msgCode == null) {
  //          console.log(jsonResponse);
  //          this.error = jsonResponse;
  //        }

  //        if (rply && 'error' in rply) {
  //          this.error = (rply as any).error.errorText;
  //        }

  //      } catch (ex) {
  //        if (ex.error != null) {
  //          this.showNoData = true;
  //          this.apiCalled = true;
  //          this.error = ex.error;
  //        }
  //        console.error(this.error);
  //        this.ngxloader.stop();
  //      }
  //    } else {
  //      try {
  //        const rply = await this.userService.subHistoryWithPhone({
  //          transactionId: this.transId
  //        }).toPromise();

  //        let str = JSON.stringify(rply);
  //        let jsonResponse = JSON.parse(str);

  //        if (rply && (rply as any).respCode === 'I00001') {
  //          //check status and display if expired
  //          console.log(rply.status);
  //          console.log(rply.lastTransResponse);
  //          if (rply.lastTransResponse === 'The credit card has expired.' && rply.status === 'active' ||
  //            rply.lastTransResponse === 'The credit card has expired.' && rply.status === 'suspended') {
  //            this.showExpiredCardMessage = true;
  //          }

  //          if (rply.lastTransResponse === 'The credit card has expired.' && rply.status === 'terminated') {
  //            this.showTerminatedSubMessage = true;
  //            // update mongo
  //            //update fam
  //            this.isActive = false;
  //            this.terminateUser();
  //          }
  //          this.apiCalled = true;
  //          this.ngxloader.stop();
  //        } else {
  //          this.apiCalled = true;
  //          this.ngxloader.stop();
  //          this.error = jsonResponse.msgCode;
  //        }

  //        if (rply != null && (rply as any).msgCode == null) {
  //          console.log(jsonResponse);
  //          this.error = jsonResponse;
  //        }

  //        if (rply && 'error' in rply) {
  //          this.error = (rply as any).error.errorText;
  //        }

  //      } catch (ex) {
  //        if (ex.error != null) {
  //          this.error = ex.error;
  //        }
  //        console.error(this.error);
  //        this.ngxloader.stop();
  //      }
  //    }
  //  }
  //}

  //terminateUser() {
  //  let updateMongoData = {
  //    is_active: false,
  //    next_billing_date: null,
  //    start_billing_date: null,
  //    auth_sub_id: null
  //  }
  //  if (localStorage.getItem('0').includes('@')) {
  //    try {
  //      var email = localStorage.getItem('0')
  //      //this needs to be flexisip request
  //      this.userService.updateSubscriptionWithEmail(email, updateMongoData).subscribe(resp => {
  //        if (resp.message == 'success') {
  //          /* this.fam_id = resp.fam_id;*/
  //          //this.userService.deactivateFlexisipUser(this.fam_id).subscribe((resp) => {
  //          //  if (resp != null) {
  //          //    this.router.navigate(['/dashboard'])
  //          //  }
  //          //});
  //        }
  //        else {
  //          this.error = "Update failed!";
  //        }
  //        this.ngxloader.stop();
  //      }, error => {

  //        this.ngxloader.stop();
  //      })

  //    } catch (ex) {
  //      if (ex.error != null) {
  //        this.error = ex.error;
  //      }
  //      console.error(this.error);
  //      this.ngxloader.stop();
  //    }
  //  } else {
  //    try {
  //      var phone = localStorage.getItem('0')
  //      //this needs to be flexisip request
  //      this.userService.updateSubscriptionWithPhoneNumber(phone, updateMongoData).subscribe(resp => {
  //        if (resp.message == 'success') {
  //          /* this.fam_id = resp.fam_id;*/
  //          //this.userService.deactivateFlexisipUser(this.fam_id).subscribe((resp) => {
  //          //  if (resp != null) {
  //          //    this.router.navigate(['/dashboard'])
  //          //  }
  //          //});
  //        } else {
  //          this.error = "Update failed!";
  //        }
  //        this.ngxloader.stop();
  //      }, error => {

  //        this.ngxloader.stop();
  //      })

  //    } catch (ex) {
  //      if (ex.error != null) {
  //        this.error = ex.error;
  //      }
  //      console.error(this.error);
  //      this.ngxloader.stop();
  //    }
  //  }
  //}

  logOut() {
    this.ngxloader.start();
    this.userService.logout();
    this.ngxloader.stop();
  }

  backClicked() {
    if (this.router.navigated) {
      this._location.back();
    }

  }


}
