import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserService } from '../shared/services/user.service';
import { AcceptJSService } from '@openutility/acceptjs-angular-wrapper';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
export class NgbdModalContent {
  constructor(public activeModal: NgbActiveModal) { }
}


@Component({
  selector: 'app-subscription-cancel',
  templateUrl: './subscription-cancel.component.html',
  styleUrls: ['./subscription-cancel.component.css']
})

export class SubscriptionCancelComponent implements OnInit {

  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;
  transId: string;
  fam_id: string;
  form: FormGroup;
  redirectCheck: boolean = false;
  error: string = null;
  closeResult = '';
  showTextarea: boolean = false
  reasons: Array<any> = [
    { name: 'I do not use service right now.', value: 'I do not use service right now.' },
    { name: 'I do not need the service for next few months.', value: 'I do not need the service for next few months.' },
    { name: 'The service is too expensive.', value: 'The service is too expensive.' },
    { name: 'I am having call quality issues.', value: 'I am having call quality issues.' },
    { name: 'Other', value: 'other' }
  ];
  constructor(private router: Router, private fb: FormBuilder, private userService: UserService, private ngxloader: NgxUiLoaderService, private _http: HttpClient,private modalService: NgbModal,
    private _acceptJSSrv: AcceptJSService) {
    this.form = this.fb.group({
      checkArray: this.fb.array([]),
      textarea: new FormControl('', [])
    })
    this.getSubscriptionId();
  }

  ngOnInit() { }


  uncheckAll() {
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
  }

  onCheckboxChange(e) {
    this.error = null
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;

    if (e.target.checked) {
      checkArray.clear();
      this.uncheckAll();
      this.showTextarea = false;
      this.form.get('textarea').reset();
      e.target.checked = true;
      checkArray.push(new FormControl(e.target.value));
      if (e.target.value == 'other') {
        this.showTextarea = true
      }
    } else {

      if (e.target.value == 'other') {
        this.showTextarea = false;
        this.form.get('textarea').reset();
      }
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  submitForm() {
    this.error = null;
    this.redirectCheck = false;
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;
    if (checkArray?.length < 1) {
      this.error = "Please select atleast one"
    } else {
      if (checkArray.value.includes('other')) {
        if (this.form.get('textarea').value == null || this.form.get('textarea').value == "") {
          this.error = "Please let us know your reason."
        } else {
          // call the api here 
          let reason = this.form.get('textarea').value;
          reason?.toString().trim();
          this.cancelSubscription(reason);
        }
      } else {
        // call th api here if other not selected
        let reason = this.form.value.checkArray[0];
        reason?.toString().trim();
        this.cancelSubscription(reason);
      }
    }
  }
  getSubscriptionId() {
    if (localStorage.getItem('0') != null) {
      if (localStorage.getItem('0').includes('@')) {
        var email = localStorage.getItem('0')
        this.userService.getSubscriptionIdWithEmail(email).subscribe(resp => {
          if (resp.message === 'success') {
            this.fam_id = resp.fam_id
            this.transId = resp.sub_id;
          }
        }, error => { this.error = 'Error canceling subscription!' })
      }
    }
    if (!localStorage.getItem('0').includes('@')) {
      var phone = localStorage.getItem('0')
      this.userService.getSubscriptionIdWithPhone(phone).subscribe(resp => {
        if (resp.message === 'success') {
          /* this.fam_id = resp.fam_id;*/
          this.transId = resp.sub_id;
        }
      }, error => { this.error = 'Error canceling subscription!' })
    }
  }
  open() {
    const modalRef = this.modalService.open(NgbdModalContent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        window.location.href = "https://www.titaniumsecure.io/login";
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        window.location.href = "https://www.titaniumsecure.io/login";
      },
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  async deleteAndCancelSubscription() {
    if (this.form) {
      this.redirectCheck = true;
      let reason = this.form.get('textarea').value;
      reason?.toString().trim();
      // if(this.user != free){
        await this.cancelSubscription(reason);  
      //}
        
      if (localStorage.getItem('0') != null) {
        if (localStorage.getItem('0').includes('@')) {
          var email = localStorage.getItem('0')

          this.userService.deleteAccountEmailFAM(email).subscribe(resp => {
            if (resp.message === 'Account deleted!') {
              this.userService.deleteAccountEmail(email).subscribe(famResp => {
                if (famResp.message === 'Account deleted!') {
                  localStorage.clear();                  
                  this.ngxloader.stop();
                  //success pop up
                  window.location.href = "https://www.titaniumsecure.io/login";

                } else {
                  this.ngxloader.stop();
                  this.error = 'Error unsubscribing!'
                }
              });

            }
          }, error => { this.error = 'Error unsubscribing!' })
        }

        if (!localStorage.getItem('0').includes('@')) {
          var phone = localStorage.getItem('0')
          this.userService.deleteAccountPhoneFAM(phone).subscribe(resp => {
            if (resp.message === 'Account deleted!') {
              this.userService.deleteAccountPhone(phone).subscribe(famResp => {
                if (famResp.message === 'Account deleted!') { 
                  localStorage.clear();                
                  this.ngxloader.stop();
                  //success pop up
                  window.location.href = "https://www.titaniumsecure.io/login";
                } else {
                  this.ngxloader.stop();
                  this.error = 'Error unsubscribing!'
                }

              });
            }
          }, error => { this.error = 'Error unsubscribing!' })
        }
      }
      else {
        this.error = 'Error unsubscribing!'
      }

    }
  }
  async cancelSubscription(reasonData: string) {

    this.ngxloader.start();
    let updateMongoData = {
      is_active: false,
      next_billing_date: null,
      start_billing_date: null,
      auth_sub_id: null
    }
    if (localStorage.getItem('0').includes('@')) {
      try {

        const rply = await this.userService.cancelSubWithEmail({
          transactionId: this.transId
        }).toPromise();

        let str = JSON.stringify(rply);
        let jsonResponse = JSON.parse(str);

        if (rply && (rply as any).respCode === 'I00001') {
          var email = localStorage.getItem('0')
          //this needs to be flexisip request
          this.userService.updateSubscriptionWithEmail(email, updateMongoData).subscribe(resp => {
            if (resp.message == 'success') {
              this.fam_id = resp.fam_id;
              this.userService.deactivateFlexisipUser(this.fam_id).subscribe((resp) => {
                if (resp != null && this.redirectCheck == false) {
                  this.router.navigate(['/dashboard'])
                }
              });
            }
            else {
              this.error = "Subscription cancelled failed!";
            }
            this.ngxloader.stop();
          }, error => {

            this.ngxloader.stop();
          })
        } else {
          this.ngxloader.stop();
          this.error = jsonResponse.msgCode;
        }

        if (rply != null && (rply as any).msgCode == null) {
          this.error = jsonResponse;
        }

        if (rply && 'error' in rply) {
          this.error = (rply as any).error.errorText;
        }

      } catch (ex) {
        if (ex.error != null) {
          this.error = ex.error;
        }
        console.error(this.error);
        this.ngxloader.stop();
      }
    } else {
      try {
        const rply = await this.userService.cancelSubWithPhone({
          transactionId: this.transId
        }).toPromise();

        let str = JSON.stringify(rply);
        let jsonResponse = JSON.parse(str);

        if (rply && (rply as any).respCode === 'I00001') {
          var phone = localStorage.getItem('0')
          //this needs to be flexisip request
          this.userService.updateSubscriptionWithPhoneNumber(phone, updateMongoData).subscribe(resp => {
            if (resp.message == 'success') {
              this.fam_id = resp.fam_id;
              this.userService.deactivateFlexisipUser(this.fam_id).subscribe((resp) => {
                if (resp != null && this.redirectCheck == false) {
                  this.router.navigate(['/dashboard'])
                }
              });
            } else {
              this.error = "Subscription cancelled failed!";
            }
            this.ngxloader.stop();
          }, error => {

            this.ngxloader.stop();
          })
        } else {
          this.ngxloader.stop();
          this.error = jsonResponse.msgCode;
        }

        if (rply != null && (rply as any).msgCode == null) {
          this.error = jsonResponse;
        }

        if (rply && 'error' in rply) {
          this.error = (rply as any).error.errorText;
        }

      } catch (ex) {
        if (ex.error != null) {
          this.error = ex.error;
        }
        console.error(this.error);
        this.ngxloader.stop();
      }
    }

  }

}
