<div class="wrapper">
    <app-header [headerTitle]="'Subscription'"></app-header>
    <div class="payment-history payment-table-block" *ngIf="!showNoData && apiCalled">
      <div class="backlink pointer"><a [routerLink]="[ '/dashboard' ]"><i class="fas fa-chevron-left"></i> Dashboard</a></div>
        <div class="table_padding">
          <table class="responsive-table">
            <thead>
              <tr class="table-head-lable">
                <th scope="col">Date</th>
                <th scope="col">Description</th>
                <th scope="col">Amount</th>
                <th scope="col">Payment Method</th>
                <th scope="col">Transaction ID</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of subHistory; let i = index; let even=even">
                <td data-title="Date">{{subHistoryDate[i] | date : 'longDate'}}</td>
                <td data-title="Description">Subscription to Titanium™</td>
                <td data-title="Amount">${{amount}}</td>
                <td data-title="Payment Method">Via Card</td>
                <td data-title="Transaction ID">{{item}}</td>
              </tr>
              </tbody>
              <!-- <tr>
        <td>July 15 2021</td>
        <td class="des-td">Subscription to Titanium</td>
        <td class="payment-amount-td">$ 3.99</td>
        <td class="payment-method-td">Visa **** **** 1234</td>
        <td>View Receipt</td>
    </tr>
    <tr>
        <td>July 15 2021</td>
        <td class="des-td">Subscription to Titanium</td>
        <td class="payment-amount-td">$ 3.99</td>
        <td class="payment-method-td">Visa **** **** 1234</td>
        <td>View Receipt</td>
    </tr>
    <tr>
        <td>July 15 2021</td>
        <td class="des-td">Subscription to Titanium</td>
        <td class="payment-amount-td">$ 3.99</td>
        <td class="payment-method-td">Visa **** **** 1234</td>
        <td>View Receipt</td>
    </tr>
    <tr>
        <td>July 15 2021</td>
        <td class="des-td">Subscription to Titanium</td>
        <td class="payment-amount-td">$ 3.99</td>
        <td class="payment-method-td">Visa **** **** 1234</td>
        <td>View Receipt</td>
    </tr> -->
          </table>
            <div class="toolbar">
                <div class="toolbar-dropdown">
                    <span>Show</span>
                    <select>
                        <option>10</option>
                        <option>20</option>
                    </select>
                    <span>Entries</span>
                </div>
                <div class="toolbar-text">Showing 1-10 of {{subHistory?.length}} entries</div>
            </div>
        </div>
    </div>

    <div class="payment-history-page" *ngIf="showNoData && apiCalled">
      <div class="backlink pointer"><a [routerLink]="[ '/dashboard' ]"><i class="fas fa-chevron-left"></i> Dashboard</a></div>
      <div class="payment-history box-shadow">
        <div class="no-subscription">
          <h4>No subscription history</h4>
        </div>
      </div>
    </div>
</div>
