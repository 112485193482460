<div class="wrapper">
  <div class="background">
    <!--<video class="video-banner" loop="true" autoplay muted oncanplay="this.play()"
           onloadedmetadata="this.muted = true">
      <source src="./assets/videos/banner-video-short.mp4" type="video/mp4" />
    </video>-->
    <div class="thankyou-box box-shadow privacy-policy-box">
      <div class="logo">
        <div>
          <a [routerLink]="['/signup']"><img src="./assets/images/logo.png" /></a>
          <span style="margin-top:-20px" class="trademark2">™</span>
        </div>
      </div>
      <div id="text-color" data-aos="fade-up">
        <h3>Titanium™ Secure Privacy Policy</h3>
        <h4> Introduction</h4>
        <p>
          This Privacy Policy sets out how we, Titanium™ Secure, Inc. (“Titanium™ Secure”), use and protect your personal data that you provide to us, or that is otherwise obtained or generated by us, in connection with your use of our cloud-based messaging services (the “Services”). For the purposes of this Privacy Policy, ‘we’, ‘us’ and ‘our’ refers to Titanium Secure, and ‘you’ refers to you, the user of the Services.
        </p>
        <p><strong>Privacy Principles</strong>  Titanium™ Secure has two fundamental principles when it comes to collecting and processing private data:</p>
        <p>We don't use your data to show you ads.</p>
        <p>We only store the data that Titanium™ Secure needs to function as a secure and feature-rich messaging service.</p>
        <p>
          You authorize your wireless carrier to use or disclose information about your account and your wireless
          device, if available, to Titanium™ Secure Services, LLC or its service provider for the duration of your
          business relationship, solely to help them identify you or your wireless device and to prevent fraud.
      </p>
        <p>
          <strong>Terms of Service</strong> This Privacy Policy forms part of our Terms of Service, which describes the terms under which you use our Services and which are available at https://TitaniumSecure.io. This Privacy Policy should therefore be read in conjunction with those terms.
        </p>
        <p><strong>Table of Contents</strong> This Privacy Policy explains the following:</p>
        <ul>
          <li>the legal basis for processing your personal data;</li>
          <li>what personal data we may collect from you;</li>
          <li>how we keep your personal data safe;</li>
          <li>what we may use your personal data for;</li>
          <li>who your personal data may be shared with; and</li>
          <li>your rights regarding your personal data.</li>

        </ul>
        <p><strong>EEA Representative</strong>  If you live in a country in the European Economic Area (EEA), the Services are provided by Titanium™ Secure, which for the purposes of applicable data protection legislation is the data controller responsible for your personal data when you use our Services. However, as Titanium™ Secure is located outside the EEA, you may contact us directly with any issues you have relating to our processing of your personal data.</p>

        <h4>Legal Ground for Processing Your Personal Data</h4>
        <p> We process your personal data on the ground that such processing is necessary to further our legitimate interests (including: (1) providing effective and innovative services to our users; and (2) detecting, preventing, or otherwise addressing fraud or security issues in respect of our provision of Services), unless those interests are overridden by your interest or fundamental rights and freedoms that require protection of personal data.</p>
        <h4>What Personal Data We Use</h4>
        <p>
          <strong>Basic Account Data</strong> Titanium™ Secure is a communication service. You provide your mobile number and basic account data (which may include profile name, profile picture and about information) to create a Titanium™ Secure account.
        </p>
        <p>
          To make it easier for your contacts and other people to reach you and recognize who you are, the screen name you choose, your profile pictures, and your username (should you choose to set one) on Titanium™ Secure are always public. We don't want to know your real name, gender, age or what you like.
        </p>
        <p>
          We do not require your screen name to be your real name. Note that users who have you in their contacts will see you by the name they saved and not by your screen name. This way your mother can have the public name 'Johnny Depp' while appearing as 'Mom' to you and as 'Boss' to her underlings at work (or the other way around, depending on how these relationships are structured).
        </p>
        <p>
          <strong>Your E-mail Address</strong>  When you enable 2-step-verification for your account or store documents using the Titanium™ Secure feature, you can opt to set up a password recovery email. This address will only be used to send you a password recovery code if you forget it. That's right: no marketing or “we miss you” emails.
        </p>
        <p><strong>Your Messages</strong></p>
        <ul>
          <li><strong>Cloud Chats</strong><br />  Titanium™ Secure is a secure communications service. We currently do not store messages, photos, videos, and documents from your cloud chats on our servers and as the applications suite is ephemeral all such data is timed out to self-destruct across all points of contact. All data is transmitted under heavily encrypted formats and the encryption keys in each case are provided by design only to the active parties in each communication string. This way local engineers or physical intruders cannot get access to user data</li>
          <li>
            <strong>Secret Chats</strong><br /> Secret chats use end-to-end encryption. This means that all data is encrypted with a key that only you and the recipient know. There is no way for us or anybody else without direct access to your device to learn what content is being sent in those messages. We do not store your Secure chats on our servers. We also do not keep any logs for messages in secret chats, so after a short period of time we no longer know who or when you messaged via Secure chats. For the same reasons Secure chats are not available in the cloud — you can only access those messages from the device they were sent to or from and only while they are timed in as active.
          </li>
          <li>
            <strong>Media in Secure Chats</strong><br />  When you send photos, videos, or files via Secure chats, before being uploaded, each item is encrypted with a separate key, not known to the server. This key and the file’s location are then encrypted again, this time with the Secure chat’s key — and sent to your recipient. They can then download and decipher the file. This means that the file is technically on one of Titanium Secures’ servers, but it looks like a piece of random indecipherable garbage to everyone except for you and the recipient. We don’t know what this random data stands for and we have no idea which chat it belongs to. We periodically purge this random data from our servers to save disk space.
          </li>
          <li>
            <strong>Public Chats</strong><br /> In addition to private messages, Titanium™ Secure will also support public channels and public groups. All public chats are cloud chats (see section 3.3.1 above). Like everything on Titanium™ Secure, the data you post in public communities is encrypted, both in storage and in transit — but everything you post in public will be accessible to everyone within a public group.
          </li>
        </ul>
        <p><strong>Phone Number and Contacts</strong> Titanium™ Secure uses phone numbers as unique identifiers so that it is easy for you to switch from SMS and other messaging apps and retain your social graph. We ask your permission before syncing your contacts.</p>
        <p>
          We store your up-to-date contacts to notify you as soon as one of your contacts signs up for Titanium™ Secure and to properly display names in notifications. We only need the number and name (first and last) for this to work and store no other data about your contacts.
        </p>
        <p>
          Our automatic algorithms can also use anonymized sets of phone numbers to calculate the approximate number of potential contacts an unregistered phone number may have on Titanium™ Secure. When you open the 'Invite friends' interface, we display the resulting statistics next to your contacts to give you an idea of who could benefit most from joining Titanium™ Secure.
        </p>
        <p>You can always stop syncing contacts or delete them from our servers in Settings > Privacy & Security > Data Settings.</p>
        <p>If you are using Android, Titanium™ Secure will ask you for permission to access your phone call logs (READ_CALL_LOG). If you grant this permission, Titanium Secure will be able verify your account by transmitting a phone call instead of asking you to enter a code. Titanium™ Secure uses this permission only to confirm receipt of the confirmation call by verifying the number in the call log.</p>
        <p><strong>Location Data</strong> If you share a location in a chat, this location data is treated like other messages in cloud or Secure chats respectively.</p>
        <p>
          <strong>Cookies</strong> The only cookies we use are those to operate and provide our Services on the web. We do not use cookies for profiling or advertising. The cookies we use are small text files that allow us to provide and customize our Services, and in doing so provide you with an enhanced user experience. Your browser should allow you to control these cookies, including whether to accept them and how to remove them. You may choose to block cookies with your web browser, however, if you do disable these cookies, you will not be able to log in to Titanium™ Secure Web.
        </p>
        <h4>Keeping Your Personal Data Safe</h4>
        <p>
          <strong>Storing Data</strong> If you signed up for Titanium™ Secure from the UK or the EEA, your data is stored in Germany. These are third-party provided data centers in which Titanium™ Secure rents a designated space. However, the servers and networks that sit inside these data centers and on which your personal data is stored are owned by Titanium™ Secure. As such, we do not share your personal data with such data centers. All data is stored heavily encrypted so that local Titanium™ Secure engineers or physical intruders cannot get access.
        </p>
        <p>
          <strong>End-to-End Encrypted Data</strong> Your messages, media, and files from secret chats (see section 3.3.2 above), as well as the contents of your calls and the data you store in your Titanium™ Secure Applications are processed only on your device and on the device of your recipient. Before this data reaches our servers, it is encrypted with a key known only to you and the recipient. While Titanium™ Secure servers will handle this end-to-end encrypted data to deliver it to the recipient – or store it in the case of Titanium™ Secure Device data, we have no ways of deciphering the actual information. In this case, we neither store nor process your personal data, rather we store and process random sequences of symbols that have no meaning without the keys which we don’t have.
        </p>
        <p><strong>Retention</strong> Unless stated otherwise in this Privacy Policy, the personal data that you provide us will only be stored for as long as it is necessary for us to fulfill our obligations in respect of the provision of the Services.</p>

        <h4>Processing Your Personal Data</h4>
        <p><strong>Our Services</strong> Titanium™ Secure is a cloud service. We will process your data to deliver your cloud chat history while active, including messages, media, and files, to any devices of your choosing without a need for you to use third-party backups or cloud storage.</p>
        <p><strong>Safety and Security</strong> Titanium™ Secure will support communities which we have to police against abuse and Terms of Service violations. Titanium™ Secure will be growing a unique User Audience which makes it a target for spammers. To improve the security of your account, as well as to prevent spam, abuse, and other violations of our Terms of Service, we may collect metadata such as your IP address, devices, and Titanium™ Secure apps you've used, history of username changes, etc. If collected, this metadata can be kept for 12 months maximum.</p>
        <p><strong>Spam and Abuse</strong> To prevent phishing, spam and other kinds of abuse and violations of Titanium™ Secures Terms of Service, our moderators may check messages that were reported to them by their recipients. If a spam report on a message you sent is confirmed by our moderators, your account may be limited from contacting strangers – temporarily or permanently. You can send an appeal using @Spambot. In case of more serious violations, your account may be banned. </p>
        <p><strong>Cross-Device Functionality</strong> We may also store some aggregated metadata to create Titanium™ Secure features (see section 5.5 below) that work across all your devices.</p>
        <p><strong>Advanced features</strong> We may use some aggregated data about how you use Titanium™ Secure to build useful features including enterprise group contacts and automation of prioritization of likely (or most recent active) users for quicker communications access.</p>
        <p><strong>No Ads Based on User Data</strong> Unlike other services, we don't use your data for ad targeting or other commercial purposes. Titanium™ Secure only stores the information it needs to function as a secure and feature-rich cloud service.</p>
        <p>Titanium™ Secure offers a tool for advertisers to promote their messages in public one-to-many channels, but these sponsored messages are based solely on the topic of the public channels in which they will be shown. No user data is mined or analyzed to display ads or sponsored messages.</p>

        <h4>Bot Messages</h4>
        <p><strong>Ecosystem (future use)</strong> Titanium™ Secure has an API that will allow third-party developers to create bots. Bots are apps that look like special Titanium™ Secure users: you can talk to them from your chat list, add them to groups or use a special “inline” interface to access their features. By performing any of these actions, you will be sending some of your data to the respective third-party bot developers when this feature set becomes active.</p>
        <p><strong>How Bots Can Receive Data</strong> You can send data to bot developers when you interact with their bots in one of these ways:</p>
        <ul>
          <li>By sending messages to a bot.</li>
          <li>By using an inline bot.</li>
          <li>By participating in a group with a bot.</li>
          <li>By pressing buttons in messages sent by a bot.</li>
          <li>By paying for goods and services via bots (see section 7 below).</li>
        </ul>
        <p><strong>What Data Bots Receive</strong> In any of the above cases, the developers of an automated user (bot) can get your public account data (see section 3.1 above): your screen name, username and profile picture(s). Bots can also receive the following data when you interact with them.</p>
        <p>Bots will obviously get your messages when you send them something.</p>
        <p>If you click on links or buttons provided by the bot, the bot can potentially get your IP address (provided that it controls the website to which the link leads).</p>
        <p>If the bot is a member of the same group with you, it may know you are a member.</p>
        <p>When you start your message with the username of an inline bot (e.g. @gif) the interface transforms so that everything you type becomes a query to that bot. This query is sent to the bot so that it can provide its service. We will warn you about this the first time you use an inline bot.</p>
        <p>Bots added to groups can operate in two modes: with access to messages in the group or without access. If the bot has access to messages, it can see everything that happens in the group. The interface clearly shows whether or not a bot has access to messages in groups.</p>
        <p><strong>Bots Are Not Maintained by Titanium™ Secure</strong> Other than our own bots, no other bots or third-party bot developers are affiliated with Titanium™ Secure. They are completely independent from us. They should ask you for your permission before they access your data, or you make it available to them.</p>
        <h4>Third Party Payment Services</h4>
        <p><strong>Payment Information</strong> The Payment Platform for Bots will be made available to users as of Titanium™ Forum Enterprise. Titanium™ Secure does not process payments from users and instead relies on different payment providers around the world. It is the payment providers that handle and store your credit card details. Neither Titanium™ Secure nor the merchants on the platform (bot developers) have access to this information. Although we work with payment providers they are completely independent from Titanium™ Secure. Please study their relevant privacy policies before making your data available to them.</p>
        <p><strong>Credit Card Information</strong> When making a purchase, you enter your credit card details into a form supplied by the payment provider that will be processing the payment, and this information goes directly to the payment provider's server. Your credit card information never reaches Titanium™ Secure's servers. We do not access and do not store your credit card information. When you save your credit card info, it is saved on the respective payment provider's servers and the payment provider gives Titanium™ Secure a token that you can reuse for future payments. It is not possible to reconstruct your credit card info from the token.</p>
        <p><strong>Shipping Information</strong> When you enter shipping information in the process of placing an order, we send it directly to the merchant bot developer. We can store your shipping information for you if you choose to save it for future purchases. We will delete this information immediately if you ask us to.</p>
        <p><strong>Clearing Payment Information</strong> You can clear all payment information associated with your account at any time by currently sending a email with "Clear Payment Info" Header in the Subject Matter to <a href="mailto:clientservices@titaniumsecure.io">clientservices@titaniumsecure.io</a>  Your Payment Info will be cleared, and you can enter new info with login.</p>
        <p><strong>Payment Disputes</strong> Since Titanium™ Secure doesn't store any credit card details or transaction information, it is impossible for us to handle complaints or cashbacks – any disputed payments are the responsibility of the bot developers, payment providers, and banks that participated in the exchange.</p>
        <h4>Who Your Personal Data May Be Shared With</h4>
        <p><strong>Other Titanium™ Secure Users</strong> Other users of our Services with whom you choose to communicate with and share certain information, who may be located outside the EEA. Note that by entering into the Terms of Service and choosing to communicate with such other users of Titanium™ Secure, you are instructing us to transfer your personal data, on your behalf, to those users in accordance with this Privacy Policy. We employ all appropriate technical and organizational measures (including encryption of your personal data) to ensure a level of security for your personal data that is appropriate to the risk.</p>
        <p><strong>Titanium™ Secures Group Companies</strong> We may share your personal data with: (1) our parent company, 14ALL Inc, located in South Dakota, U.S.A.; to help provide, improve, and support our Services. We will implement appropriate safeguards to protect the security and integrity of that personal data. This will take the form of standard contract clauses approved by the European Commission in an agreement between us and our relevant group companies. If you would like more information regarding these clauses, please contact us using the details in section 12 below.</p>
        <p><strong>Law Enforcement Authorities</strong> If Titanium™ Secure receives a court order that confirms you're a terror suspect, we may disclose your IP address (if available) and phone number of registration to the relevant authorities. So far, this has never happened. When it does, we will include it in a semiannual transparency report published at: https://titaniumsecure.io.</p>
        <h4>Your Rights Regarding the Personal Data You Provide to Us</h4>
        <p><strong>Your Rights</strong> Under applicable data protection legislation, in certain circumstances, you have rights concerning your personal data. You have a right to: (1) request a copy of all your personal data that we store and to transmit that copy to another data controller; (2) delete (see section 10 below) or amend your personal data; (3) restrict, or object to, the processing of your personal data; (4) correct any inaccurate or incomplete personal data we hold on you; and (5) lodge a complaint with national data protection authorities regarding our processing of your personal data.</p>
        <p><strong>Exercising Your Rights</strong> If you wish to exercise any of these rights, kindly contact us using the details in section 12 below.</p>
        <p>
          <strong>Data Settings</strong> You can control how your data is used (e.g., delete synced contacts) in Settings > Privacy & Security > Data Settings (using one of our mobile apps).
        </p>
        <p>Sadly, if you're not OK with Titanium™ Secures modest minimum requirements, it won't be possible for us to provide you with our Services. You can delete your Titanium™ Secure account by proceeding to the deactivation page.</p>
        <h4>Deleting data</h4>
        <p><strong>Accounts</strong> If you would like to delete your account, you can do this on the deactivation page. Deleting your account removes all messages, media, contacts, and every other piece of data you store in the Titanium™ Secure cloud. This action must be confirmed via your Titanium™ Secure account and cannot be undone.</p>
        <p><strong>Messages</strong> In Secure chats, ephemeral deletion of a message always instructs the app on the other end to delete it too.</p>
        <p>In cloud chats, you can choose to delete a message for all participants within a default of 120 seconds after sending. Otherwise, deleting a message will delete it from your message history. This means that a copy will stay on the server as part of your partner's message history until the duration is activated. As soon as your partner deletes it too, it's gone forever.</p>
        <p><strong>Self-Destructing Messages</strong> Messages in Secure Chats can be ordered to self-destruct. As soon as such a message is read (2 checks appear), the countdown starts. When the timer expires, both devices participating in a secret chat are instructed to delete the message (photo, video, etc.). Media with short timers (less than a minute) are shown with blurred previews. The timer is triggered when they are viewed.</p>
        <h4>Changes to this Privacy Policy</h4>
        <p>We will review and may update this Privacy Policy from time to time. Any changes to this Privacy Policy will become effective when we post the revised Privacy Policy on this page TitaniumSecure.io/privacy. Please check our website frequently to see any updates or changes to our Privacy Policy, a summary of which we will set out below.</p>
        <p>January 31, 2022</p>
        <p>Important changes made to this Privacy Policy will be notified to you via Titanium™ Secure.</p>
        <h4>Questions and concerns</h4>
        <p> If you have any questions about privacy and our data policies, please contact our client services at info@titaniumsecure.io. <br>Use the /access command to learn how to get a copy of your Titanium™ Secure data and use the /contact command to leave a request, which we will answer at the earliest opportunity.</p>
        <p>Titanium™ Secure may be repurposed for private or public partnerships. Contact Titanium™ Secure for further details.</p>
      </div>
    </div>
  </div>
</div>




