import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.css']
})
export class InstructionsComponent implements OnInit {

  constructor(private _location: Location, private router: Router, private ccService: NgcCookieConsentService) { }

  ngOnInit(): void {
    this.ccService.destroy();
  }

}
