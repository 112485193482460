import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserAuthService } from '../../services/auth/user-auth.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() headerTitle = '';
  user:any;
  currentUrl:any;
  constructor(private userService: UserService, private userAuth: UserAuthService, private router: Router) {

    this.getUsers();
  }

  ngOnInit(): void {
  }

  getUsers(){
    this.user = this.userAuth.getStoredUser();
  }

  logOut(){
    this.userService.logout();
  }

  goToHome(){
    this.router.navigate(['/'])
  }

}
