<!-- <div class="container h-100 d-flex justify-content-center align-items-center" style="height:100%;">
    <div class="jumbotron my-auto">
      <h1>500</h1>
      <p> Internal server error</p>
    </div>
</div> -->

<div class="wrapper">
  <app-header [headerTitle]="''"></app-header>

  <div class="payment-history-page">
      <div class="payment-history box-shadow">
          <div class="subscription-amount-block">
              <div class="amount" >
                  <strong>500</strong><br />
                  <p>Internal Server Error</p>
              </div>
          </div>
          <div class="view-payment-history-text">
            <p></p>
            <p>We are working to resolve this soon.</p>
            <!-- <p><a [routerLink]="[ '/subscription-history' ]" routerLinkActive="active">View Payment History</a></p> -->
        </div>
      </div>
  </div>
</div>
