import { Component, OnInit, OnDestroy, SimpleChanges, HostListener, OnChanges, Input, EventEmitter, ViewChild, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CreditCardValidators } from 'angular-cc-library';
import { UserService } from '../shared/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from "ngx-ui-loader";

import { AcceptJSService, CreditCard } from '@openutility/acceptjs-angular-wrapper';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { Subscription, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { delay, isEmpty, tap } from 'rxjs/operators';
import { REPL_MODE_SLOPPY } from 'repl';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TelesignService } from '../shared/services/telesign.service';


declare function phoneOrEmail(): any;
declare function autoFillDisplayName(): any;
declare function viewPassword(): any;
declare function viewCPassword(): any;
declare function checkPassword(): any;
declare function updateRequirement(id: string, isValid: boolean): any;

@Component({
  selector: 'ngbd-modal-content',
  template: `
		<div class="modal-header">
			<h4 style="color:black" class="modal-title">Notice</h4>
			<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
		</div>
		<div class="modal-body">
			<h5 style="color:black">Verification link successfully resend</h5>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-blue submitBtn" (click)="activeModal.close('Close click')">OK</button>
		</div>
	`,
})
export class NgbdModalContent {
  constructor(public activeModal: NgbActiveModal) { }
}

@Component({
  selector: 'app-signup-edded',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  animations: [
    trigger(
      'slideView',
      [
        state('true', style({ transform: 'translateX(100%)', opacity: 0 })),
        state('false', style({ transform: 'translateX(0)', opacity: 1 })),
        transition('0 => 1', animate('500ms', style({ transform: 'translateX(0)', 'opacity': 1 }))),
        transition('1 => 1', animate('500ms', style({ transform: 'translateX(100%)', 'opacity': 0 }))),
      ]),

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)', 'opacity': 1 }))
      ]),

      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('0ms ease-in', style({ transform: 'translateX(100%)', 'opacity': 0 }))
      ])
    ])
  ]
})
export class SignupComponent implements OnInit, OnDestroy {
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 4,
    autofocus: true,
    classList: {
      inputBox: "my-super-box-class",
      input: "my-super-class",
      inputFilled: "my-super-filled-class",
      inputDisabled: "my-super-disable-class",
      inputSuccess: "my-super-success-class",
      inputError: "my-super-error-class"
    }
  };

  checkConfirm: boolean = false;
  FAMID: string;
  emailOTP: boolean = false;
  phoneOTP: boolean = false;
  ConfirmationToken: string;
  ProvisioningToken: string;
  OTPcounter: number = 0;
  emailOrPhoneExists: boolean;
  phoneCheck = "checked";
  emailCheck: string;
  phoneOption: boolean = false;
  response: string;
  defaultSignInAction = 'Phone';
  signinAction = [{ id: '1', value: 'Phone' }, { id: '2', value: 'Email' }]
  billing_amount: number;
  end_date: Date;
  subscription_id: string;
  error: string; success: string;
  strMessage: string;
  passwordNotMatchError: string;
  emailNotMatchError: string;
  signupForm: FormGroup;
  signSubmit: boolean = false;
  ccSubmit: boolean = false
  creditCardForm: FormGroup;
  redirected: boolean = false;
  steps: number;
  rateId: string;
  hasChange: boolean;
  currentPromoCodeId: string;
  updated_price: number;
  ShowThankyouScreen: boolean = false;
  planName: string;
  numberOfSeats: number;
  errorSeats: string;
  disableButton: boolean = false;
  totalSeats: number;
  planPrice: number;
  individualPlan: boolean = false;
  pricePerSeat: number;
  discount_amount: number;
  windowRef: any;
  recaptchaVerifier: any;
  //plans from 5-50 users and above don't have a trial period
  trial_occurrences: number;
  promoCodeActive: boolean = false;

  //variables for int number 
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];
  ////end
  phone_number = '';
  email_id = '';
  confirm_email = '';
  first_name = '';
  last_name = '';
  display_name = '';
  password: string;
  confirm_password: string;
  closeResult = '';
  //to Display otp input
  showOTP: boolean = false; //this should false when init
  showResendOTP: boolean = false // to show resend otp button -- should be false when init

  //for otp timer
  countDown: Subscription;
  counter = 120;
  tick = 1000;
  emailOption: boolean;

  constructor(private userService: UserService, private telesignService: TelesignService, private ngxloader: NgxUiLoaderService,
    private router: Router, private _acceptJSSrv: AcceptJSService, private _http: HttpClient, private route: ActivatedRoute, private ccService: NgcCookieConsentService,
    private modalService: NgbModal) {
    this.initSignUpForm();
    //this.initSignUpFormWithValues();
    //this is for preserve steps=2 when redirect to url = /sigup when step=2
    this.userService.isRedirected$.subscribe(data => {
      this.redirected = data;
      this.steps = this.redirected ? 2 : 1;
    });
  }

  ngOnInit(): void {
    this.OnChanges()
    this.signupForm.reset();
    setTimeout(() => {
      this.phoneOption = phoneOrEmail(), 1000
    });
    this.ccService.destroy();
  }

  get f() {
    return this.signupForm.controls;
  }

  get ccf() {
    return this.creditCardForm.controls;
  }

  initSignUpFormWithValues() {
    this.signupForm = new FormGroup({
      first_name: new FormControl('Anuj', [Validators.required]),
      last_name: new FormControl('T', [Validators.required]),
      email_id: new FormControl('anuj@zap', [Validators.required]),
      phone_number: new FormControl('+16505551234', [Validators.required]),
      // password : new FormControl('' , [ Validators.required ]),
      referral_code: new FormControl('', []),
    });

    this.creditCardForm = new FormGroup({
      card_holder_name: new FormControl('Nikhil Gandhi', Validators.required),
      card_number: new FormControl('4242424242424242', [Validators.required, CreditCardValidators.validateCCNumber]),
      exp_month: new FormControl('08', [Validators.required]),
      exp_year: new FormControl('2026', [Validators.required]),
      cvv: new FormControl('426', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
      postal_code: new FormControl('58962', [Validators.required])
    });

  }


  onSilentVerifyClick() {
    this.telesignService.silentVerifyInit();
  }
  initSignUpForm() {
    this.signupForm = new FormGroup({
      first_name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      last_name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      display_name: new FormControl('', [Validators.maxLength(40)]),
      email_id: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/), Validators.maxLength(254)]),
      confirm_email: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/), Validators.maxLength(254)]),
      phone_number: new FormControl('+359888888888', [Validators.required, Validators.maxLength(50)]),
      password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_={}|:;"'<,>.])[A-Za-z\d@$!%*?&~`#^()_={}|:;"'<,>.]{8,}$/)]),
      confirm_password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_={}|:;"'<,>.])[A-Za-z\d@$!%*?&~`#^()_={}|:;"'<,>.]{8,}$/)]),
      OtpCode: new FormControl('', [Validators.required]),
      referral_code: new FormControl('', []),
      acceptTermsAndConditions: new FormControl(false, [Validators.requiredTrue]),
      plan_name: new FormControl('', [Validators.required]),
      number_of_seats: new FormControl('', [Validators.required]),
    }, { validators: [this.passwordMatchingValidatior, this.emailMatchingValidatior] });

    this.creditCardForm = new FormGroup({
      fullName: new FormControl('', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]),
      cardNumber: new FormControl('', [Validators.required, CreditCardValidators.validateCCNumber, Validators.maxLength(20)]),
      month: new FormControl('', [Validators.required]),
      year: new FormControl('', [Validators.required]),
      cardCode: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
      zip: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(15)]),
    });

  }
  verifyUserSubscription() {
    this.clearInfoMessages();
    let phone = this.signupForm.get('phone_number').value;
    let email = this.signupForm.get('email_id').value;
    let obj = this;
    this.emailOrPhoneExists = false;
    let tempSteps = obj.steps;
    this.error = null;
    tempSteps = obj.steps + 1;
    let set_display_name = this.signupForm.get('display_name').value;
    this.display_name = set_display_name;
    this.creditCardForm.get('fullName').setValue(this.signupForm.get('first_name').value + " " + this.signupForm.get('last_name').value);

    if (phone) {
      this.phone_number = this.removePlusFromstring(phone.e164Number);
      let data = {
        phone_number: this.removePlusFromstring(phone.e164Number)
      }
      this.userService.validateUserPhoneNumber(data).subscribe(resp => {
        this.ngxloader.start();
        if (resp.message === 'success (phone number already exists)') {
          this.response = resp.message;
          this.error = "This number is already registered";
          this.ngxloader.stop();
          console.log(obj.steps);
          if (obj.steps != 1) {
            obj.steps = 2;
            this.ngxloader.stop();
          }
        }
      }, err => {
        if (obj.steps != 3 && email == null) {
          this.ngxloader.start();
          this.userService.getPhoneOTP(this.phone_number).subscribe(resp => {
            if (resp.OTP) {
              this.userService.sendPhoneOTP(this.phone_number).subscribe(resp => {
                if (resp.message === 'Phone OTP was successfully sent!') {
                  this.phoneOTP = true;
                  this.showOTP = true;
                  this.startOTPTimer();
                  var data = {
                    otp: resp.otp,
                    phone: this.phone_number,
                    otp_sent_counter: 0,
                    otp_sent_date: new Date(),
                    telesign_ref_id: resp.telesign_ref_id
                  }

                }
                else {
                  if (tempSteps != 3) { obj.steps = tempSteps; }
                }
                this.ngxloader.stop();
              });
            }
            else {
              this.userService.sendPhoneOTP(this.phone_number).subscribe(resp => {
                if (resp.message === 'Phone OTP was successfully sent!') {
                  this.phoneOTP = true;
                  this.showOTP = true;
                  this.startOTPTimer();
                  var data = {
                    otp: resp.otp,
                    phone_number: this.phone_number,
                    otp_sent_counter: this.OTPcounter,
                    otp_sent_date: new Date(),
                    telesign_ref_id: resp.telesign_ref_id
                  }

                }
                else {
                  if (tempSteps != 3) { obj.steps = tempSteps; }
                }
                this.ngxloader.stop();
              });
            }
          });
        }
      });
    }
    if (email) {
      let data = {
        email_id: email
      }
      this.userService.validateUserEmailAddress(data).subscribe(resp => {
        this.ngxloader.start();
        if (resp.message === 'success (email already exists)') {
          this.response = resp.message;
          this.error = "This email is already registered";
          this.ngxloader.stop();
          this.emailOrPhoneExists = true;
          /*tempSteps = tempSteps - 1;*/
          if (obj.steps != 1) {
            obj.steps = 2;
            this.ngxloader.stop();
          }
        }
      }, err => {
        if (obj.steps != 3 && phone == null) {
          this.ngxloader.start();
          this.userService.getEmailOTP(email).subscribe(resp => {
            if (resp.OTP) {
              this.userService.sendEmail(email).subscribe(resp => {
                if (resp.message === 'Email was successfully sent!') {
                  this.emailOTP = true;
                  this.showOTP = true;
                  this.startOTPTimer();
                  var data = {
                    otp: resp.otp,
                    email: email,
                    otp_sent_counter: 0,
                    otp_sent_date: new Date()
                  }

                }
                else {
                  if (tempSteps != 3) { obj.steps = tempSteps; }
                }
                this.ngxloader.stop();
              });
            }
            if (!resp.OTP) {
              this.userService.sendEmail(email).subscribe(resp => {
                if (resp.message === 'Email was successfully sent!') {
                  this.showOTP = true;
                  this.emailOTP = true;
                  this.startOTPTimer();
                  var data = {
                    otp: resp.otp,
                    email: email,
                    otp_sent_counter: this.OTPcounter,
                    otp_sent_date: new Date()
                  }

                }
                else {
                  if (tempSteps != 3) { obj.steps = tempSteps; }
                }
                this.ngxloader.stop();
              });
            }
          });
        }
      });
    }
    if (phone == '') {
      this.error = "Please enter phone number";
    }
    if (obj.steps == 2) {
      this.onNext();
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public async onSubmit(): Promise<void> {
    this.ngxloader.start();
    this.error = null;
    let unformattedCardNumber = this.creditCardForm.get('cardNumber').value;
    let formattedCard = this.removeSpaceBetween(unformattedCardNumber);
    this.creditCardForm.value.cardNumber = formattedCard;
    if (!this.creditCardForm.valid) {
      return;
    }
    let cc: CreditCard = this.creditCardForm.value;
    let ccExpDate = cc.year + '-' + cc.month;
    let ccNumber = cc.cardNumber;
    let ccCode = cc.cardCode;
    let zipCode = cc.zip;
    //trial only for individual
    if (this.planName == "Individual") {
      this.trial_occurrences = 1;
    } else {
      this.trial_occurrences = 0;
    }
    console.log(this.trial_occurrences);
    if (this.updated_price != 0) {
      try {
        // generate a nonce payment using the acceptJS service.
        const nonce = await this._acceptJSSrv.generatePaymentNonce(cc);
        await this.delay(6000);
        /*this.ngxloader.stop();*/
        // submit nonce to your server with payment amount
        const rply = await this.userService.payment({
          nonce,
          amount: this.updated_price,
          fullName: this.creditCardForm.value.fullName,
          email: this.signupForm.value.email_id,
          phoneNumber: this.signupForm.value.phone_number.nationalNumber,
          trial_amount: 0,
          promo_code: this.signupForm.value.referral_code,
          trial_occurrences: this.trial_occurrences
        }).toPromise();

        this.strMessage = JSON.stringify(rply);
        let jsonResponse = JSON.parse(this.strMessage);

        if (rply == 'Invalid OTS Token.') {
          this.retryPayment();
        }

        if (rply != null && rply != 'Invalid OTS Token.' && jsonResponse.transId != null) {
          this.subscription_id = jsonResponse.transId;
          this.billing_amount = jsonResponse.subAmount;
        }
        if (rply && (rply as any).respCode === 'I00001') {
          this.createUser(jsonResponse.dateInterval.startDate);
        }
        if (rply != null && rply != 'Invalid OTS Token.' && jsonResponse.transId == null) {
          if (rply == "Bill-To Last Name is required.") {
            this.error = 'Last Name is required.'
          }
          else {
            this.error = rply;
          }
        }

        if (rply && 'error' in rply) {
          this.error = (rply as any).error.errorText;
        }

      } catch (ex) {
        if (this.strMessage != '"Invalid OTS Token."') {
          if (ex.messages != null) {
            this.error = ex.messages.message[0].text;
          }
          console.error(this.error);
          this.ngxloader.stop();
        }
      }
    } else {
      try {
        // generate a nonce payment using the acceptJS service.
        //const nonce = await this._acceptJSSrv.generatePaymentNonce(cc);
        //await this.delay(6000);
        /*this.ngxloader.stop();*/
        // submit nonce to your server with payment amount
        const rply = await this.userService.authorizeCard({
          ccNumber,
          ccExpDate,
          ccCode,
          zipCode,
          amount: 0,
          fullName: this.creditCardForm.value.fullName
        }).toPromise();

        this.strMessage = JSON.stringify(rply);
        let jsonResponse = JSON.parse(this.strMessage);

        if (rply != null) {
          this.subscription_id = jsonResponse.transId;
          this.billing_amount = jsonResponse.subAmount;
        }
        if (rply && (rply as any).respCode === '1' || rply && (rply as any).respCode === '4') {
          this.createUser(jsonResponse.dateInterval.startDate);
        }

        if (rply && 'error' in rply) {
          this.error = (rply as any).error.errorText;
        }


      } catch (ex) {
        if (ex.messages != null) {
          this.error = ex.messages.message[0].text;
        } else {
          this.error = this.strMessage.split('"').join('');
        }
        console.error(this.error);
        this.ngxloader.stop();
      }
    }
  }
  checkEmailOTP() {
    let email = this.signupForm.get('email_id').value;
    let OTPinput = this.signupForm.get('OtpCode').value;
    this.signupForm.get('confirm_email').setValue(email);
    this.userService.checkEmailOTP(email, OTPinput).subscribe(resp => {
      /* if (res.OTP) {*/
      if (resp.message = 'OTP matches!') {
        this.emailOption = true;
        let obj = this;
        obj.steps = 2;
        setTimeout(() => {
          autoFillDisplayName(), 1000
        });
        this.clearInfoMessages();

        if (localStorage.getItem('planName') != null) {
          this.planName = localStorage.getItem('planName');
          this.numberOfSeats = Number(localStorage.getItem('numberOfSeats'));
        } else {
          this.planName = "Individual";
          this.numberOfSeats = 0;
        }

        this.signupForm.get('plan_name').setValue(this.planName);
        this.signupForm.get('number_of_seats').setValue(this.numberOfSeats);
        this.signupForm.get('plan_name').disable();
        //disable promo codes for all business plans
        //this.signupForm.get('referral_code').disable();

        if (this.planName == 'Individual') {
          this.individualPlan = true;
          this.signupForm.get('number_of_seats').disable();
          //this.signupForm.get('referral_code').enable();
        }

        //delete temptable record
        // this.userService.deleteTempEmailRecord(email).subscribe(resp => { });

      }
      else {
        this.error = "Invalid Code!"
      }
    }
    )
  }
  checkPhoneOTP() {
    let phone = this.signupForm.get('phone_number').value;
    console.log(this.CountryISO);
    var phone_number = this.removePlusFromstring(phone.e164Number);
    let OTPinput = this.signupForm.get('OtpCode').value;
    this.userService.checkPhoneOTP(phone_number, OTPinput).subscribe(resp => {
      /* if (res.OTP) {*/
      if (resp.message = 'OTP matches!') {
        this.emailOption = false;
        let obj = this;
        obj.steps = 2;
        setTimeout(() => {
          autoFillDisplayName(), 1000
        });
        this.clearInfoMessages();

        if (localStorage.getItem('planName') != null) {
          this.planName = localStorage.getItem('planName');
          this.numberOfSeats = Number(localStorage.getItem('numberOfSeats'));
        } else {
          this.planName = "Individual";
          this.numberOfSeats = 0;
        }

        this.signupForm.get('plan_name').setValue(this.planName);
        this.signupForm.get('number_of_seats').setValue(this.numberOfSeats);
        this.signupForm.get('plan_name').disable();
        //disable promo codes for all business plans
        //this.signupForm.get('referral_code').disable();


        if (this.planName == 'Individual') {
          this.individualPlan = true;
          this.signupForm.get('number_of_seats').disable();
          //this.signupForm.get('referral_code').enable();
        }

        //delete temptable record
        // this.userService.deleteTempPhoneRecord(phone_number).subscribe(resp => { });
      }
      else {
        this.error = "Invalid Code!"
      }
    }
    )
  }
  resendOTP() {
    let email = this.signupForm.get('email_id').value;
    let phone = this.signupForm.get('phone_number').value;
    if (email) {
      this.ngxloader.start();
      this.userService.getEmailOTP(email).subscribe(resp => {
        this.OTPcounter = resp.counter;
        if (this.OTPcounter < 3) {
          this.userService.sendEmail(email).subscribe(resp => {
            if (resp.message === 'Email was successfully sent!') {
              this.showOTP = true;
              this.startOTPTimer();
              this.ngxloader.stop();
              this.OTPcounter = this.OTPcounter + 1;
              var data = { otp: resp.otp, email: email, otp_sent_counter: this.OTPcounter, otp_sent_date: new Date() }
            } else {
              this.ngxloader.stop();
            }
          });
        }
        else {
          var dateHours;
          var DateNow = new Date();
          var dateSpan = new Date(resp.OTPSentDate);
          if (dateSpan.getHours() != 23) {
            dateHours = dateSpan.getHours() + 1
          }
          else {
            dateHours = 0;
          }
          dateSpan.setHours(dateHours);
          //var DateError = OTPDate - DateNow;
          if (DateNow >= dateSpan) {
            this.userService.sendEmail(email).subscribe(resp => {
              if (resp.message === 'Email was successfully sent!') {
                this.showOTP = true;
                this.startOTPTimer();
                this.OTPcounter = this.OTPcounter + 1;
                var data = { otp: resp.otp, email: email, otp_sent_counter: 0, otp_sent_date: new Date() }

                this.ngxloader.stop();
                this.clearInfoMessages();
              }
            });
            this.ngxloader.stop();
          }
          else {
            this.error = "You must wait an hour before resending again!";
            this.ngxloader.stop();
          }
        }
      });
    }
    if (phone) {
      this.ngxloader.start();
      let phone_number = this.removePlusFromstring(phone.e164Number);
      this.userService.getPhoneOTP(phone_number).subscribe(resp => {
        this.OTPcounter = resp.counter;
        if (this.OTPcounter < 3) {
          this.userService.sendPhoneOTP(phone_number).subscribe(resp => {
            if (resp.message === 'Phone OTP was successfully sent!') {
              this.showOTP = true;
              this.startOTPTimer();
              this.ngxloader.stop();
              this.OTPcounter = this.OTPcounter + 1;
              var data = {
                telesign_ref_id: resp.telesign_ref_id,
                otp: resp.otp,
                phone: phone_number,
                otp_sent_counter: this.OTPcounter,
                otp_sent_date: new Date()
              }

              this.ngxloader.stop();
            } else {
              this.ngxloader.stop();
            }
          });
        }
        else {
          var dateHours;
          var DateNow = new Date();
          var dateSpan = new Date(resp.OTPSentDate);
          if (dateSpan.getHours() != 23) {
            dateHours = dateSpan.getHours() + 1
          }
          else {
            dateHours = 0;
          }
          dateSpan.setHours(dateHours);
          if (DateNow >= dateSpan) {
            this.ngxloader.start();
            this.userService.sendPhoneOTP(phone_number).subscribe(resp => {
              if (resp.message === 'Phone OTP was successfully sent!') {
                this.showOTP = true;
                this.startOTPTimer();
                this.OTPcounter = this.OTPcounter + 1;
                var data = { otp: resp.otp, phone: phone_number, otp_sent_counter: 0, otp_sent_date: new Date() }
                this.ngxloader.stop();
                this.clearInfoMessages();
              }
            });
            this.ngxloader.stop();
          }
          else {
            this.error = "You must wait an hour before resending again!";
            this.ngxloader.stop();
          }
        }
      });
    }
  }

  verifyLoginCode() {
    let obj = this;
    obj.steps = 2;
    setTimeout(() => {
      autoFillDisplayName(), 1000
    });
    this.clearInfoMessages();

    // if(this.signupForm.get('password').value){
    //   if(this.windowRef?.confirmationResult){
    //     this.ngxloader.start();
    //     this.windowRef.confirmationResult
    //     .confirm(this.signupForm.get('password').value)
    //     .then( result => { 
    //       console.log(result);

    //     })
    //     .catch( error => {
    //       this.ngxloader.stop();
    //       this.error = "Incorrect code entered"
    //       console.log(error, "Incorrect code entered?")
    //     });
    //   }else{
    //     this.error = "Please enter valid OTP, You can get OTP by pressing 'Get OTP' button"
    //   }
    // }else{
    //   this.error = "Please Enter OTP"
    // }

  }

  ////

  nextStep() {  
  
    this.calculatePaymentAmount(() => {
  
      if (this.updated_price === 0 && this.error === null) {
        this.byPassPayment();
      } else {           
        this.steps = 3;
      }
    });
  }
  

  previousStep() {
    this.clearInfoMessages();
    this.showOTP = false;
    if (this.steps > 1) {
      this.steps -= 1;
    } else {
      this.steps = 1;
      this.signupForm.reset();
      setTimeout(() => {
        this.phoneOption = phoneOrEmail(), 1000
        this.showOTP = true;
      });
    }
    //this.steps = 1;
    //this.signupForm.reset();
    //setTimeout(() => {
    //  this.phoneOption = phoneOrEmail(), 1000

    //});
  }

  clearInfoMessages() {
    this.error = null;
    this.success = null;
  }

  clearPasswordNotMatchError() {
    this.passwordNotMatchError = null;
  }

  clearEmailNotMatchError() {
    this.emailNotMatchError = null;
  }

  onNext() {
    this.signSubmit = true;
    //if (this.response != null && this.error != null) {
    //  this.steps = 2;
    //}

    if (this.signupForm.valid) {
      this.clearInfoMessages();
      if (this.signupForm.get('referral_code').value) {
        this.promoCodeActive = true;
        this.validateReferalCode();
      } else {
        if (this.planName == 'Individual') {
          this.signupForm.get('referral_code').setValue('regular');
          this.promoCodeActive = false;
          this.validateReferalCode();
        }
        else {
          this.signupForm.get('referral_code').setValue('regular business');
          this.promoCodeActive = false;
          this.validateReferalCode();
        }
      }
    } else {
      this.error = "Please fill up all the required fields with valid details"
    }
  }

  validateReferalCode() {
    // Call validate referal API here
    let data = {
      "value": this.signupForm.get('referral_code').value.replace(/\s/g, '').toUpperCase()
    }
    //console.log(data);
    this.userService.validateReferralCode(data).subscribe(resp1 => {
      if (resp1.error != 'This promo code is already used!') {
        if (resp1.Discount >= 0) {
          this.discount_amount = resp1.Discount;
          this.currentPromoCodeId = resp1._id;
          this.nextStep();
        }
        else if (resp1.RateId && this.planName == 'Individual') {
          this.currentPromoCodeId = resp1._id;
          this.rateId = resp1.RateId;
          this.userService.getRateAmount(this.rateId).subscribe(resp2 => {
            if (resp2 != '') {
              this.updated_price = resp2.Amount;
              if (!this.emailOrPhoneExists && this.error == null) {
                this.nextStep();
              } else {
                this.steps = 2
              }
            }
          }, err => { this.error = "Invalid promo code!"; });
        }
        else {
          this.error = "Invalid promo code!";
        }

  
      } else {
        this.steps = 2;
        this.error = 'This promo code is already used!';
      }
    }, err => {
      this.steps = 2;
      this.error = "Invalid promo code!";
    }
    );
  }

  byPassPayment() {
    // this.ngxloader.start();
    // this.clearInfoMessages();
    // if (this.signupForm.get('phone_number').value?.e164Number) {
    //   let phoneNumber = this.signupForm.get('phone_number').value.e164Number;
    //   this.signupForm.get('phone_number').patchValue(this.removePlusFromstring(phoneNumber));
    // }
    // let createUserData = {
    //   ...this.signupForm.value, is_active: true, fam_id: this.FAMID
    // }
    // delete createUserData['OtpCode'];
    // createUserData = this.filterEmptyFields(createUserData);


    // this.userService.createUser(createUserData).subscribe(res => {
    //   if (res.message === 'User was registered successfully!') {

    //     let updatePromo = {
    //       is_used: true
    //     }
    //     this.userService.updatePromoCode(this.currentPromoCodeId, updatePromo).subscribe(res => {
    //       if (res.message === 'Updated!') {
    //         this.ngxloader.stop();
    //         this.userService.signupFormData$.next(
    //           {
    //             first_name: '',
    //             last_name: '',
    //             display_name: '',
    //             password: '',
    //             confirm_password: '',
    //             OtpCode: '',
    //             email_id: '',
    //             phone_number: {},
    //             referral_code: '',
    //             acceptTermsAndConditions: false
    //           }
    //         );
    //       }
    //     });
    //     this.createFlexisipUser();
    //   } else {
    //     this.ngxloader.stop();
    //     this.error = res.message;
    //   }
    // });


    this.clearInfoMessages();
    this.ngxloader.start();
    if (this.signupForm.get('phone_number').value?.e164Number) {
      let phoneNumber = this.signupForm.get('phone_number').value.e164Number;
      this.signupForm.get('phone_number').patchValue(this.removePlusFromstring(phoneNumber));
    }

    if (this.display_name == null || this.display_name == '') {
      this.display_name = this.signupForm.get('first_name').value + " " + this.signupForm.get('last_name').value;
    }
    this.password = this.signupForm.get('password').value;
    let OTPinput = this.signupForm.get('OtpCode').value;
    let createUserData = {
      ...this.signupForm.value, auth_sub_id: this.subscription_id,
      ...this.signupForm.value, next_billing_amount: this.billing_amount,
      next_billing_date: this.end_date,
      start_billing_date: null,
      is_active: false,
      rate_id: this.rateId,
      promo_code_id: this.currentPromoCodeId,
      password: this.password,
      display_name: this.display_name,
      trial_usage: true,
      trial_end: this.end_date,
      otp: OTPinput,
      // fam_id: this.FAMID
      //update seats depending on chosen plan
      seats: this.totalSeats
    }
    delete createUserData['OtpCode'];

    // createUserData = this.filterEmptyFields(createUserData);
    // console.log(createUserData, "<<<<<<<<<<<<<<<<<<<<<<<<");

    this.userService.createUser(createUserData).subscribe((Response) => {
      if (Response.message === 'User was registered successfully!') {
        this.createFlexisipUser();

      } else {
        this.error = Response.message;
      }
    }, (error) => { console.log('Error is', error); })



  }
  removeSpaceBetween(value) {
    return value.toString().replace(/\s/g, "");
  }

  createUser(startdate: string) {
    this.ccSubmit = true
    this.clearInfoMessages();
    let email = this.signupForm.get('email_id').value;
    //console.log(this.creditCardForm.value);,
    if (this.creditCardForm.valid) {
      this.ngxloader.start();
      let unformattedCardNumber = this.creditCardForm.get('cardNumber').value;
      this.creditCardForm.get('cardNumber').setValue(this.removeSpaceBetween(unformattedCardNumber));
      if (this.signupForm.get('phone_number').value?.e164Number) {
        let phoneNumber = this.signupForm.get('phone_number').value.e164Number;
        this.signupForm.get('phone_number').patchValue(this.removePlusFromstring(phoneNumber));
      }
      if (startdate != '') {
        let endDate = new Date(startdate);
        endDate.setMonth(endDate.getMonth() + 1)
        this.end_date = endDate;
      }
      if (this.display_name == null || this.display_name == '') {
        this.display_name = this.signupForm.get('first_name').value + " " + this.signupForm.get('last_name').value;
      }
      this.password = this.signupForm.get('password').value;
      let OTPinput = this.signupForm.get('OtpCode').value;
      let createUserData = {
        ...this.signupForm.value, auth_sub_id: this.subscription_id,
        ...this.signupForm.value, next_billing_amount: this.billing_amount,
        next_billing_date: this.end_date,
        start_billing_date: startdate,
        is_active: false,
        rate_id: this.rateId,
        promo_code_id: this.currentPromoCodeId,
        password: this.password,
        display_name: this.display_name,
        trial_usage: true,
        trial_end: this.end_date,
        otp: OTPinput,
        // fam_id: this.FAMID
        //update seats depending on chosen plan
        seats: this.totalSeats
      }
      delete createUserData['OtpCode'];

      // createUserData = this.filterEmptyFields(createUserData);
      // console.log(createUserData, "<<<<<<<<<<<<<<<<<<<<<<<<");

      this.userService.createUser(createUserData).subscribe((Response) => {
        if (Response.message === 'User was registered successfully!') {
          this.createFlexisipUser();

        } else {
          this.error = Response.message;
        }
      }, (error) => { console.log('Error is', error); })

    } else {
      this.error = "Please fill up all the required fields with valid details"
    }

  }

  createFlexisipUser() {
    var display_name = this.signupForm.get('first_name').value + " " + this.signupForm.get('last_name').value;
    let createUserData = {
      username: "+" + this.phone_number,
      password: this.password,
      algorithm: "SHA-256",
      phone: "+" + this.phone_number,
      email: this.signupForm.get('email_id').value,
      display_name: display_name,
      activated: false

    }
    this.userService.createFlexisipUser(createUserData).subscribe((resp) => {
      if (resp != null) {
        /*this.ngxloader.stop();*/
        this.FAMID = resp.id;
        this.ConfirmationToken = resp.confirmation_key;
        this.ProvisioningToken = resp.provisioning_token;
        this.showThankyou();
        this.startOTPTimer();
        if (this.updated_price == 0) {
          this.ngxloader.stop();
          let updatePromo = {
            is_used: true
          }
          this.userService.updatePromoCode(this.currentPromoCodeId, updatePromo).subscribe(res => {
            if (res.message === 'Updated!') {
              this.userService.signupFormData$.next(
                {
                  first_name: '',
                  last_name: '',
                  display_name: '',
                  password: '',
                  confirm_password: '',
                  OtpCode: '',
                  email_id: '',
                  phone_number: {},
                  referral_code: '',
                  acceptTermsAndConditions: false
                }
              );
            }
          });
        }
      }

    }, (error) => { this.error = error })
  }


  getFormatedDate(date: Date, format: string) {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, format);
  }

  removePlusFromstring(phoneNumber) {
    return phoneNumber.split('+').join('')
  }

  filterEmptyFields(data: any): any {    // Filter any fields that aren't empty & store in a new object - To be passed on the Pipe map's caller
    let fields = {};
    Object.keys(data).forEach(key => data[key] != '' ? fields[key] = data[key] : key);

    return fields;
  }

  showThankyou() {
    var email = this.signupForm.get('email_id').value;
    var emailIsSend = false;
    var data = { fam_id: this.FAMID, confirmation_key: this.ConfirmationToken }
    if (this.emailOTP) {
      this.userService.updateSubscriptionWithEmail(email, data).subscribe((resp) => {
        var linkData = { token: this.ConfirmationToken, phone_number: this.phone_number }
        this.userService.sendPhoneLink(linkData).subscribe(async (resp) => {
          console.log(resp);
          this.ShowThankyouScreen = true;
          this.ngxloader.stop();
          while (!this.checkConfirm) {
            await this.delay(5000);
            if (!emailIsSend) {
              this.userService.isActiveLoopPhone(this.phone_number).subscribe((resp) => {
                this.checkConfirm = resp.is_active;
                if (this.checkConfirm) {
                  var emailData = { email: email }
                  emailIsSend = true;
                  this.userService.sendWelcomeEmail(emailData).subscribe(resp => {

                  });
                }
                //window.location.reload();
              });
            }
          }
        });


      }, (error) => { this.error = error })
    } else {
      if (this.phoneOTP) {
        this.userService.updateSubscriptionWithPhoneNumber(this.phone_number, data).subscribe((resp) => {
          if (resp != null) {
            var linkData = { token: this.ConfirmationToken, email: email }
            this.userService.sendConfirmationLink(linkData).subscribe(async (resp) => {
              this.ShowThankyouScreen = true;
              this.ngxloader.stop();
              while (!this.checkConfirm) {
                await this.delay(5000);
                if (!emailIsSend) {
                  this.userService.isActiveLoopEmail(email).subscribe((resp) => {
                    this.checkConfirm = resp.is_active;
                    if (this.checkConfirm) {
                      var emailData = { email: email }
                      emailIsSend = true;
                      this.userService.sendWelcomeEmail(emailData).subscribe(resp => {

                      });
                    }
                    //window.location.reload();
                  });
                }
              }
            });
          }

        }, (error) => { this.error = error })
        this.ShowThankyouScreen = true;
        this.ngxloader.stop();
      }
    }
  }

  resendVerificationLink() {
    var email = this.signupForm.get('email_id').value;
    var linkData = { token: this.ConfirmationToken, email: email }
    this.userService.sendConfirmationLink(linkData).subscribe(resp => {
      if (resp.message === 'Email was successfully sent!') {
        this.startOTPTimer();
        this.open();
      }
    });
  }

  resendVerificationMessage() {
    var linkData = { token: this.ConfirmationToken, phone_number: this.phone_number }
    this.userService.sendPhoneLink(linkData).subscribe(resp => {
      if (resp.message === 'Email was successfully sent!') {
        this.startOTPTimer();
      }
    });
  }

  resetOTPTimer() {
    this.showResendOTP = false;
    this.counter = 120
  }

  startOTPTimer() {
    this.resetOTPTimer();
    this.countDown = timer(0, this.tick).subscribe((count) => {
      if (count == 120) {
        //console.log('timer completed', count, this.counter);
        //show resent otp button
        this.showResendOTP = true;
        if (this.countDown) {
          this.countDown.unsubscribe();
          clearInterval(this.tick);
        }
      }
      --this.counter;
    });
  }
  getStarted() {
    this.ngxloader.start();
    //this.userService.generateXMLFile(this.phone_number)
    //  .subscribe((resp) => {
    //    window.location.href = 'https://www.titaniumsecure.io:3000/api/xml/' + resp.filename;
    //  });generateXMLFileFAM
    this.userService.generateXMLFileFAM(this.phone_number, this.ProvisioningToken)
      .subscribe((resp) => {
        window.location.href = 'https://www.titaniumsecure.io:3000/api/xml/' + resp.filename;
        // window.location.href = 'https://sip2.titaniumsecure.io/flexiapi/provisioning/' + this.ProvisioningToken;
      });
  }

  goTOHome() {
    this.signupForm.reset();
    this.router.navigate(['/'])
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.steps != 3) {
      if (this.signupForm.get('first_name').value || this.signupForm.get('last_name').value) {
        setTimeout(() => {
          autoFillDisplayName(), 1000
        });
      }
    }
  }

  //check(event) {
  //  if (this.signupForm.get('first_name').value) {

  //  autoFillDisplayName();
  //  }
  //  //setTimeout(() => {
  //  //  autoFillDisplayName(), 1000
  //  //});
  //}

  reload() {
    window.location.reload();
  }
  ngOnDestroy(): void {
    //pass data to user service to store signup data before destroy of his component 
    this.userService.signupFormData$.next(
      this.signupForm.value
    );
    //isRedirected$ value is true from privacy and service aggrement so set false when destroy the component
    this.userService.isRedirected$.next(false);
  }

  selectedOption() {
    this.phoneOption = phoneOrEmail()
    if (this.phoneOption == true) {
      this.clearInfoMessages();
      this.phoneCheck = "checked";
      this.emailCheck = '';
    }
    else {
      this.clearInfoMessages();
      this.emailCheck = "checked";
      this.phoneCheck = '';
    }
  }
  passwordMatchingValidatior: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get('password');
    const confirmPassword = control.get('confirm_password');

    if (password.value != '' && confirmPassword.value != '' && password.value != null && confirmPassword.value != null) {
      var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_={}|:;"'<,>.])[A-Za-z\d@$!%*?&~`#^()_={}|:;"'<,>.]{8,}$/);
      let currentPassword = password.value;
      var result = currentPassword.match(pattern);
    }
    if (password?.value === confirmPassword?.value ? null : { notmatched: true }) {
      this.passwordNotMatchError = "Password does not match!"
    } else {
      this.clearPasswordNotMatchError();
    }
    if (password.value === confirmPassword.value && password.value != null && confirmPassword.value != null) {
      if (!result) {
        // this.passwordNotMatchError = "Password must be at least 8 characters, one uppercase letter, one lowercase letter, one number, one special character."
        let passwordMessage = document.getElementById('passwordMessage') as HTMLElement;
        passwordMessage?.style.setProperty('color', '#842029')

      } else {
        let passwordMessage = document.getElementById('passwordMessage') as HTMLElement;
        passwordMessage?.style.setProperty('color', '#212529')
        this.clearPasswordNotMatchError();
      }
    }
    return password?.value === confirmPassword?.value ? null : { notmatched: true };
  };

  emailMatchingValidatior: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!this.emailOption) {

      const email = control.get('email_id');
      const confirmEmail = control.get('confirm_email');

      if (email?.value === confirmEmail?.value ? null : { notmatched: true }) {
        this.emailNotMatchError = "Email does not match!"
      } else {
        this.clearEmailNotMatchError();
      }

      return email?.value === confirmEmail?.value ? null : { notmatched: true };
    }
  };

  handeOtpChange(value: string[]): void {
    //console.log(value);
    value.forEach(input => {
      if (input == '') {
        this.signupForm.get('OtpCode').setValue(null);
      }
    });
  }

  handleFillEvent(value: string): void {
    //console.log("filled",value);
    this.signupForm.get('OtpCode').setValue(value);

    //for auto-login 
    // setTimeout(() => {
    //   this.verifyLoginCode();  
    // });

  }

  retryPayment() {
    this.onSubmit();
  }

  open() {
    const modalRef = this.modalService.open(NgbdModalContent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  OnChanges() {
    this.signupForm.get('number_of_seats').valueChanges.subscribe(selectedPlanOption => {
      var currentSeats = Number(this.signupForm.get('number_of_seats').value)

      switch (this.planName) {
        case "Individual":
          if (currentSeats == 0) {
            this.errorSeats = null;
            this.disableButton = false;
          }
          break;
        case "2-4 users":
          if (currentSeats < 2 || currentSeats > 4 || Number.isNaN(+currentSeats)) {
            this.errorSeats = "Please enter the number of seats from 2 to 4."
            this.disableButton = true;
          } else {
            this.errorSeats = null;
            this.disableButton = false;
          }
          break;
        case "5-50 users":
          if (currentSeats < 5 || currentSeats > 50 || Number.isNaN(+currentSeats)) {
            this.errorSeats = "Please enter the number of seats from 5 to 50."
            this.disableButton = true;
          } else {
            this.errorSeats = null;
            this.disableButton = false;
          }
          break;
        case "51-300 users":
          if (currentSeats < 51 || currentSeats > 300 || Number.isNaN(+currentSeats)) {
            this.errorSeats = "Please enter the number of seats from 51 to 300."
            this.disableButton = true;
          } else {
            this.errorSeats = null;
            this.disableButton = false;
          }
          break;
        case "301-unlimited users":
          if (currentSeats < 301 || Number.isNaN(+currentSeats)) {
            this.errorSeats = "Please enter the number of seats starting from 301 and above"
            this.disableButton = true;
          } else {
            this.errorSeats = null;
            this.disableButton = false;
          }
          break;
        default:
          this.errorSeats = "Invalid plan or seats"
          this.disableButton = true;
          break;
      }

    })
  }

  calculatePaymentAmount(callback: () => void) {
    this.totalSeats = Number(this.signupForm.get('number_of_seats').value);
    
    this.userService.getRateName(this.planName).subscribe(resp => {
      if (resp != null) {
        this.rateId = resp._id;
        var plan_price;
        var discount;
  
        switch (this.planName) {
          case "Individual":
            this.totalSeats = 0;
            this.updated_price = 6;
            if (this.discount_amount != 0) {
              plan_price = this.updated_price;
              discount = (plan_price * this.discount_amount) / 100;
              this.updated_price = plan_price - discount;
            }
            break;
          case "2-4 users":
            this.pricePerSeat = 5;
            this.updated_price = this.applyDiscount(this.totalSeats * this.pricePerSeat);
            break;
          case "5-50 users":
            this.pricePerSeat = 4;
            this.updated_price = this.applyDiscount(this.totalSeats * this.pricePerSeat);
            break;
          case "51-300 users":
            this.pricePerSeat = 3;
            this.updated_price = this.applyDiscount(this.totalSeats * this.pricePerSeat);
            break;
          case "301-unlimited users":
            this.pricePerSeat = 2;
            this.updated_price = this.applyDiscount(this.totalSeats * this.pricePerSeat);
            break;
          default:
            this.updated_price = 0;
            break;
        }
  
        console.log(this.updated_price, "during call");
  
        // ✅ Execute the callback AFTER updated_price is set
        if (callback) {
          callback();
        }
      }
    }, error => {
      this.error = 'Error getting plan rate!';
      if (callback) {
        callback();
      }
    });
  }
  
  // ✅ Helper function to apply discount
  applyDiscount(plan_price: number): number {
    if (this.discount_amount != 0) {
      const discount = (plan_price * this.discount_amount) / 100;
      return plan_price - discount;
    }
    return plan_price;
  }
  

}

