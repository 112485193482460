<div class="wrapper">
  <!-- <div class="top-btns">
    <button [routerLink]="[ '/login' ]" style="margin-right: 15px;" routerLinkActive="active"
      class="btn btn-lg btn-outline-primary">Sign In</button>
  </div> -->
  <!-- <div class="top-btns">
    <a style="padding-right: 10px; margin-right: 10px; width: 100px" data-aos="fade-up"
      data-aos-anchor-placement="bottom-bottom" [routerLink]="[ '/login' ]" routerLinkActive="active"
      class="btn btn-orange banner-btn">Log in</a>
  </div> -->
  <div class="show-video-bg">
    <div class="video-wrapper">
      <video class="video-banner" loop="true" autoplay muted oncanplay="this.play()"
             onloadedmetadata="this.muted = true">
        <source src="./assets/videos/banner-video.mp4" type="video/mp4" />
      </video>
    </div>
    <main-app-header></main-app-header>
    <div class="container">
      <div class="logo" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
        <div>
          <!-- <img class="image-size" src="./assets/images/logo.png" />
        <span class="trademark">™</span> -->
        </div>
      </div>
      <div class="user-block">
        <div class="dropdown">
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <div class="user-name">
              <!-- <span><a href="#">change</a></span> -->
            </div>
            <ul>
              <li class="subscription-menu">
                <a [routerLink]="[ '/dashboard' ]" routerLinkActive="active"><em></em>Subscription</a>
              </li>
              <li class="account-setting-menu">
                <a><em></em>Account Settings</a>
              </li>
              <li class="logout-menu pointer">
                <a (click)="logOut();"><em></em>Log Out</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="banner">
        <!--<a style="padding-right: 10px; margin-right: 10px; width: 100px" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" [routerLink]="[ '/login' ]"
      routerLinkActive="active" class="btn btn-orange banner-btn">Sign In</a>-->
        <!-- <a [routerLink]="[ '/signup' ]" routerLinkActive="active" class="try-link">Try Titanium free for <span
          class="thirty-days">30 Days</span></a> -->
        <!-- <p style="padding:0px; font-size: 12px;color:grey;">$8.99/month retail price after the free trial.</p>
        <a style="width:100px;" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" [routerLink]="[ '/signup' ]"
           routerLinkActive="active" class="btn btn-orange banner-btn">Sign Up</a> -->
        <br />
        <!-- <div class="social-icons">
          <ul>
              <li>
                  <a href="https://www.facebook.com/" target="_blank"><i class="fab fa-facebook-f"></i></a>
              </li>
              <li>
                  <a href="https://www.twitter.com/" target="_blank"><i class="fab fa-twitter"></i></a>
              </li>
              <li>
                  <a href="https://www.linkdin.com/" target="_blank"><i class="fab fa-linkedin-in"></i></a>
              </li>
              <li>
                  <a href="https://www.youtube.com/" target="_blank"><i class="fab fa-youtube"></i></a>
              </li>
          </ul>
      </div> -->
        <!-- <h1 data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
          Secure Communications from Mobile to
          Desktop
        </h1>
        <p data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
          For Individuals, Business & Enterprise Users
        </p>
        <p data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
          For Users that value their business and
          personal communications
        </p> -->
        <h3 data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
          <!-- Secure Communications from Mobile to
          Desktop -->
          <strong>QUANTUM RESISTANT COMMUNICATIONS</strong>
        </h3>
        <!-- left justify fully, support ..
       put messaging voice video conf part of fully with less padding-->
        <div>
          <h5 class="main-bullets justify-section" style="font-size: 20px!important;" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            Fully integrated package:
          </h5>
          <h5 class="main-bullets justify-section" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            Messaging
          </h5>
          <h5 class="main-bullets justify-section" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            Voice
          </h5>
          <h5 class="main-bullets justify-section" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            Video
          </h5>
          <h5 class="main-bullets justify-section" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            Conferencing
          </h5>
          <h5 class="main-bullets justify-section2" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            Supports Mac, Windows, iOS and Android.
          </h5>
          <h5 class="main-bullets justify-section2" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            Hosted in the U.S.A. Completely independent of big tech.
          </h5>
          <h5 class="main-bullets justify-section2" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            The only app built for privacy. No communications stored on servers.
          </h5>
          <h5 class="main-bullets justify-section2" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            Subscription plan with simple, intelligent pricing. We <i>
              do not sell or share your
              information.
            </i>
          </h5>
        </div>

        <section>
          <!--<div class="trial-getstarted-section section3">
          <div>
            <h3>Try Titanium™ free yourself for<br><span>30 Days</span></h3>
              <br>

          </div>
          <p style="padding:0px;  font-size: 12px;color:grey;">$6/month retail price after the free
            trial.</p>

          <a data-aos="fade-up" data-aos-anchor-placement="bottom-bottom"
          onClick="document.getElementById('planCards').scrollIntoView();" routerLinkActive="active"
          class="btn btn-orange banner-btn" style="padding-right: 10px; margin-right: 10px;">See all plans</a>
          <a data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" [routerLink]="[ '/signup' ]"
             routerLinkActive="active" class="btn btn-orange banner-btn pointer" style="padding-right: 10px; margin-right: 10px;">Sign Up</a>
        </div>-->
          <h1 data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            <!-- Secure Communications from Mobile to
            Desktop -->
            Features
          </h1>
          <div class="grid-tiles">
            <div class="grid-tile">
              <h3 class="section" style="margin-top:10px;">Audio/ Video Calling</h3>
              <img class="img-width" src="./assets/images/audio-video-call.png" />
              <!-- <p>Make secure end-to-end encrypted voice and video calls across the world.</p> -->
              <div class="banner-des">
                <ul>
                  <li>All calls are end-to-end encypted, quantum resistant.</li>
                  <li>Content never stored on any server.</li>
                  <li>Screen sharing capability.</li>
                  <!-- <li>Disappearing timer can be set by the sender.</li> -->
                </ul>
              </div>
            </div>
            <div class="grid-tile">
              <h3 style="margin-top:10px;">Integrated Text Messaging</h3>
              <img class="img-width" src="./assets/images/chat-image.png" />
              <!-- <p>Send and receive messages individually or connect with your people in group chats.</p> -->
              <div class="banner-des">
                <ul>
                  <li>One-to-one or group chats.</li>
                  <li>All messages are protected with post quantum encryption.</li>
                  <li>Disappearing timer can be set by the sender.</li>
                </ul>
              </div>
            </div>

            <div class="grid-tile second-grid-img">
              <h3 style="margin-top:10px;">Video Conferencing</h3>
              <img class="img-width" src="./assets/images/audio-video-call-meetings.png" />
              <!-- <p>
                Connect remotely in group video interactions, schedule important meetings and share your views to enhance collaboration and
                productivity.
              </p> -->
              <div class="banner-des">
                <ul>
                  <li>Connect remotely in group video interactions.</li>
                  <li>Schedule important meetings.</li>
                  <li>Chat during conference.</li>
                  <!-- <li>Enhance collaboration and productivity.</li> -->
                </ul>
              </div>
            </div>
            <div class="grid-tile">
              <h3 style="margin-top:10px;">Disappearing Messages</h3>
              <img class="img-width" src="./assets/images/ephemeral-timer.png" />
              <!-- <p>
                Allow users to share (images, audio, video, and docs), and recipients users can view and/or
                download & share them based on access permissions, Messages are automatically deleted on all devices after the selected burn timer has expired.
              </p> -->
              <div class="banner-des">
                <ul>
                  <li>Set disappearing messages.</li>
                  <li>Presets or custom controls.</li>
                  <li>Each sender can control each message when it disappears.</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <div class="banner-des" style="margin-top: 50px;">
          <p>
            Titanium™ presents a simple and cost-effective ultra secure solution for
            all users who value the privacy and security of their data and
            communications, including:
          </p>
          <ul>
            <li>
              Healthcare Professionals & Patients
            </li>
            <li>
              Legal Professionals & Clients
            </li>
            <li>
              Finance & Banking
            </li>
            <li>
              Real Estate Professionals & Clients
            </li>
            <li>
              Non-Profit and Political Organizations
            </li>
            <li>
              Church & Ministry Organizations
            </li>
            <li>
              Manufacturing & Engineering
            </li>
            <li>
              Commerce & Trade Representatives
            </li>
            <li>Transportation & Logistics</li>
          </ul>
          <p>It's Your Data, on Titanium™ You Own it!</p>
        </div>

        <h2 id="planCards" class="plan-tile-label" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
          <i>
            Prices are per user per month. Add or remove subscriptions anytime.
          </i>
        </h2>
        <section style="padding:0px !important;">
          <div class="grid-plan-two-tiles section3">
            <div class="grid-plan-two-tile">
              <h3 class="general-font-size" style="margin-top:10px;"><span>Individual</span></h3>
              <div>
              </div>
              <ul class="checkmark-list">
                <li>$6</li>
              </ul>
              <a style=" bottom: 0;" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom"
                 (click)="autopopulatePlanIndividual()" [routerLink]="[ '/signup' ]" routerLinkActive="active"
                 class="btn btn-orange banner-btn resp-btn">Sign up now!</a>
            </div>
            <div class="grid-plan-two-tile">
              <h3 class="general-font-size" style="margin-top:10px;"><span>2-4 users</span></h3>
              <div>
              </div>
              <ul class="checkmark-list">
                <li> $5 </li>
              </ul>
              <a style="bottom: 0;" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom"
                 (click)="autopopulatePlanFamily()" [routerLink]="[ '/signup' ]" routerLinkActive="active"
                 class="btn btn-orange banner-btn">Sign up now!</a>
            </div>
            <div class="grid-plan-two-tile">
              <h3 class="general-font-size" style="margin-top:10px;"><span>5-50 users</span></h3>
              <div>
              </div>
              <ul class="checkmark-list">
                <li>$4</li>
              </ul>
              <a style="bottom: 0;" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom"
                 (click)="autopopulatePlanSmallBusiness()" [routerLink]="[ '/signup' ]" routerLinkActive="active"
                 class="btn btn-orange banner-btn">Sign up now!</a>
            </div>
            <div class="grid-plan-two-tile">
              <h3 class="general-font-size" style="margin-top:10px;"><span>51-300 users</span></h3>
              <div>
              </div>
              <ul class="checkmark-list">
                <li>$3</li>
              </ul>
              <a style="bottom: 0;" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom"
                 (click)="autopopulatePlanMidBusiness()" [routerLink]="[ '/signup' ]" routerLinkActive="active"
                 class="btn btn-orange banner-btn">Sign up now!</a>
            </div>
            <div class="grid-plan-two-tile">
              <h3 class="general-font-size" style="margin-top:10px;"><span>301-unlimited users</span></h3>
              <div>
              </div>
              <ul class="checkmark-list">
                <li>$2</li>
              </ul>
              <a style="bottom: 0;" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom"
                 (click)="autopopulatePlanEnterprise()" [routerLink]="[ '/signup' ]" routerLinkActive="active"
                 class="btn btn-orange banner-btn">Sign up now!</a>
            </div>
          </div>

        </section>


      </div>
      <section class="use-it-now">
        <div class="container links-align">
          <div class="col-sm-12 col-md-4" data-aos="fade-left">
            <h3>Downloads for existing users:</h3>
            <br>
          </div>
          <div data-aos="fade-left">
            <div class="btn-margin">
              <div>
                <a href="https://apps.apple.com/us/app/titanium-secure-messaging/id6455696374" target="_blank"
                   class="btn btn-border btn-size">
                  <i class="fab fa-apple"></i> iOS
                </a>
              </div>
              <!--<div>
              <a href="https://play.google.com/store/apps/details?id=io.titanium" target="_black"
                class="btn btn-border btn-size">
                <i class="fab fa-google-play"></i> Android (Google Play)
              </a>
            </div>-->
              <div>
                <a href="https://www.titaniumsecure.io:3000/download/getlatestapk" target="_black"
                   class="btn btn-border btn-size">
                  <i class="fab fa-android"></i> Android (APK)
                </a>
              </div>
              <div>
                <a href="https://www.titaniumsecure.io:3000/download/getlatestmac" target="_black"
                   class="btn btn-border btn-size">
                  <i class="fab fa-app-store-ios"></i> MacOS
                </a>
              </div>
              <div>
                <a href="https://www.titaniumsecure.io:3000/download/getlatestwin" target="_black"
                   class="btn btn-border btn-size">
                  <i class="fab fa-windows"></i> Windows
                </a>
              </div>
            </div>
          </div>
        </div>
        <br>
        <br>
      </section>
      <app-contact-form class="section4"></app-contact-form>
      <app-footer></app-footer>
    </div>
  </div>
</div>
