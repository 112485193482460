<section class="map-form-section">
  <div class="map-form section8">
    <h3 data-aos="fade-up" data-aos-anchor-placement="bottom-bottom"></h3>
    <p data-aos="fade-up" data-aos-anchor-placement="bottom-bottom"></p>
    <h4 class="try-text">Feedback form</h4>
    <div class="form-group">
      <form [formGroup]="contactForm" id="contact-form">
        <ul>
          <li>
            <label>*Full Name </label>
            <input [ngClass]="( signSubmit && f['name']?.errors?.required ? 'form-error' : '')"
                   type="text" formControlName="name" name="name" placeholder="Full Name" />

          </li>
          <li>
            <label>*Email Address</label>
            <input [ngClass]="( signSubmit && f['email_id']?.errors?.required || signSubmit && f['email_id']?.errors?.email ? 'form-error' : '')"
                   type="email" formControlName="email_id" name="email_id" placeholder="Email Address" email />
          </li>
          <li>
            <label>Please select the feedback type below: </label>
            <div class="container">
              <div id="phoneEmail">
                <label for="Suggestion" class="material-icons">
                  <input type="radio" id="phoneCheck" value="Phone" (click)="selectedOption()" name="phone_or_email" checked={{phoneCheck}}>
                  <span class="material-icons-outlined" style="color: #B3CEFB">
                    lightbulb
                  </span>
                </label>
                <label for="Bug report" class="material-icons">
                  <input type="radio" id="emailCheck" value="Email" (click)="selectedOption()" name="phone_or_email" checked={{emailCheck}}>
                  <span class="material-icons-outlined" style="color: #B3CEFB">
                    bug_report
                  </span>
                </label>
              </div>
            </div>
            <div id="phoneIsChecked" style="display:none">
              <textarea id="textarea" [ngClass]="( signSubmit && f['message']?.errors?.required ? 'form-error' : '')"
                        name="message" formControlName="message" name="message" placeholder="Suggestion"></textarea>
            </div>
            <div id="emailIsChecked" style="display:none;">
              <textarea id="textarea" [ngClass]="( signSubmit && f['message']?.errors?.required ? 'form-error' : '')"
                        name="message" formControlName="message" name="message" placeholder="Bug report"></textarea>
            </div>
          </li>

          <li *ngIf="error" class="show__alert__message">
            <div class="alert alert-danger">
              {{error}}
            </div>
          </li>
          <li *ngIf="success" class="show__alert__message">
            <div class="alert alert-success">
              {{success}}
            </div>
          </li>
          <li>
            <input type="button" [disabled]="!contactForm.valid" class="btn btn-orange submitBtn" (click)="onSubmit()" name="submit" value="Submit" />
          </li>
        </ul>
      </form>
    </div>
  </div>
</section>
