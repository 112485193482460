import { NgModule } from '@angular/core';
import { TK_CONFIG, Config, AcceptJSService } from '@openutility/acceptjs-angular-wrapper';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module'
//components
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule, NgxUiLoaderConfig, NgxUiLoaderService } from 'ngx-ui-loader';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';

import { CommonHttpClient } from './shared/services/common/common-http.service';
import { UserService } from './shared/services/user.service';
import { TelesignService } from './shared/services/telesign.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonTelesignHttpClient } from './shared/services/common/common-telesign-http.service';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ServerErrorComponent } from './server-error/server-error.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { SubscriptionHistoryComponent } from './subscription-history/subscription-history.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SubscriptionCancelComponent } from './subscription-cancel/subscription-cancel.component';
import { ServiceAggrementComponent } from './service-aggrement/service-aggrement.component';
import { CommonMariaHttpClient } from './shared/services/common/common-maria-http.service';
import { SubscriptionRenewComponent } from './subscription-renew/subscription-renew.component';
import { SignupEmbeddedComponent } from './signup-embedded/signup-embedded.component';
import { ValidationLinkComponent } from './validation-link/validation-link.component';
import { PrivacyPolicyEmbeddedComponent } from './privacy-policy-embedded/privacy-policy-embedded.component';
import { ServiceAggrementEmbeddedComponent } from './service-aggrement-embedded/service-aggrement-embedded.component';
import { DatePipe } from '@angular/common';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangeUsernameComponent } from './change-username/change-username.component';
import { ChangePhonenumberComponent } from './change-phonenumber/change-phonenumber.component';
import { DashboardEmbeddedComponent } from './dashboard-embedded/dashboard-embedded.component';
import { ChangeEmailComponent } from './change-email/change-email.component';
import { DashboardEmbeddedInitialComponent } from './dashboard-embedded-initial/dashboard-embedded-initial.component';
import { ChangePaymentComponent } from './change-payment/change-payment.component';
import { ChangePasswordLinkComponent } from './change-password-link/change-password-link.component';
import { ChangeEmailLinkComponent } from './change-email-link/change-email-link.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordEmbeddedComponent } from './forgot-password-embedded/forgot-password-embedded.component';
import { ChangePasswordEmbeddedComponent } from './change-password-embedded/change-password-embedded.component';
import { ChangePhonenumberEmbeddedComponent } from './change-phonenumber-embedded/change-phonenumber-embedded.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { CheatSheetComponent } from './cheat-sheet/cheat-sheet.component';
import { PlansComponent } from './plans/plans.component';
import { HeaderComponent } from './header/header.component';
import { AboutComponent } from './about/about.component'
import { ValidationOtpComponent } from './validation-otp/validation-otp.component';

declare function showCookies(): any;

const cookieConfigs: NgcCookieConsentConfig = {

  cookie: {
    domain: 'www.titaniumsecure.io',// it is recommended to set your domain, for cookies to work properly
    secure: true,
  },
  position: "bottom-left",
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    },
  },
  theme: 'classic',
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messagelink}}{{compliance}}{{buttons}}'
  },
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank" rel="noopener">{{privacyPolicyLink}}</a> and our 
      <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank" rel="noopener">{{tosLink}}</a>.
    </span>
     <span id="showCookies" class="cc-message" style="display:none">{{showCookieMessage}}
    </span>
    `,
    buttons: `<span id="showButton" class="cc">{{button}} 
   <a style="background-color:black;border-color:black; color:white; margin-top: 10px; margin-bottom: -5px" role="button" tabindex="0" class="cc-btn" onclick="showCookies()">Show Cookies</a>
    </span>
    `,
  },
  content: {
    message: 'We use cookies to analyze our website traffic and to support technical features that enhance your user experience. By using our site, we acknowledge that you have read and understand our ',
    showCookieMessage: 'Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data.',
    privacyPolicyLink: 'Privacy Policy',
    privacyPolicyHref: 'https://www.titaniumsecure.io/privacy-policy',

    tosLink: 'Terms of Service',
    tosHref: 'https://www.titaniumsecure.io/service-aggrement',
    deny: "Decline Cookies",
  }
};

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  'bgsOpacity': 0.5,
  'bgsPosition': 'bottom-right',
  'bgsSize': 60,
  'bgsType': 'three-strings',
  'blur': 11,
  'fgsPosition': 'center-center',
  'fgsSize': 280,
  'fgsColor': 'red',
  'fgsType': 'three-strings',
  'gap': -240,
  'logoPosition': 'center-center',
  'logoSize': 200,
  'logoUrl': 'assets/images/logo.png',
  'masterLoaderId': 'master',
  'overlayBorderRadius': '0',
  'overlayColor': 'rgba(40, 40, 40, 0.8)',
  'pbDirection': 'ltr',
  'pbThickness': 3,
  'hasProgressBar': true,
  'text': '',
  'textPosition': 'center-center'
};

const ngxToastrConfig = {

  maxOpened: 5,
  autoDismiss: true,
  positionClass: 'toast-bottom-right',
  preventDuplicates: true,

}
const AcceptJSConfig: Config = {
  acceptjsUrl: 'https://js.authorize.net/v1/Accept.js'
  , apiLoginID: '54B5GQ2cyB'
  , clientKey: '6Uq37jA2RxBVE97e37xxfx89RAAj54aTL882vkXawPLZj4XdYZt6kMAxU8CQP2aL'
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    SignupComponent,
    DashboardComponent,
    ServerErrorComponent,
    SubscriptionHistoryComponent,
    PrivacyPolicyComponent,
    SubscriptionCancelComponent,
    ServiceAggrementComponent,
    SubscriptionRenewComponent,
    SignupEmbeddedComponent,
    ValidationLinkComponent,
    PrivacyPolicyEmbeddedComponent,
    ServiceAggrementEmbeddedComponent,
    ChangePasswordComponent,
    ChangeUsernameComponent,
    ChangePhonenumberComponent,
    DashboardEmbeddedComponent,
    ChangeEmailComponent,
    DashboardEmbeddedInitialComponent,
    ChangePaymentComponent,
    ChangePasswordLinkComponent,
    ChangeEmailLinkComponent,
    ForgotPasswordComponent,
    ValidationOtpComponent,
    ForgotPasswordEmbeddedComponent,
    ChangePasswordEmbeddedComponent,
    ChangePhonenumberEmbeddedComponent,
    FeedbackComponent,
    CheatSheetComponent,
    PlansComponent,
    HeaderComponent,
    AboutComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    CreditCardDirectivesModule,
    ToastrModule.forRoot(ngxToastrConfig),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    AngularFireModule,
    AngularFireAuthModule,
    NgxIntlTelInputModule,
    NgxOtpInputModule,
    NgcCookieConsentModule.forRoot(cookieConfigs),

  ],
  providers: [CommonHttpClient, UserService,  CommonMariaHttpClient,CommonTelesignHttpClient, TelesignService, DatePipe, {
    provide: TK_CONFIG,
    useValue: AcceptJSConfig
  },
    AcceptJSService],
  bootstrap: [AppComponent]
})

export class AppModule { }

