<div class="wrapper">
  <div class="payment-header">
      <div class="payment-logo">
          <img src="./assets/images/logo.png" />
          <h3>Dashboard</h3>
      </div>
  </div>
  <!-- <app-header [headerTitle]="'Dashboard'" style="color:white !important"></app-header> -->

  <div class="payment-history-page">
    <div class="payment-history box-shadow" *ngIf="apiCalled">
      <div class="subscription-amount-block">
        <div class="amount" *ngIf="!isFreeUser">
          <!-- <p>Purchased on {{subscriptionDetails?.start_billing_date | date : 'longDate'}}</p> -->
          <!--<span>$</span> <strong>{{amount}}</strong><br />
  <span class="amount-label">Per Month.</span>-->
          <!--<div *ngIf="isActive"><p class="dashText">Subscription status: <font color="#058c03">Active</font></p></div>-->
          <div *ngIf="!isActive"><p class="dashText">Subscription status: <font color="#9e0202">Inactive</font> </p></div>
          <div *ngIf="!isActive" class="buttonPadding"><a id="dashButton" [routerLink]="[ '/subscription-renew' ]" routerLinkActive="active" class="btn btn-blue submitBtn">Renew Subscription</a></div>
          <div *ngIf="isActive" class="buttonPadding"><a id="dashButton" [routerLink]="[ '/change-password-embedded' ]" routerLinkActive="active" class="btn btn-blue submitBtn">Change Password</a></div>
          <div *ngIf="isActive" class="buttonPadding"><a id="dashButton" [routerLink]="[ '/change-username' ]" routerLinkActive="active" class="btn btn-blue submitBtn">Change Display Name</a></div>
          <div *ngIf="isActive" class="buttonPadding"><a id="dashButton" [routerLink]="[ '/change-phonenumber-embedded' ]" routerLinkActive="active" class="btn btn-blue submitBtn">Change Phone Number</a></div>
          <div *ngIf="isActive" class="buttonPadding"><a id="dashButton" [routerLink]="[ '/change-payment' ]" routerLinkActive="active" class="btn btn-blue submitBtn">Change/Update Payment</a></div>
          <div *ngIf="isActive" class="buttonPadding"><a id="dashButton" [routerLink]="[ '/change-email' ]" routerLinkActive="active" class="btn btn-blue submitBtn">Change Email</a></div>
          <div *ngIf="isActive" class="buttonPadding"><a id="dashButton" [routerLink]="[ '/subscription-cancel' ]" routerLinkActive="active" class="btn btn-blue submitBtn">Delete account</a></div>
          <div *ngIf="isActive" class="buttonPadding"><a id="dashButton" [routerLink]="[ '/manage-plans' ]" routerLinkActive="active" class="btn btn-blue submitBtn">Manage Plan</a></div>
          <div *ngIf="showExpiredCardMessage"><p class="dashText"><font color="#9e0202">Your credit card has expired. Please update using the change payment option. </font> </p></div>
          <div *ngIf="showTerminatedSubMessage"><p class="dashText"><font color="#9e0202">The suspended subscription has been terminated. Please resubscribe using the renew subscription option. </font> </p></div>
        </div>
        <div class="amount" *ngIf="isFreeUser">
          <p></p>
          <span></span> <strong> Free Plan</strong><br />
          <span class="amount-label"></span>
          <!--<div *ngIf="isActive"><p class="dashText">Subscription status: <font color="#058c03">Active</font></p></div>-->
          <!-- <div *ngIf="!isActive"><p class="dashText">Subscription status: <font color="#9e0202">Inactive</font></p></div> -->
          <div *ngIf="isActive" class="buttonPadding"><a id="dashButton" [routerLink]="[ '/change-password-embedded' ]" routerLinkActive="active" class="btn btn-blue submitBtn">Change Password</a></div>
          <div *ngIf="isActive" class="buttonPadding"><a id="dashButton" [routerLink]="[ '/change-username' ]" routerLinkActive="active" class="btn btn-blue submitBtn">Change Display Name</a></div>
          <div *ngIf="isActive" class="buttonPadding"><a id="dashButton" [routerLink]="[ '/change-phonenumber-embedded' ]" routerLinkActive="active" class="btn btn-blue submitBtn">Change Phone Number</a></div>
          <!-- <div *ngIf="isActive" class="buttonPadding"><a id="dashButton" [routerLink]="[ '/change-payment' ]" routerLinkActive="active" class="btn btn-blue submitBtn">Change/Update Payment</a></div> -->
          <div *ngIf="isActive" class="buttonPadding"><a id="dashButton" [routerLink]="[ '/change-email' ]" routerLinkActive="active" class="btn btn-blue submitBtn">Change Email</a></div>
          <div *ngIf="isActive" class="buttonPadding"><a id="dashButton" [routerLink]="[ '/subscription-cancel' ]" routerLinkActive="active" class="btn btn-blue submitBtn">Delete account</a></div>
          <div *ngIf="isActive" class="buttonPadding"><a id="dashButton" [routerLink]="[ '/manage-plans' ]" routerLinkActive="active" class="btn btn-blue submitBtn">Manage Plan</a></div>
        </div>
      </div>
      <!--<div class="view-payment-history-text" *ngIf="isFreeUser">-->
        <!-- <p class="dashText">Next Charge on {{subscriptionDetails?.next_billing_date | date : 'longDate'}}</p> -->
        <!-- <div *ngIf="isActive"><a [routerLink]="[ '/subscription-history' ]" routerLinkActive="active">View Payment History</a></div> -->
        
      <!--</div>
      <div class="view-payment-history-text" *ngIf="!isFreeUser">-->
        <!-- <p class="dashText">Next Charge on {{subscriptionDetails?.next_billing_date | date : 'longDate'}}</p> -->
        <!-- <div *ngIf="isActive"><a [routerLink]="[ '/subscription-history' ]" routerLinkActive="active">View Payment History</a></div> -->
        
      <!--</div>-->

      <!-- <div class="view-payment-history-text" *ngIf="showNoData">
        <p><a [routerLink]="[ '/subscription-history' ]" routerLinkActive="active">View Payment History</a></p>
        <div *ngIf="isActive"><p class="dashText">Subscription status: Active</p></div>
        <div *ngIf="!isActive"><p class="dashText">Subscription status: Inactive </p></div>        
      </div> -->
    </div>
  </div>
</div>
