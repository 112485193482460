import { Component, OnInit, TemplateRef } from '@angular/core';
import { UserService } from '../shared/services/user.service';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, timer } from 'rxjs';
import { UserAuthService } from '../shared/services/auth/user-auth.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { CommonHttpClient } from '../shared/services/common/common-http.service';
declare function viewPassword(): any;
declare function viewCPassword(): any;
declare function viewCCPassword(): any;

@Component({
  selector: 'ngbd-modal-content',
  template: `
		<div class="modal-header">
			<h4 style="color:black" class="modal-title">Profile update</h4>
			<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
		</div>
		<div class="modal-body">
			<h5 style="color:black">Your password has been successfully updated.</h5>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-blue submitBtn" (click)="activeModal.close('Close click')">OK</button>
		</div>
	`,
})
export class NgbdModalContent {
  constructor(public activeModal: NgbActiveModal) { }
}


@Component({
  selector: 'app-change-password-link',
  templateUrl: './change-password-link.component.html',
  styleUrls: ['./change-password-link.component.css'],
  animations: [
    trigger(
      'slideView',
      [
        state('true', style({ transform: 'translateX(100%)', opacity: 0 })),
        state('false', style({ transform: 'translateX(0)', opacity: 1 })),
        transition('0 => 1', animate('500ms', style({ transform: 'translateX(0)', 'opacity': 1 }))),
        transition('1 => 1', animate('500ms', style({ transform: 'translateX(100%)', 'opacity': 0 }))),
      ]),

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)', 'opacity': 1 }))
      ]),

      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('0ms ease-in', style({ transform: 'translateX(100%)', 'opacity': 0 }))
      ])
    ])
  ]
})
export class ChangePasswordLinkComponent implements OnInit {
  //get from html forms
  newPassword: string;
  error: boolean;
  PasswordChanged: boolean = false;
  changePasswordForm: FormGroup;
  submit: boolean = false;
  passwordNotMatchError: string;
  closeResult = '';
  phoneCheck = false;
  emailCheck = false;

  countDown: Subscription;
  counter = 120;
  tick = 1000;
  success: any;
  changePhoneByPhoneCheck: boolean;

  ConfirmationToken: string;
  response: string;

  windowRef: any;
  apiCalled: boolean;
  param: any;
  //fam_id: string;
  email: string;
  phone: string;
  userId: any;
  constructor(private userService: UserService, private _location: Location, private router: Router, private ngxloader: NgxUiLoaderService,
    private modalService: NgbModal, private userAuth: UserAuthService, route: ActivatedRoute, private ccService: NgcCookieConsentService, private commonHttpInit: CommonHttpClient) {
    this.initChangePasswordForm();
    this.param = route.snapshot.params.param;
    this.initCommonHTTP();
  }

  ngOnInit(): void {
    this.ccService.destroy();
  }
  async initCommonHTTP()
  {
    this.ngxloader.start();
    var key = await this.commonHttpInit.getApiKey()
      if(key){
        this.getSubscriptionDetails();
      }   
  }
  changePassword() {
    this.ngxloader.start();
    this.clearInfoMessages();
    this.newPassword = this.changePasswordForm.get("new_password").value;

    if (this.param.includes("@")) {
      this.email = this.param;
    }
    else {
      this.phone =  this.param
    }

    if (this.param != null) {
      if (this.param.includes("@")) {
        let OTPinput = this.changePasswordForm.get('OtpCode').value;
        this.userService.changePasswordByEmailFromLink(this.newPassword, this.email, OTPinput).subscribe(resp => {
          if (resp.message === 'Password changed!') {
            this.userService.changePasswordFromlink(this.newPassword, this.userId, '+' + this.phone).subscribe(famResp => {
              if (famResp.message === 'Password changed successfully!') {
                this.ngxloader.stop();
                this.PasswordChanged = true;
                //success pop up
                this.open();         
              }
            });

          }
          else {
            if (resp.message === 'Old password is incorrect.') {
              this.passwordNotMatchError = resp.message;
              this.ngxloader.stop();
            }
            if (resp.message === "Invalid Password! Password is the same as the current one!") {
              this.passwordNotMatchError = resp.message;
              this.ngxloader.stop();
            }
          }
        }, error => { this.passwordNotMatchError = 'Error changing password!' })
      }

      if (!this.param.includes("@")) {
        let OTPinput = this.changePasswordForm.get('OtpCode').value;
        this.userService.changePasswordByPhoneFromLink(this.newPassword, this.phone, OTPinput).subscribe(resp => { //change
          if (resp.message === 'Password changed!') {
            this.userService.changePasswordFromlink(this.newPassword, this.userId, '+'+this.phone).subscribe(famResp => {
              if (famResp.message === 'Password changed successfully!') {
                this.ngxloader.stop();
                this.PasswordChanged = true;
                //success pop up
                this.open();
              }
            });
          }
          else {
            if (resp.message === 'Old password is incorrect.') {
              this.passwordNotMatchError = resp.message;
              this.ngxloader.stop();
            }
            if (resp.message === "Invalid Password! Password is the same as the current one!") {
              this.passwordNotMatchError = resp.message;
              this.ngxloader.stop();
            }
          }
        }, error => { this.passwordNotMatchError = "Invalid Password! Password is the same as the current one!" })
      }
    }
    else {
      this.passwordNotMatchError = 'Error changing password!'
    }
  }
  get f() {
    return this.changePasswordForm.controls;
  }

  initChangePasswordForm() {
    this.changePasswordForm = new FormGroup({
      new_password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_={}|:;"'<,>.])[A-Za-z\d@$!%*?&~`#^()_={}|:;"'<,>.]{8,}$/)]),
      confirm_new_password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_={}|:;"'<,>.])[A-Za-z\d@$!%*?&~`#^()_={}|:;"'<,>.]{8,}$/)])
    }, { validators: this.passwordMatchingValidatior });
  }

  backClicked() {
    if (this.router.navigated) {
      this._location.back();
    }

  }
  clearPasswordNotMatchError() {
    this.passwordNotMatchError = null;
  }

  passwordMatchingValidatior: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const newPassword = control.get('new_password');
    const confirm_new_password = control.get('confirm_new_password');

    if (confirm_new_password.value != '' && newPassword.value != '' && confirm_new_password.value != null && newPassword.value != null) {
      var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_={}|:;"'<,>.])[A-Za-z\d@$!%*?&~`#^()_={}|:;"'<,>.]{8,}$/);
      let newCurrentPassword = newPassword.value;
      var result = newCurrentPassword.match(pattern);
    }

    if (newPassword.value != confirm_new_password.value && newPassword.value != null && confirm_new_password.value != null && newPassword.value != '' && confirm_new_password.value != '' &&
      confirm_new_password.value != newPassword) {
      this.passwordNotMatchError = "New Password does not match the Confirm New Password!"
      this.changePasswordForm.invalid;
    }
    else {
      this.clearPasswordNotMatchError();
    }
    if (confirm_new_password.value === newPassword.value && confirm_new_password.value != null && newPassword.value != null && confirm_new_password.value != '' && newPassword.value != '') {
      if (!result) {
        this.passwordNotMatchError = "Password must be at least eight characters, with one uppercase letter, one lowercase letter, one number and one special character."
      } else {
        this.clearPasswordNotMatchError();
      }
    }

    return newPassword?.value === confirm_new_password?.value ? null : { notmatched: true };
  };

  open() {
    const modalRef = this.modalService.open(NgbdModalContent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.router.navigate(['/login'])
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.router.navigate(['/login'])
      },
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  clearInfoMessages() {
    this.error = null;
    this.passwordNotMatchError = null;
    this.success = null;
  }

  getSubscriptionDetails() {
    if (this.param.includes("@")) {
      this.email = this.param;
    }
    else {
      this.phone = this.param
    }
    this.ngxloader.start();
    if (this.param.includes("@")) {
      this.userService.getIdMariaAPIByEmail(this.email).subscribe(resp => {
        if (resp != null) {
          this.userId = resp[0].id
          this.userService.getSubscriptionDetailsWithEmailFromLink(this.email).subscribe(resp => {
            if (resp.message == 'success') {
              this.phone = resp.phone_number;
            }
            this.apiCalled = true;
            this.ngxloader.stop();
          }, error => {
            this.apiCalled = true;
            this.ngxloader.stop();
          })
        }
        this.apiCalled = true;
        this.ngxloader.stop();
      }, error => {
        this.apiCalled = true;
        this.ngxloader.stop();
      })
    } else {
      this.userService.getIdMariaAPIByPhone(this.phone).subscribe(resp => {
        if (resp != null) {
          this.userId = resp[0].id
        }
        this.apiCalled = true;
        this.ngxloader.stop();
      }, error => {
        this.apiCalled = true;
        this.ngxloader.stop();
      })
    }

  }

}
