<div class="thankyou-bg privacy-policy-bg">
  <div class="logo">
    <div>
      <a [routerLink]="['/']"><img src="./assets/images/logo.png" /></a>
      <span style="margin-top:-20px" class="trademark2">™</span>
    </div>
  </div>
  <!--<div class="backlink pointer"><a (click)="backClicked()"><i class="fas fa-chevron-left"></i> Back</a></div>-->
  <div class="thankyou-box box-shadow privacy-policy-box">

    <div>
      <h1 style="color: #000; margin-top: 1.5rem; ">Installation Guide</h1>
      <h4> Table of Contents</h4>
      <div style="text-align:left">
        <ol>
          <li>
            <p>
              <a routerLinkActive="active" onClick="document.getElementById('verifySection').scrollIntoView();" class="try-link">
                1.Verifying the account and setting the desired password
              </a>
            </p>
          </li>
          <li>
            <p>
              <a routerLinkActive="active" onClick="document.getElementById('downloadSection').scrollIntoView();" class="try-link">
                2.Downloading and installing
              </a>
            </p>
            <ul>
              <li>
                <p>
                  <a routerLinkActive="active" onClick="document.getElementById('downloadSectionMobile').scrollIntoView();" class="try-link">
                    • Mobile installing
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a routerLinkActive="active" onClick="document.getElementById('downloadSectionDesktop').scrollIntoView();" class="try-link">
                    • Desktop installing
                  </a>
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <a routerLinkActive="active" onClick="document.getElementById('loginSection').scrollIntoView();" class="try-link">
                3.Logging in
              </a>
            </p>
            <ul>
              <li>
                <p>
                  <a routerLinkActive="active" onClick="document.getElementById('loginSectionMobile').scrollIntoView();" class="try-link">
                    • Mobile log in
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a routerLinkActive="active" onClick="document.getElementById('loginSectionDesktop').scrollIntoView();" class="try-link">
                    • Desktop log in
                  </a>
                </p>
              </li>
            </ul>
          </li>
        </ol>
      </div>
      <h4 id="verifySection">1.Verifying the account and setting the desired password</h4>
      <p id="align-left">
        - To start using Titanium, the dependent user must verify their account and set their desired password using the link provided in the welcome email.
      </p>
      <p id="align-left">
        - After opening the link, enter the phone number associated with the account, then enter the OTP to verify the phone number.
      </p>
      <p id="align-left">
        - On the next step, enter your desired password and press confirm.
      </p>
      <p id="align-left">
        - The account is all set, the next page will display the download buttons for every platform we currently support, click on the preferred platform to download the installation file.
      </p>
      <div class="cheat-sheet-image"><a><img src="./assets/images/welcome.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/enter-phone.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/enter-otp.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/enter-password.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/thank-you-screen.png" /></a></div>
      <h4 id="downloadSection">2.Downloading and installing</h4>
      <p id="align-left">
        - The installation file for the preferred platform can be downloaded either from the thank-you page, after finalizing the setup of your account, or by visiting <a href="https://www.titaniumsecure.io/">Titaniumsecure.io</a> and scrolling down to our download section.
      </p>
      <p id="align-left">
        - On mobile locate the downloaded installation file, open it and follow the installation steps.
      </p>
      <p id="align-left">
        - On desktop your browser will indicate that the installation file is downloaded, open the file from your browser or locate the download directory and open the file from there, then follow the installation steps.
      </p>
      <div class="cheat-sheet-image"><a><img src="./assets/images/download-section.png" /></a></div>
      <h4 id="downloadSectionMobile">Mobile installing</h4>
      <div class="cheat-sheet-image"><a><img src="./assets/images/apk-file.jpg" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/install-apk.jpg" /></a></div>
      <h4 id="downloadSectionDesktop">Desktop installing</h4>
      <div class="cheat-sheet-image"><a><img src="./assets/images/desktop-file.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/install-1.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/install-2.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/install-3.png" /></a></div>
      <h4 id="loginSection">3.Logging in</h4>
      <p id="align-left">
      <p id="align-left">
        - To log in, enter your phone number starting with the + sign followed by the country code, then enter your account password.
      </p>
      <h4 id="loginSectionMobile">Mobile log in</h4>
      <div class="cheat-sheet-image"><a><img style="max-width:350px !important" src="./assets/images/mobile-login.png" /></a></div>
      <div class="cheat-sheet-image"><a><img style="max-width:350px !important" src="./assets/images/mobile-login2.png" /></a></div>
      <h4 id="loginSectionDesktop">Desktop log in</h4>
      <div class="cheat-sheet-image"><a><img src="./assets/images/desktop-login.png" /></a></div>
      <div class="cheat-sheet-image"><a><img src="./assets/images/desktop-login2.png" /></a></div>
    </div>
  </div>
</div>
