<div class="wrapper">
  <div class="payment-header">
    <div class="payment-logo">
      <img src="./assets/images/logo.png" />
      <span style="padding-top: 0px !important; font-size: 0.5rem !important; margin-left: 45px !important; margin-top:5px!important" class="trademark">™</span>
      <h3>Change Displayname</h3>
    </div>
  </div>
  <section class="map-form-section login-form">

    <!-- <div class="social-icons">
        <ul>
            <li>
                <a href="https://www.facebook.com/" target="_blank"><i class="fab fa-facebook-f"></i></a>
            </li>
            <li>
                <a href="https://www.twitter.com/" target="_blank"><i class="fab fa-twitter"></i></a>
            </li>
            <li>
                <a href="https://www.linkdin.com/" target="_blank"><i class="fab fa-linkedin-in"></i></a>
            </li>
            <li>
                <a href="https://www.youtube.com/" target="_blank"><i class="fab fa-youtube"></i></a>
            </li>
        </ul>
    </div> -->
    <div class="backlink pointer"><a (click)="backClicked()"><i class="fas fa-chevron-left"></i> Dashboard</a></div>
    <div class="map-form">
      <div class="login-form-group" [@slideInOut]>
        <h3 class="addedMargin">Amount</h3>
        <h3 class="topText">Please enter your current password and the new displayname.</h3>
        <div class="form-group">
          <div id="emailOrPhoneCheck">
            <form autocomplete="on" [formGroup]="changeDisplayNameForm" id="signup-form">
              <ul>
                <li>
                  <!-- <p style="text-align: center;">Old Password</p> -->
                  <input id="pass"
                         [ngClass]="( submit && f['old_password']?.errors?.required ? 'form-error' : '')"
                         type="password" formControlName="old_password" name="old_password"
                         placeholder="Current Password" />
                  <span id="shpass" class="fa fa-eye-slash field-icon" aria-hidden="true"
                        onClick="viewPassword()"></span>

                </li>
                <li>
                  <input [ngClass]="( signSubmit && f['display_name']?.errors?.required ? 'form-error' : '')"
                         type="text" formControlName="display_name" name="display_name" placeholder="Display Name" />
                </li>
                <li>
                  <input type="submit" id="submit" style="margin-top: 10px;margin-bottom: 10px;"
                         [disabled]="!changeDisplayNameForm.valid" class="btn btn-orange submitBtn"
                         (click)="changeDisplayName()" name="submit" value="Change Displayname">
                </li>
                <li *ngIf="error" class="show__alert__message" style="padding-bottom: 5px;">
                  <div class="alert alert-danger">
                    {{error}}
                  </div>
                </li>
                <li *ngIf="passwordNotMatchError" class="show__alert__message"
                    style="padding-bottom: 5px;">
                  <div class="alert alert-danger">
                    {{passwordNotMatchError}}
                  </div>
                </li>

              </ul>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
