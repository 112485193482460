import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/services/user.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'app-privacy-policy-embedded',
  templateUrl: './privacy-policy-embedded.component.html',
  styleUrls: ['./privacy-policy-embedded.component.css']
})
export class PrivacyPolicyEmbeddedComponent implements OnInit {

  constructor(private userService: UserService, private ccService: NgcCookieConsentService) {

  }

  ngOnInit(): void {
    this.userService.isRedirected$.next(true);
    this.ccService.destroy();
  }

}
