import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as utf8 from 'crypto-js/enc-utf8';
import * as AES from 'crypto-js/aes';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as moment from "moment";
@Injectable({
    providedIn: 'root'
})
export class UserAuthService {
    public user;
    public user$ = new BehaviorSubject(undefined);
    private _apiKey = environment.apiSecretKey;

    constructor(private router: Router) {
        this.getStoredUser();
    }

    storeUser(data, authResult) {
        // const encrypted = AES.encrypt(
        //     JSON.stringify(data),
        //     this._apiKey
        // ).toString();        
        //localStorage.setItem('0', encrypted);
        if(data)
        {
            localStorage.setItem('0', data);
            const expiresAt = moment().add(authResult.expiresIn,'second');
    
            localStorage.setItem('id_token', authResult.token);
            localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );

            var userData = {data, token: authResult.token, expiresIn: authResult.expiresIn}
            this.user = userData;
            this.user$.next(this.user);
        }     
    }
    
    getStoredUser() {
        if (localStorage.getItem('0')) {
            const userdata = localStorage.getItem('0');
            try {
                // this.user = JSON.parse(
                //     AES.decrypt(userdata, this._apiKey).toString(utf8)
                // );
                this.user = userdata;
            } catch (err) {
                // Catching error means the data is tempered
                this.user = undefined;
                localStorage.removeItem('0');
            }
        } else {
            this.user = undefined;
            localStorage.removeItem('0');
        }
        this.user$.next(this.user);
        return this.user;
    }

    removeUser() {
        this.user = undefined;
        this.user$.next(this.user);
        localStorage.clear();
        //localStorage.removeItem('0');
    }

    reCallUserSubscriber() {
        this.user$.next(this.user);
    }

    setUserData(isCompleted) {
        const userdata = localStorage.getItem('0');
        try {
            this.user = JSON.parse(
                AES.decrypt(userdata, this._apiKey).toString(utf8)
            );
            this.user.is_completed = isCompleted;
            const encrypted = AES.encrypt(
                JSON.stringify(this.user),
                this._apiKey
            ).toString();
            localStorage.removeItem('0');
            localStorage.setItem('0', encrypted);
        } catch (err) {
            // Catching error means the data is tempered
            this.user = undefined;
            localStorage.removeItem('0');
        }
    }
}
