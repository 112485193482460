import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AcceptJSService, CreditCard } from '@openutility/acceptjs-angular-wrapper';
import { CreditCardValidators } from 'angular-cc-library';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserAuthService } from '../shared/services/auth/user-auth.service';
import { UserService } from '../shared/services/user.service';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { Subscription, timer } from 'rxjs';
import { delay, isEmpty, tap } from 'rxjs/operators';
import { REPL_MODE_SLOPPY } from 'repl';
import { DatePipe } from '@angular/common';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Location } from '@angular/common';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-modal-content',
  template: `
		<div class="modal-header">
			<h4 style="color:black" class="modal-title">Profile update</h4>
			<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
		</div>
		<div class="modal-body">
			<h5 style="color:black">Payment card was successfully updated, The credit card will be used from the next billing cycle.</h5>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-blue submitBtn" (click)="activeModal.close('Close click')">OK</button>
		</div>
	`,
})
export class NgbdModalContent {
  constructor(public activeModal: NgbActiveModal) { }
}

@Component({
  selector: 'app-change-payment',
  templateUrl: './change-payment.component.html',
  styleUrls: ['./change-payment.component.css'],
  animations: [
    trigger(
      'slideView',
      [
        state('true', style({ transform: 'translateX(100%)', opacity: 0 })),
        state('false', style({ transform: 'translateX(0)', opacity: 1 })),
        transition('0 => 1', animate('500ms', style({ transform: 'translateX(0)', 'opacity': 1 }))),
        transition('1 => 1', animate('500ms', style({ transform: 'translateX(100%)', 'opacity': 0 }))),
      ]),

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)', 'opacity': 1 }))
      ]),

      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('0ms ease-in', style({ transform: 'translateX(100%)', 'opacity': 0 }))
      ])
    ])
  ]
})
export class ChangePaymentComponent implements OnInit {
  user: any;
  subscriptionDetails: any;
  rateId: string;
  phoneNumber: string;
  email: string;
  amount: number;
  trial_amount: number;
  isActive: boolean;
  apiCalled: boolean = false;
  updated_price: number;
  error: string; success: string;
  strMessage: string;
  subscription_id: string;
  billing_amount: number;
  ccSubmit: boolean = false;
  end_date: Date;
  transId: string;
  fam_id: string;
  form: FormGroup;
  showTextarea: boolean = false
  closeResult = '';
  currentRateAmount: number
  rate_id: string;
  plan_id: string;
  planName: string;

  constructor(private userService: UserService, private userAuth: UserAuthService,
    private ngxloader: NgxUiLoaderService, private router: Router, private _acceptJSSrv: AcceptJSService,
    private _http: HttpClient, private route: ActivatedRoute, private ccService: NgcCookieConsentService,
    private _location: Location, private modalService: NgbModal) {
    this.user = this.userAuth.getStoredUser();
    this.getSubscriptionId();
  }

  ngOnInit(): void {
    this.ccService.destroy();
  }

  get ccf() {
    return this.creditCardForm.controls;
  }

  creditCardForm = new FormGroup({
    fullName: new FormControl('', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]),
    cardNumber: new FormControl('', [Validators.required, CreditCardValidators.validateCCNumber, Validators.maxLength(20)]),
    month: new FormControl('', [Validators.required]),
    year: new FormControl('', [Validators.required]),
    cardCode: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
    zip: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(15)]),
  });

  public async onSubmit(): Promise<void> {
    this.ngxloader.start();
    this.error = null;
    let unformattedCardNumber = this.creditCardForm.get('cardNumber').value;
    let formattedCard = this.removeSpaceBetween(unformattedCardNumber);
    this.creditCardForm.value.cardNumber = formattedCard;
    if (!this.creditCardForm.valid) {
      return;
    }
    let cc: CreditCard = this.creditCardForm.value;
    let ccExpDate = cc.year + '-' + cc.month;
    let ccNumber = cc.cardNumber;
    let ccCode = cc.cardCode;
    let zipCode = cc.zip;
    if (localStorage.getItem('0').includes('@')) {
      try {
        const rply = await this.userService.changePaymentWithEmail({
          transactionId: this.transId,
          ccNumber,
          ccExpDate,
          ccCode,
          zipCode,
          fullName: this.creditCardForm.value.fullName,
          amount: this.currentRateAmount
        }).toPromise();

        this.strMessage = JSON.stringify(rply);
        let jsonResponse = JSON.parse(this.strMessage);

        if (rply && (rply as any).respCode === 'I00001') {
          this.open();
          this.ngxloader.stop();
        }

        if (rply != null && rply != 'Invalid OTS Token.') {
          if (rply == "Bill-To Last Name is required.") {
            this.error = 'Last Name is required.'
          }
        }

        if (rply && 'error' in rply) {
          this.error = (rply as any).error.errorText;
        }
        
        if (rply != null && (rply as any).respCode != 'I00001') {
          this.error = rply;
        }

      } catch (ex) {
        if (this.strMessage != '"Invalid OTS Token."') {
          if (ex.messages != null) {
            this.error = ex.messages.message[0].text;
          }
          console.error(this.error);
          this.ngxloader.stop();
        }
      }
    } else {
      //with phone
      try {
        const rply = await this.userService.changePaymentWithPhone({
          transactionId: this.transId,
          ccNumber,
          ccExpDate,
          ccCode,
          zipCode,
          fullName: this.creditCardForm.value.fullName,
          amount: this.currentRateAmount
        }).toPromise();

        this.strMessage = JSON.stringify(rply);
        let jsonResponse = JSON.parse(this.strMessage);

        if (rply && (rply as any).respCode === 'I00001') {
          this.open();
          this.ngxloader.stop();
        }

        if (rply != null && rply != 'Invalid OTS Token.') {
          if (rply == "Bill-To Last Name is required.") {
            this.error = 'Last Name is required.'
          }
        }

        if (rply && 'error' in rply) {
          this.error = (rply as any).error.errorText;
        }

        if (rply != null && (rply as any).respCode != 'I00001') {
          this.error = rply;
        }

      } catch (ex) {
        if (this.strMessage != '"Invalid OTS Token."') {
          if (ex.messages != null) {
            this.error = ex.messages.message[0].text;
          }
          console.error(this.error);
          this.ngxloader.stop();
        }
      }
    }
  }

  getSubscriptionId() {
    if (localStorage.getItem('0') != null) {
      if (localStorage.getItem('0').includes('@')) {
        var email = localStorage.getItem('0')
        this.userService.getSubscriptionIdWithEmail(email).subscribe(resp => {
          if (resp.message === 'success') {
            /*this.fam_id = resp.fam_id;*/
            this.transId = resp.sub_id;
            this.rate_id = resp.rate;
            this.userService.getRateAmount(this.rate_id).subscribe(rateResp => {
              this.plan_id = rateResp.PlanId;
              this.currentRateAmount = rateResp.Amount;
              console.log(this.currentRateAmount);
              this.userService.getPlanName(this.plan_id).subscribe(planResp => {
                this.planName = planResp.Name;
              }, error => { })
            }, error => { })
          }
        }, error => { this.error = 'Error canceling subscription!' })
      }
    }
    if (!localStorage.getItem('0').includes('@')) {
      var phone = localStorage.getItem('0')
      this.userService.getSubscriptionIdWithPhone(phone).subscribe(resp => {
        if (resp.message === 'success') {
          /* this.fam_id = resp.fam_id;*/
          this.transId = resp.sub_id;
          this.rate_id = resp.rate;
          this.userService.getRateAmount(this.rate_id).subscribe(rateResp => {
            this.plan_id = rateResp.PlanId;
            this.currentRateAmount = rateResp.Amount;
            console.log(this.currentRateAmount);
            this.userService.getPlanName(this.plan_id).subscribe(planResp => {
              this.planName = planResp.Name;
            }, error => { })
          }, error => { })
        }
      }, error => { this.error = 'Error canceling subscription!' })
    }
  }

  removeSpaceBetween(value) {
    return value.toString().replace(/\s/g, "");
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  clearInfoMessages() {
    this.error = null;
    this.success = null;
  }

  retryPayment() {
    this.onSubmit();
  }

  goTOHome() {
    this.router.navigate(['/'])
  }

  backClicked() {
    if (this.router.navigated) {
      this._location.back();
    }

  }

  open() {
    const modalRef = this.modalService.open(NgbdModalContent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.backClicked();
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.backClicked();
      },
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
