<div class="wrapper">
    <div class="payment-header">
      <div class="payment-logo">
        <img src="./assets/images/logo.png" />
        <span style="padding-top: 0px !important; font-size: 0.5rem !important; margin-left: 45px !important; margin-top:5px!important" class="trademark">™</span>
        <h3>Change Password</h3>
      </div>
    </div>
    <section class="map-form-section login-form">

        <!-- <div class="social-icons">
          <ul>
              <li>
                  <a href="https://www.facebook.com/" target="_blank"><i class="fab fa-facebook-f"></i></a>
              </li>
              <li>
                  <a href="https://www.twitter.com/" target="_blank"><i class="fab fa-twitter"></i></a>
              </li>
              <li>
                  <a href="https://www.linkdin.com/" target="_blank"><i class="fab fa-linkedin-in"></i></a>
              </li>
              <li>
                  <a href="https://www.youtube.com/" target="_blank"><i class="fab fa-youtube"></i></a>
              </li>
          </ul>
      </div> -->
        <div class="backlink pointer"><a (click)="backClicked()"><i class="fas fa-chevron-left"></i> Dashboard</a></div>
        <div class="map-form">
            <div class="login-form-group" [@slideInOut]>
                <h3 class="addedMargin">Amount</h3>
                <h3 *ngIf="!showOTP" class="topText">Please enter your current and new passwords.</h3>
                <div class="form-group">
                    <div id="emailOrPhoneCheck">
                        <form autocomplete="on" [formGroup]="changePasswordForm" id="signup-form">
                            <ul>
                                <li *ngIf="!showOTP">         
                                    <input id="pass"
                                        [ngClass]="( submit && f['old_password']?.errors?.required ? 'form-error' : '')"
                                        type="password" formControlName="old_password" name="old_password"
                                        placeholder="Current Password" />
                                    <span id="shpass" class="fa fa-eye-slash field-icon" aria-hidden="true"
                                        onClick="viewPassword()"></span>

                                </li>
                                <li *ngIf="!showOTP">
                                    <!-- <p style="text-align: center;">New Password</p> -->
                                    <input id="cpass"
                                        [ngClass]="( submit && f['new_password']?.errors?.required ? 'form-error' : '')"
                                        type="password" formControlName="new_password" name="new_password"
                                        placeholder="New Password" />
                                    <span id="shcpass" class="fa fa-eye-slash field-icon" aria-hidden="true"
                                        onClick="viewCPassword()"></span>
                                </li>
                                <li *ngIf="!showOTP">
                                    <!-- <p style="text-align: center;">New Password</p> -->
                                    <input id="ccpass"
                                        [ngClass]="( submit && f['confirm_new_password']?.errors?.required ? 'form-error' : '')"
                                        type="password" formControlName="confirm_new_password" name="confirm_new_password"
                                        placeholder="Confirm New Password" />
                                    <span id="shccpass" class="fa fa-eye-slash field-icon" aria-hidden="true"
                                        onClick="viewCCPassword()"></span>
                                </li>
                                <li *ngIf="!showOTP">
                                    <input type="submit" id="submit" style="margin-top: 10px;margin-bottom: 10px;"
                                        [disabled]="!changePasswordForm.valid" class="btn btn-orange submitBtn"
                                        (click)="changePassword()" name="submit" value="Change Password">
                                </li>
                                <li *ngIf="error" class="show__alert__message" style="padding-bottom: 5px;">
                                    <div class="alert alert-danger">
                                        {{error}}
                                    </div>
                                </li>
                                <li *ngIf="passwordNotMatchError" class="show__alert__message"
                                    style="padding-bottom: 5px;">
                                    <div class="alert alert-danger">
                                        {{passwordNotMatchError}}
                                    </div>
                                </li>
                                <li *ngIf="showOTP">
                                    <h6>Verify by Phone OTP</h6>
                                    <p>
                                      Enter the 4 digit OTP sent to:
                                      <strong *ngIf="phoneCheck">+{{localPhone}}</strong>
                                      <strong *ngIf="emailCheck">+{{localEmail}}</strong>
                                    </p>
                  
                                    <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)"
                                                   (fill)="handleFillEvent($event)"></ngx-otp-input>
                                    <!-- <input [ngClass]="( signSubmit && f['password']?.errors?.required ? 'form-error' : '')"
                      type="text" autocomplete="off" formControlName="password" name="otp" placeholder="OTP" /> -->
                  
                                    <span *ngIf="showResendOTP" class="resend-otp-text pointer" (click)="resendOTP()">
                                      Resend OTP
                                    </span>
                                    <span *ngIf="!showResendOTP" class="resend-otp-text">
                                      Re-send otp in {{counter|formatTime}}
                                    </span>
                                  </li>
                                  <li *ngIf="error && showOTP" class="show__alert__message">
                                    <div class="alert alert-danger">
                                      {{error}}
                                    </div>
                                  </li>
                                  <li *ngIf="error && !showOTP" class="show__alert__message" style="padding-bottom: 5px;">
                                    <div class="alert alert-danger">
                                      {{error}}
                                    </div>
                                  </li>
                                  <li *ngIf="success" class="show__alert__message" style="padding-bottom: 5px;">
                                    <div class="alert alert-success">
                                      {{success}}
                                    </div>
                                  </li>
                                  <li>                                    
                                    <input *ngIf="showOTP" type="button" class="btn btn-blue submitBtn" (click)="checkPhoneOTP()" name="submit" value="Confirm" />
                                  </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
