import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserService } from '../shared/services/user.service';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
declare function phoneOrEmail(): any;

@Component({
  selector: 'ngbd-modal-content',
  template: `
		<div class="modal-header">
			<h4 style="color:black" class="modal-title">Feedback sent</h4>
			<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
		</div>
		<div class="modal-body">
			<h5 style="color:black">Thank you for taking the time to send us your feedback, we greatly appreciate it.</h5>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-blue submitBtn" (click)="activeModal.close('Close click')">OK</button>
		</div>
	`,
})
export class NgbdModalContent {
  constructor(public activeModal: NgbActiveModal) { }
}

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  error: string;
  success: string
  contactForm: FormGroup;
  signSubmit: boolean = false;
  closeResult = '';
  messageSubject = '';
  phoneOption: boolean = false;
  phoneCheck = "checked";
  emailCheck: string;

  constructor(private userService: UserService, private ngxloader: NgxUiLoaderService, private modalService: NgbModal, private router: Router,
    route: ActivatedRoute, private _location: Location, private ccService: NgcCookieConsentService ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.ccService.destroy();
    this.contactForm.reset();
    setTimeout(() => {
      this.phoneOption = phoneOrEmail(), 1000
    });
  }

  get f() {
    return this.contactForm.controls;
  }

  initForm() {
    this.contactForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      email_id: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(254)]),
      message: new FormControl('', [Validators.required, Validators.pattern(/^[\x20-\x7E]{6,}$/)]),
    });
  }

  onSubmit() {
    this.signSubmit = true;
    this.clearMessages();
    if (this.contactForm.valid) {
      let email = { email_id: this.contactForm.get('email_id').value }
      this.userService.validateUserEmailAddress(email).subscribe(resp => {
        if (resp.message === 'success (email already exists)') {
          if (this.phoneOption) {
            this.messageSubject = 'Suggestion'
          } else {
            this.messageSubject = 'Bug report'
          }
          var data = { name: this.contactForm.get('name').value, email: this.contactForm.get('email_id').value, message: this.contactForm.get('message').value, subject: this.messageSubject }
          this.ngxloader.start();
          this.userService.sendFeedback(data).subscribe(resp => {
            if (resp.message === 'Email was successfully sent!') {
              this.open();
            }
            else {
              this.error = resp.message;
            }
            this.ngxloader.stop();
          });
        } else {
          this.error = resp.message;
          this.ngxloader.stop();
        }
      }, err => {
        this.error = 'User not found.';
        this.ngxloader.stop();
      });
     
      //this.formReset();
    } else {
      this.error = "Please fill up all the required fields with valid details"
    }
  }

  formReset() {
    this.signSubmit = false;
    this.contactForm.reset();
  }

  clearMessages() {
    this.error = null;
    this.success = null;
  }


  open() {
    const modalRef = this.modalService.open(NgbdModalContent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.router.navigate(['/'])
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.router.navigate(['/'])
      },
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  selectedOption() {
    this.phoneOption = phoneOrEmail()
    if (this.phoneOption == true) {
      this.clearInfoMessages();
      this.phoneCheck = "checked";
      this.emailCheck = '';
    }
    else {
      this.clearInfoMessages();
      this.emailCheck = "checked";
      this.phoneCheck = '';
    }
  }

  clearInfoMessages() {
    this.error = null;
    this.success = null;
  }
}
