import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UserService } from '../shared/services/user.service'
import { UserAuthService } from '../shared/services/auth/user-auth.service'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpClient } from '@angular/common/http';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AcceptJSService } from '@openutility/acceptjs-angular-wrapper';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-subscription-history',
  templateUrl: './subscription-history.component.html',
  styleUrls: ['./subscription-history.component.css']
})
export class SubscriptionHistoryComponent implements OnInit {
  date: Date;
  strTransIds: string = "";
  strTransDate: string = "";
  transId: string;
  error: string = null;
  user :any;
  subHistory: Array<string>;
  subHistoryDate: Array<string>;
  showNoData :boolean =false;
  apiCalled: boolean = false;
  amount: number;
  constructor(private userService : UserService , private userAuth : UserAuthService,
    private ngxloader: NgxUiLoaderService, private _http: HttpClient, private _acceptJSSrv: AcceptJSService, public datepipe: DatePipe) {
    this.user = this.userAuth.getStoredUser();
    this.getSubscriptionId();
    /*this.getSubscriptionHistory();*/
  }


  ngOnInit(): void {
  }

  getSubscriptionId() {
    if (localStorage.getItem('0') != null) {
      if (localStorage.getItem('0').includes('@')) {
        var email = localStorage.getItem('0')
        this.userService.getSubscriptionIdWithEmail(email).subscribe(resp => {
          if (resp.message === 'success') {
            this.transId = resp.sub_id;
            this.getSubscriptionHistory();
          }
        }, error => { this.error = 'Error getting subscription!' })
      }
    }
    if (!localStorage.getItem('0').includes('@')) {
      var phone = localStorage.getItem('0')
      this.userService.getSubscriptionIdWithPhone(phone).subscribe(resp => {
        if (resp.message === 'success') {
          this.transId = resp.sub_id;
          this.getSubscriptionHistory();
        }
      }, error => { this.error = 'Error getting subscription!' })
    }
  }

 //async getSubscriptionHistory(){
 //   this.ngxloader.start();
 //   this.userService.getsubscriptionHistory().subscribe(resp =>{
 //     console.log("sub history", resp);
 //     if(resp.status === 'success'){
 //       this.showNoData =false;
 //       this.subHistory = resp.data;
 //       if(this.subHistory.length === 0){
 //         this.showNoData = true;
 //       }
 //       //console.log(this.subHistory.length);
 //       this.apiCalled =true;
 //       this.ngxloader.stop();
 //     }else{
 //       this.showNoData = true;
 //     }
 //     this.apiCalled =true;
 //     this.ngxloader.stop();
 //   },error => {
 //     this.showNoData =true;
 //     this.apiCalled =true;
 //     this.ngxloader.stop();
 //   });
 // }

  async getSubscriptionHistory() {

    this.ngxloader.start();
    if (localStorage.getItem('0').includes('@')) {
      try {
        const rply = await this.userService.subHistoryWithEmail({
          transactionId: this.transId
        }).toPromise();

        let str = JSON.stringify(rply);
        let jsonResponse = JSON.parse(str);

        if (rply && (rply as any).respCode === 'I00001') {
          //display sub history
          if (rply.transactions != null && rply.transactions.length >= 1) {
            for (let i = 0; i < jsonResponse.transactions.length; i++) {
              if (jsonResponse.transactions[i].transId != null) {
                this.strTransIds += jsonResponse.transactions[i].transId + " ";             
                this.strTransDate += this.datepipe.transform(new Date(jsonResponse.transactions[i].submitTimeUTC), 'yyyy-MM-dd') + ",";
              }
            }
            if (this.strTransIds != '' && this.strTransDate != '') {
              this.showNoData = false;
              this.subHistory = this.strTransIds.split(" ").filter(id => id);
              this.subHistoryDate = this.strTransDate.split(",").filter(date => date);
            } else {
              this.showNoData = true;
            }
          } else {
            this.showNoData = true;
          }
          this.apiCalled = true;
          this.amount = rply.amount;
          this.ngxloader.stop();
        } else {
          this.showNoData = true;
          this.apiCalled = true;
          this.ngxloader.stop();
          this.error = jsonResponse.msgCode;
        }

        if (rply != null && (rply as any).msgCode == null) {
          this.error = jsonResponse;
        }

        if (rply && 'error' in rply) {
          this.error = (rply as any).error.errorText;
        }

      } catch (ex) {
        if (ex.error != null) {
          this.showNoData = true;
          this.apiCalled = true;
          this.error = ex.error;
        }
        console.error(this.error);
        this.ngxloader.stop();
      }
    } else {
      try {
        const rply = await this.userService.subHistoryWithPhone({
          transactionId: this.transId
        }).toPromise();

        let str = JSON.stringify(rply);
        let jsonResponse = JSON.parse(str);

        if (rply && (rply as any).respCode === 'I00001') {
          //display sub history
          if (rply.transactions != null && rply.transactions.length >= 1) {
            for (let i = 0; i < jsonResponse.transactions.length; i++) {
              if (jsonResponse.transactions[i].transId != null) {
                this.strTransIds += jsonResponse.transactions[i].transId + " ";
                this.strTransDate += this.datepipe.transform(new Date(jsonResponse.transactions[i].submitTimeUTC), 'yyyy-MM-dd') + ",";
              }
            }
            if (this.strTransIds != '' && this.strTransDate != '') {
              this.showNoData = false;
              this.subHistory = this.strTransIds.split(" ").filter(id => id);
              this.subHistoryDate = this.strTransDate.split(",").filter(date => date);
            } else {
              this.showNoData = true;
            }
          } else {
            this.showNoData = true;
          }
          this.apiCalled = true;
          this.amount = rply.amount;
          this.ngxloader.stop();
        } else {
          this.showNoData = true;
          this.apiCalled = true;
          this.ngxloader.stop();
          this.error = jsonResponse.msgCode;
        }

        if (rply != null && (rply as any).msgCode == null) {
          this.error = jsonResponse;
        }

        if (rply && 'error' in rply) {
          this.error = (rply as any).error.errorText;
        }

      } catch (ex) {
        if (ex.error != null) {
          this.error = ex.error;
        }
        console.error(this.error);
        this.ngxloader.stop();
      }
    }

  }

  logOut(){
    this.userService.logout();
  }

}
