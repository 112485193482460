import { UserAuthService } from '../shared/services/auth/user-auth.service'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CreditCardValidators } from 'angular-cc-library';
import { UserService } from '../shared/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AcceptJSService, CreditCard } from '@openutility/acceptjs-angular-wrapper';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { Subscription, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { delay, isEmpty, tap } from 'rxjs/operators';
import { REPL_MODE_SLOPPY } from 'repl';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-subscription-renew',
  templateUrl: './subscription-renew.component.html',
  styleUrls: ['./subscription-renew.component.css'],
  animations: [
    trigger(
      'slideView',
      [
        state('true', style({ transform: 'translateX(100%)', opacity: 0 })),
        state('false', style({ transform: 'translateX(0)', opacity: 1 })),
        transition('0 => 1', animate('500ms', style({ transform: 'translateX(0)', 'opacity': 1 }))),
        transition('1 => 1', animate('500ms', style({ transform: 'translateX(100%)', 'opacity': 0 }))),
      ]),

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)', 'opacity': 1 }))
      ]),

      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('0ms ease-in', style({ transform: 'translateX(100%)', 'opacity': 0 }))
      ])
    ])
  ]
})
export class SubscriptionRenewComponent implements OnInit {
  user: any;
  subscriptionDetails: any;
  rateId: string;
  phoneNumber: string;
  email: string;
  amount: number;
  trial_amount: number;
  isActive: boolean;
  apiCalled: boolean = false;
  updated_price: number;
  error: string; success: string;
  strMessage: string;
  subscription_id: string;
  billing_amount: number;
  ccSubmit: boolean = false;
  end_date: Date;
  ShowThankyouScreen: boolean = false;
  fam_id: string;

  constructor(private userService: UserService, private userAuth: UserAuthService,
    private ngxloader: NgxUiLoaderService, private router: Router, private _acceptJSSrv: AcceptJSService,
    private _http: HttpClient, private route: ActivatedRoute) {
    this.user = this.userAuth.getStoredUser();
    this.getSubscriptionDetails();
  }

  ngOnInit(): void {
  }

  get ccf() {
    return this.creditCardForm.controls;
  }

  creditCardForm = new FormGroup({
    fullName: new FormControl('', [Validators.required, Validators.maxLength(200), Validators.maxLength(3)]),
    cardNumber: new FormControl('', [Validators.required, CreditCardValidators.validateCCNumber, Validators.maxLength(20)]),
    month: new FormControl('', [Validators.required]),
    year: new FormControl('', [Validators.required]),
    cardCode: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
    zip: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(15)]),
  });

  public async onSubmit(): Promise<void> {
    this.ngxloader.start();
    this.error = null;
    let unformattedCardNumber = this.creditCardForm.get('cardNumber').value;
    let formattedCard = this.removeSpaceBetween(unformattedCardNumber);
    this.creditCardForm.value.cardNumber = formattedCard;
    if (!this.creditCardForm.valid) {
      return;
    }
    let cc: CreditCard = this.creditCardForm.value;
    try {
      // generate a nonce payment using the acceptJS service.
      const nonce = await this._acceptJSSrv.generatePaymentNonce(cc);
      await this.delay(6000);
      /*this.ngxloader.stop();*/
      // submit nonce to your server with payment amount
      const rply = await this.userService.payment({
        nonce,
        amount: this.updated_price,
        fullName: this.creditCardForm.value.fullName,
        email: this.email,
        phoneNumber: this.phoneNumber,
        trial_amount: this.trial_amount
      }).toPromise();

      this.strMessage = JSON.stringify(rply);
      let jsonResponse = JSON.parse(this.strMessage);

      if (rply == 'Invalid OTS Token.') {
        this.retryPayment();
      }

      if (rply != null && rply != 'Invalid OTS Token.' && jsonResponse.transId != null) {
        this.subscription_id = jsonResponse.transId;
        this.billing_amount = jsonResponse.subAmount;
      }
      if (rply && (rply as any).respCode === 'I00001') {
        //create user to mongo
        //this.createFlexisipUser();
        this.updateUser(jsonResponse.dateInterval.startDate); //update user
        //create user to flexisip
      }
      if (rply != null && rply != 'Invalid OTS Token.' && jsonResponse.transId == null) {
        if (rply == "Bill-To Last Name is required.") {
          this.error = 'Last Name is required.'
        }
        else {
          this.error = rply;
        }
      }

      if (rply && 'error' in rply) {
        this.error = (rply as any).error.errorText;
      }
     
    } catch (ex) {
      if (this.strMessage != '"Invalid OTS Token."') {
        if (ex.messages != null) {
          this.error = ex.messages.message[0].text;
        }
        console.error(this.error);
        this.ngxloader.stop();
      }
    }
  }

  getSubscriptionDetails() {
    this.ngxloader.start();
    if (this.user.includes("@")) {
      this.userService.getRenewSubscriptionDetailsWithEmail(this.user).subscribe(resp => {
        if (resp.message == 'success') {
          this.rateId = resp.rate_id;
          this.email = resp.email_address;
          this.phoneNumber = resp.phone_number;
          this.isActive = resp.is_active;
          this.fam_id = resp.fam_id;
          this.userService.getRateAmount(this.rateId).subscribe(rateResp => {
            this.amount = rateResp.Amount;
            if (this.amount > 0) {
              //save needed data
              this.updated_price = this.amount;
              this.trial_amount = this.amount;
              this.subscriptionDetails = resp;
            }
          }, error => { })
        }
        this.apiCalled = true;
        this.ngxloader.stop();
      }, error => {
        this.apiCalled = true;
        this.ngxloader.stop();
      })
    } else {
      this.userService.getRenewSubscriptionDetailsWithPhoneNumber(this.user).subscribe(resp => {
        if (resp.message == 'success') {
          this.rateId = resp.rate_id;
          this.email = resp.email_address;
          this.phoneNumber = resp.phone_number;
          this.isActive = resp.is_active;
          this.fam_id = resp.fam_id;
          this.userService.getRateAmount(this.rateId).subscribe(rateResp => {
            this.amount = rateResp.Amount;
            if (this.amount > 0) {
              this.updated_price = this.amount;
              this.trial_amount = this.amount;
              this.subscriptionDetails = resp;
            }
          }, error => { })
        }
        this.apiCalled = true;
        this.ngxloader.stop();
      }, error => {
        this.apiCalled = true;
        this.ngxloader.stop();
      })
    }

  }

  updateUser(startdate: string) {
    this.ccSubmit = true
    this.clearInfoMessages();
    if (this.creditCardForm.valid) {
      this.ngxloader.start();
      let unformattedCardNumber = this.creditCardForm.get('cardNumber').value;
      this.creditCardForm.get('cardNumber').setValue(this.removeSpaceBetween(unformattedCardNumber));
      if (startdate != '') {
        let endDate = new Date(startdate);
        endDate.setMonth(endDate.getMonth() + 1)
        this.end_date = endDate;
      }
      let updateMongoData = {
        is_active: true,
        next_billing_date: this.end_date,
        start_billing_date: startdate,
        /*trial_end: null,*/
        auth_sub_id: this.subscription_id
      }
      if (localStorage.getItem('0').includes('@')) {
        var email = localStorage.getItem('0');
        this.userService.updateSubscriptionWithEmail(email, updateMongoData).subscribe(resp => {
          if (resp.message == "success") {
            //above code for updating in our mongo after flexisip success
            //and redirect to home page or dashboard
            this.userService.activateFlexisipUser(this.fam_id).subscribe((resp) => {
              this.showThankyou();
              this.ngxloader.stop();
            });
          }
        }, (error) => { console.log('Error is', error); })
      } else {
        var phone = localStorage.getItem('0');
        this.userService.updateSubscriptionWithPhoneNumber(phone, updateMongoData).subscribe(resp => {
          if (resp.message == "success") {
            //above code for updating in our mongo after flexisip success
            //and redirect to home page or dashboard
            this.userService.activateFlexisipUser(this.fam_id).subscribe((resp) => {
              this.showThankyou();
              this.ngxloader.stop();
            });
          }
        }, (error) => { console.log('Error is', error); })
      }
    } else {
      this.error = "Please fill up all the required fields with valid details"
      this.ngxloader.stop();
    }
  }

  removeSpaceBetween(value) {
    return value.toString().replace(/\s/g, "");
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  clearInfoMessages() {
    this.error = null;
    this.success = null;
  }

  showThankyou() {
    this.ShowThankyouScreen = true;
  }

  retryPayment() {
    this.onSubmit();
  }

  goTOHome() {
    this.router.navigate(['/'])
  }
}
