<main-app-header></main-app-header>
<div class="thankyou-bg privacy-policy-bg">
  <div class="logo">
    <div>
      <a [routerLink]="['/signup']"><img src="./assets/images/logo.png" /></a>
      <span style="margin-top:-20px" class="trademark2">™</span>
    </div>
  </div>

    <div class="thankyou-box box-shadow privacy-policy-box">

        <h3>Titanium™ Secure Privacy Policy</h3>
        <h4>1. Introduction</h4>
        <p>This Privacy Policy sets out how we, Titanium™ Secure Services, LLC (“Titanium™ Secure”),
             use and protect your personal data that you provide to us, or that is otherwise obtained
              or generated by us, in connection with your use of our cloud-based messaging services 
              (the “Services”). For the purposes of this Privacy Policy, ‘we’, ‘us’ and ‘our’ refers
               to Titanium™ Secure, and ‘you’ refers to you, the user of the Services.
        </p>
        <p><strong>1.1. Privacy Principles</strong>Titanium™ Secure has two fundamental principles when
             it comes to collecting and processing private data:</p>
        <p>We don't use your data to show you ads.</p>
        <p>We only store the data that Titanium™ Secure needs to function as a secure and feature-rich messaging service.
        </p>
        <p><strong>1.2. Terms of Service</strong>This Privacy Policy forms part of our Terms of Service, which describes 
            the terms under which you use our Services and which are available at https://TitaniumSecure.io.
             This Privacy Policy should therefore be read in conjunction with those terms.
        </p>
        <p><strong>1.3. Table of Contents</strong>This Privacy Policy explains the following:</p>
        <ul>
            <li>the legal basis for processing your personal data;</li>
            <li>what personal data we may collect from you;</li>
            <li>how we keep your personal data safe;</li>
            <li>what we may use your personal data for;</li>
            <li>who your personal data may be shared with; and</li>
            <li>your rights regarding your personal data.</li>

        </ul>
        <p><strong>1.4. EEA Representative</strong>If you live in a country in the European Economic Area (EEA), the Services
            are provided by Titanium™ Secure, which for the purposes of applicable data protection legislation is the
            data controller responsible for your personal data when you use our Services. However, as Titanium™ Secure is
            located outside the EEA, you may contact us directly with any issues you have relating to our processing of
            your personal data.</p>

        <h4>2. Legal Ground for Processing Your Personal Data</h4>
        <p>We process your personal data on the ground that such processing is necessary to further our legitimate interests 
            (including: (1) providing effective and innovative Services to our users; and (2) to detect, prevent or otherwise
             address fraud or security issues in respect of our provision of Services), unless those interests are overridden
              by your interest or fundamental rights and freedoms that require protections of personal data.</p>
        <h4>3. What Personal Data We Use</h4>
        <p><strong>3.1. Basic Account Data</strong> Titanium™ Secure is a communication service. You provide your mobile number
             and basic account data (which may include profile name, profile picture and about information) 
             to create a Titanium™ Secure account.
        </p>
        <p>To make it easier for your contacts and other people to reach you and recognize who you are, the screen name you choose,
             your profile pictures, and your username (should you choose to set one) on Titanium™ Secure are always public.
              We don't want to know your real name, gender, age or what you like.
        </p>
        <p>
            We do not require your screen name to be your real name. Note that users who have you in their contacts will 
            see you by the name they saved and not by your screen name. This way your mother can have the public name 
            'Johnny Depp' while appearing as 'Mom' to you and as 'Boss' to her underlings at work (or the other way 
            around, depending on how these relationships are structured).
            
        </p>
        <p><strong>3.2. Your E-mail Address</strong>When you enable 2-step-verification for your account or store documents
             using the Titanium™ Secure feature, you can opt to set up a password recovery email. This address will only be
              used to send you a password recovery code if you forget it. That's right: no marketing or “we miss you” emails.
        </p>
        <p><strong>3.3. Your Messages</strong></p>
        <ul>
            <li><strong>3.3.1. Cloud Chats</strong><br />Titanium™ Secure is a secure communications service. We currently 
                do not store messages, photos, videos, and documents from your cloud chats on our servers and as the 
                applications suite is ephemeral all such data is timed out to self-destruct across all points of contact.
                 All data is transmitted under heavily encrypted formats and the encryption keys in each case are provided
                  by design only to the active parties in each communication string. This way local engineers or physical
                   intruders cannot get access to user data.</li>
            <li><strong>3.3.2. Secure Chats</strong><br />Secure chats use end-to-end encryption. This means that all data 
                is encrypted with a key that only you and the recipient know. There is no way for us or anybody else without 
                direct access to your device to learn what content is being sent in those messages. We do not store the 
                transcript of your Secure chats on our servers. For the same reasons Secure chats are not available in the cloud 
                — you can only access those messages from the device they were sent to or from and only while they are timed in as active.
            </li>
            <li><strong>3.3.3. Media in Secure Chats</strong><br />When you send photos, videos, or files via Secure chats, before being
                 uploaded, each item is encrypted with a separate key, not known to the server. This key and the file’s location are 
                 then encrypted again, this time with the Secure chat’s key — and sent to your recipient. They can then download and 
                 decipher the file. This means that the file is technically on one of Titanium™ Secures’ servers, but it looks like a 
                 piece of random indecipherable garbage to everyone except for you and the recipient. We don’t know what this random 
                 data stands for and we have no idea which chat it belongs to. We periodically purge this random data from our servers
                  to save disk space.
            </li>
            <li><strong>3.3.4. Public Chats</strong><br />In addition to private messages, Titanium™ Secure will also support public 
                channels and public groups. All public chats are cloud chats (see section 3.3.1 above). Like everything on Titanium™ 
                Secure, the data you post in public communities is encrypted, both in storage and in transit — but everything you 
                post in public will be accessible to everyone within a public group.
            </li>
        </ul>
        <p><strong>3.4. Phone Number and Contacts</strong>Titanium™ Secure uses phone numbers as unique identifiers so that it is easy 
            for you to switch from SMS and other messaging apps and retain your social graph. </p>
        <p>W
            We access your contacts to notify you as soon as one of your contacts signs up for Titanium™ Secure
             and to properly display names in notifications. 
            
        </p>
        <p>If you are using Android, Titanium™ Secure will ask you for permissions to access various android systems.  
            Titanium™ Secure uses these permissions in accordance with this Privacy Policy.
        </p>

        <p><strong>3.5. Location Data</strong>If you share a location in a chat, this location data is treated like other
            messages in cloud or Secure chats respectively.</p>
        <p><strong>3.6. Cookies</strong>We do not use cookies for profiling or advertising..
        </p>
        <h4>4. Keeping Your Personal Data Safe</h4>
        <p><strong>4.1. Storing Data</strong>All stored data is heavily encrypted so that local 
            Titanium™ Secure engineers or physical intruders cannot get access.
            access.
        </p>
        <p><strong>4.2. End-to-End Encrypted Data</strong>Your messages, media, and files from Secure chats
             (see section 3.3.2 above), as well as the contents of your calls and the data you store in your
              Titanium™ Secure Applications are processed only on your device and on the device of your recipient.
               Before this data reaches our servers, it is encrypted with a key known only to you and the recipient.
                While Titanium™ Secure servers will handle this end-to-end encrypted data to deliver it to the recipient
                 – or store it in the case of Titanium™ Secure Device data, we have no ways of deciphering the actual
                  information. In this case, we neither store nor process your personal data, rather we store and process 
                  random sequences of symbols that have no meaning without the keys which we don’t have.
        </p>
        <p><strong>4.3. Retention</strong>Unless stated otherwise in this Privacy Policy, the personal data that you provide
            us will only be stored for as long as it is necessary for us to fulfill our obligations in respect of the
            provision of the Services.</p>

        <h4>5. Processing Your Personal Data</h4>
        <p><strong>5.1. Our Services</strong>Titanium™ Secure is a cloud service. We will process your data to deliver your
             cloud chat history while active, including messages, media, and files, to any devices of your choosing without
              a need for you to use third-party backups or cloud storage.</p>
        <p><strong>5.2. Safety and Security</strong>Titanium™ Secure will support communities which we have to police
             against abuse and Terms of Service violations. Titanium™ Secure will be growing a unique User Audience
              which makes it a target for spammers. To improve the security of your account, as well as to prevent 
              spam, abuse, and other violations of our Terms of Service, we may collect metadata such as your IP address,
               devices, and Titanium™ Secure apps you've used, history of username changes, etc. If collected,
                this metadata can be kept for 12 months maximum.</p>
        <p><strong>5.3. Spam and Abuse</strong>TTo prevent phishing, spam and other kinds of abuse and violations of 
            Titanium™ Secures Terms of Service, our moderators may check messages that were reported to them by their 
            recipients. If a spam report on a message you sent is confirmed by our moderators, your account may be 
            limited from contacting strangers – temporarily or permanently. In case of more serious violations, 
            your account may be banned.  </p>
        <p><strong>5.4. Cross-Device Functionality</strong>We may also store some aggregated metadata to create 
            Titanium™ Secure features (see section 5.5 below) that work across all your devices.</p>
        <p><strong>5.5. Advanced features</strong>We may use some aggregated data about how you use Titanium™ Secure to build
            useful features including enterprise group contacts and automation of prioritization of likely (or most
            recent active) users for quicker communications access.</p>
        <p><strong>5.6. No Ads Based on User Data</strong>Unlike other services, we don't use your data for ad targeting or
            other commercial purposes. Titanium™ Secure only stores the information it needs to function as a secure and
            feature-rich cloud service.</p>
        <p>Titanium™ Secure offers a tool for advertisers to promote their messages in public one-to-many channels, but
            these sponsored messages are based solely on the topic of the public channels in which they will be shown.
            No user data is mined or analyzed to display ads or sponsored messages.</p>

        <h4>6. Third Party Payment Services</h4>
        <p><strong>6.1. Payment Information</strong>Titanium™ Secure does not process payments from users and instead relies 
            on different payment providers around the world. Although we work with payment providers, they are completely 
            independent from Titanium™ Secure. Please study their relevant privacy policies before making your data available to them.</p>
        <p><strong>6.2. Credit Card Information</strong>When making a purchase, you enter your credit card details into a
            form supplied by the payment provider that will be processing the payment, and this information goes
            directly to the payment provider's server. Your credit card information never reaches Titanium™ Secure's
            servers. We do not access and do not store your credit card information. When you save your credit card
            info, it is saved on the respective payment provider's servers and the payment provider gives Titanium™
            Secure a token that you can reuse for future payments. It is not possible to reconstruct your credit card
            info from the token.</p>
        <p><strong>6.3. Shipping Information</strong>When you enter shipping information in the process of placing an
             order, we send it directly to the merchant bot developer. </p>
        <p><strong>6.4. Payment Disputes</strong>Since Titanium™ Secure doesn't store any credit card details or transaction 
            information, it is impossible for us to handle complaints or cashbacks – any disputed payments are the 
            responsibility of the payment providers and banks that participated in the exchange.</p>
        <h4>7. Who Your Personal Data May Be Shared With</h4>
        <p><strong>7.1. Other Titanium™ Secure Users</strong>Note that by entering into the Terms of Service and choosing to 
            communicate with such other users of Titanium™ Secure, you are instructing us to transfer your personal data, 
            on your behalf, to those users in accordance with this Privacy Policy. We employ all appropriate technical 
            and organizational measures (including encryption of your personal data) to ensure a level of security for 
            your personal data that is appropriate to the risk.</p>
        <p><strong>7.2. Titanium™ Secures Group Companies</strong>We may share your personal data with: (1) our parent
            company, 14ALL Inc, located in South Dakota, U.S.A.; to help provide, improve, and support our Services. We
            will implement appropriate safeguards to protect the security and integrity of that personal data. This will
            take the form of standard contract clauses approved by the European Commission in an agreement between us
            and our relevant group companies. If you would like more information regarding these clauses, please contact
            us using the details in section 11 below.</p>
        <p><strong>7.3. Law Enforcement Authorities</strong>If Titanium™ Secure receives a court order that confirms
             you're a terror suspect, we may disclose your IP address (if available) and phone number of registration
              to the relevant authorities. So far, this has never happened. When it does, we will include it in a 
              semiannual transparency report published at: https://titaniumsecure.io or, if prohibited from making 
              the disclosure, will edit this section.</p>
        <p><strong>7.4. Wireless Operator</strong>You authorize your wireless operator (Telus, Bell, Rogers, 
            or any other branded wireless operator) to disclose your mobile number, name, address, email, network status, 
            customer type, customer file, billing type, mobile device identifiers (IMSI and IMEI) and other subscriber status 
            and device details, if available, to our third party service provider, solely to verify your identity and prevent 
            fraud for the duration of the business relationship.</p>
        <h4>8. Your Rights Regarding the Personal Data You Provide to Us</h4>
        <p><strong>8.1. Your Rights</strong>Under applicable data protection legislation, in certain circumstances, 
            you have rights concerning your personal data. You may have a right to: (1) request a copy of all your
             personal data that we store and to transmit that copy to another data controller; (2) delete (see 
             section 9 below) or amend your personal data; (3) restrict, or object to, the processing of your 
             personal data; (4) correct any inaccurate or incomplete personal data we hold on you; and (5) 
             lodge a complaint with national data protection authorities regarding our processing of your personal data.</p>
        <p><strong>8.2. Exercising Your Rights</strong>If you wish to exercise any of these rights, kindly contact us using
            the details in section 11 below.</p>
        <p><strong>8.3. Data Settings</strong>Sadly, if you're not OK with Titanium™ Secures modest minimum requirements,
             it won't be possible for us to provide you with our Services. You can delete your Titanium™ Secure account
              by proceeding to the deactivation page.
        </p>
    
        <h4>9. Deleting data</h4>
        <p><strong>9.1. Accounts</strong>If you would like to delete your account, you can do this on the deactivation page.
            Deleting your account removes all messages, media, contacts, and every other piece of data you store in the
            Titanium™ Secure cloud. This action must be confirmed via your Titanium™ Secure account and cannot be undone.
        </p>
        <p><strong>9.2. Self-Destructing Messages</strong>Messages in Secure Chats can be ordered to self-destruct.
             As soon as such a message is read (2 checks appear), the countdown starts. When the timer expires, 
             both devices participating in a Secure chat are instructed to delete the message (photo, video, etc.).
              Media with short timers (less than a minute) are shown with blurred previews. The timer is triggered when they are viewed.</p> 
        <h4>10. Changes to this Privacy Policy</h4>
        <p>We will review and may update this Privacy Policy from time to time. Any changes to this Privacy Policy will become effective
             when we post the revised Privacy Policy on this page https://www.titaniumsecure.io/privacy-policy. Please check our website
              frequently to see any updates or changes to our Privacy Policy.</p>
        <p>March 5, 2025</p>
        <h4>11. Questions and concerns</h4>
        <p>If you have any questions about privacy and our data policies, please contact our client services at info@titaniumsecure.io. 
            Use the /access command to learn how to get a copy of your Titanium™ Secure data and use the /contact command to leave a request,
             which we will answer at the earliest opportunity.</p>
        <p>            Titanium™ Secure may be repurposed for private or public partnerships. Contact Titanium™ Secure for further details.
            </p>

    </div>
</div>
