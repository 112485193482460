import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserAuthService } from '../auth/user-auth.service';
import { ToastrService } from 'ngx-toastr';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SHA256 } from 'crypto-js';
@Injectable()
export class CommonMariaHttpClient {
  private httpOptions;
  private logsReqResData = true; // Make it True For Debugging purpose only
  private _flexiApiURL = environment.flexiApiURL;
  private getKey = "4c0254db-rrmz-8092-cqcn-d6579d47cd88";
  private mongoKey;

  constructor(private http: HttpClient,
    private userAuthService: UserAuthService,
    private toastr: ToastrService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
  ) { 
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Key' : this.getKey
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
  this.http.get('https://www.titaniumsecure.io:3000/api/api-key', requestOptions
  ).subscribe(resp => {
      if(resp != null)
      {
          this.mongoKey = resp.toString();
      }
  });
  }

  private async _configHeaders(){
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/json');
    if(this.mongoKey){
      headers = headers.set('Key', this.mongoKey);
    }
    if (localStorage.getItem('0') && localStorage.getItem("id_token")) {
      headers = headers.set('Authorization', localStorage.getItem("id_token")); 

  }
    this.httpOptions = { headers };
  }
  // async generateSHA256Hash(input: string): Promise<string> {
  //   const encoder = new TextEncoder();
  //   const data = encoder.encode(input);
  //   const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  //   const hashArray = Array.from(new Uint8Array(hashBuffer));
  //   const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
  //   return hashHex;
  // }

  async getApiKey()
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Key' : this.getKey
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
  this.http.get('https://www.titaniumsecure.io:3000/api/api-key', requestOptions
  ).subscribe(resp => {
      if(resp != null)
      {
          return resp;
      }
  });
  }
  get(url, showToastr = false) {
    this._configHeaders();
    return this.http.get(this._flexiApiURL + url, this.httpOptions).pipe(
      tap((resp: any) => {
        if (resp.status === 'success' && showToastr) {
          this.showToastr('success', resp.message, '');
        } else if (resp.status === 'failed' && showToastr) {
          this.showToastr('error', resp.message, '');
        }
      }),
      map((resp: any) => {
        if (resp.data) {
          // With Decryption
          //resp.data = this.decrypt(resp.data);
          // Without Decryption
          resp.data = resp.data;
        }
        this.consoleLogData({ method: 'GET', url, resp });
        return resp;
      }),
      catchError(err => {
        this.handleError(err, showToastr);
        this.consoleLogData(err);
        return throwError(err);
      })
    );
  }
  getBody(url, data, showToastr = false) {
    this._configHeaders();
    return this.http.get(this._flexiApiURL + url, this.httpOptions).pipe(
      tap((resp: any) => {
        if (resp.status === 'success' && showToastr) {
          this.showToastr('success', resp.message, '');
        } else if (resp.status === 'failed' && showToastr) {
          this.showToastr('error', resp.message, '');
        }
      }),
      map((resp: any) => {
        if (resp.data) {
          // With Decryption
          //resp.data = this.decrypt(resp.data);
          // Without Decryption
          resp.data = resp.data;
        }
        this.consoleLogData({ method: 'GET', url, resp });
        return resp;
      }),
      catchError(err => {
        this.handleError(err, showToastr);
        this.consoleLogData(err);
        return throwError(err);
      })
    );
  }

  post(url, data, showToastr = false) {
    this._configHeaders();
    // With Ecryption
    return this.http.post(this._flexiApiURL + url, data, this.httpOptions)
      .pipe(
        // Without Ecryption
        // return this.http.post(this._baseApiUrl + url,  data , this.httpOptions).pipe(
        tap((resp: any) => {
          if (resp.status === 'success' && showToastr) {
            this.showToastr('success', resp.message, '');
          } else if (resp.status === 'failed' && showToastr) {
            this.showToastr('error', resp.message, '');
          }
          this.consoleLogData({ method: 'POST', url, data, resp });
        }),
        catchError(err => {
          this.handleError(err, showToastr);
          this.consoleLogData(err);
          return throwError(err);
        })
      );
  }
  put(url, data, showToastr = false) {
    this._configHeaders();
    // With Encryption
    return this.http.put(this._flexiApiURL + url, data, this.httpOptions)
      .pipe(
        // Without Encryption
        // return this.http.put(this._baseApiUrl + url, data, this.httpOptions).pipe(
        tap((resp: any) => {
          if (resp.status === 'success' && showToastr) {
            this.showToastr('success', resp.message, '');
          } else if (resp.status === 'failed' && showToastr) {
            this.showToastr('error', resp.message, '');
          }
          this.consoleLogData({ method: 'PUT', url, data, resp });
        }),
        catchError(err => {
          this.handleError(err, showToastr);
          this.consoleLogData(err);
          return throwError(err);
        })
      );
  }
  
  postRequest(url, data) {
    this._configHeaders();
    return this.http.post(this._flexiApiURL + url, data, this.httpOptions);
  }

  delete(url, showToastr = false) {
    this._configHeaders();
    return this.http.delete(this._flexiApiURL + url, this.httpOptions).pipe(
      tap((resp: any) => {
        if (resp.status === 'success' && showToastr) {
          this.showToastr('success', resp.message, '');
        } else if (resp.status === 'failed' && showToastr) {
          this.showToastr('error', resp.message, '');
        }
      }),
      map((resp: any) => {
        if (resp.data) {

          // With Decryption
          //resp.data = this.decrypt(resp.data);
          // Without Decryption
          resp.data = resp.data;
        }
        this.consoleLogData({ method: 'Delete', url, resp });
        return resp;
      }),
      catchError(err => {
        this.handleError(err, showToastr);
        this.consoleLogData(err);
        return throwError(err);
      })
    );
  }

  handleError(error, showToastr) {
    this.ngxService.stop();
    if (showToastr) {
      this.showToastr('error', error.message, error.status);
    }
    this.userAuthService.removeUser();
    if (error.status === 401) {
      this.router.navigate(['/login']);
    } else if (error.status === 500) {
      this.router.navigate(['/server-error']);
    } else if (error.status === 0) {
      this.router.navigate(['/server-error']);
    }
  }

  consoleLogData(data) {
    if (this.logsReqResData) {
      // console.log('------ API LOGS -----', data);
    }
  }

  showToastr(type, msg, title) {
    this.toastr[type](msg, title, {
      progressBar: true,
      progressAnimation: 'increasing',
      timeOut: 1500,
      positionClass: 'toast-bottom-right',
    });
  }
}
