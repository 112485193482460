<div class="wrapper">
  <app-header [headerTitle]="'Subscription'"></app-header>
  <div class="payment-history-page">
    <div class="backlink pointer"><a [routerLink]="[ '/dashboard' ]"><i class="fas fa-chevron-left"></i> Dashboard</a></div>
    <div class="map-form">
      <div class="login-form-group">
        <!--<h3>Subscription cancellation</h3>
        <p>Sorry to see you go, please let us know why</p>-->
        <div class="form-group" style="margin-top: 80px;">
          <h3>Subscription cancellation</h3>
          <p>Sorry to see you go, please let us know why</p>
          <form [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
            <ul>
              <li *ngFor="let reason of reasons; let i=index">
                <label>
                  <input #checkboxes type="checkbox" [value]="reason.value" (change)="onCheckboxChange($event)" />
                  {{reason.name}}
                </label>
              </li>

              <li *ngIf="showTextarea">
                <textarea formControlName="textarea" placeholder="Please specify your reason."></textarea>
              </li>

              <li *ngIf="error" class="show__alert__message">
                <div class="alert alert-danger">
                  {{error}}
                </div>
              </li>
                <li>
                <button class="btn btn-blue" style = "text-align: center; margin-bottom:30px;" type="submit">Unsubscribe and keep my account</button>
                <button class="btn btn-orange" style = "align-items: center; margin-bottom:30px;" type="delete" (click)="deleteAndCancelSubscription()" >Unsubscribe and delete my account</button>
              </li>
              <li></li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
