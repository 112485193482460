import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserService } from '../shared/services/user.service';
import { AcceptJSService } from '@openutility/acceptjs-angular-wrapper';
import { UserAuthService } from '../shared/services/auth/user-auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { CommonHttpClient } from '../shared/services/common/common-http.service';
@Component({
  selector: 'app-validation-link',
  templateUrl: './validation-link.component.html',
  styleUrls: ['./validation-link.component.css']
})
export class ValidationLinkComponent implements OnInit {
  thankyou: boolean = false;
  fam_id: string;
  error: boolean;
  confirmation_token: string;
  email: string;
  param: string;
  phone: string;
  user: any;
  subHistory: any;
  showNoData: boolean = false;
  apiCalled: boolean = false;
  rate_id: any;
  plan_id: any;
  planName: any;
  errorMessage: string;
  individualPlan: boolean;

  constructor(private userService: UserService, private userAuth: UserAuthService,
    private ngxloader: NgxUiLoaderService, route: ActivatedRoute, private ccService: NgcCookieConsentService, private commonHttpInit: CommonHttpClient,
    private router: Router) {
    this.param = route.snapshot.params.param;
    this.confirmation_token = route.snapshot.params.token;
    this.user = this.userAuth.getStoredUser();
    this.initCommonHTTP();
    this.getSubscriptionId();
  }

  ngOnInit(): void {
    this.ccService.destroy();
  }
  async initCommonHTTP()
  {
    this.ngxloader.start();
    var key = await this.commonHttpInit.getApiKey()
      if(key){
        this.checkIfTokenIsValid();
      }   
  }
  checkIfTokenIsValid()
  {
    if (this.param.includes("@")) {
      this.email = this.param;
    }
    else {
      this.phone = this.param
    }
    if (this.email) {
      
    this.userService.verifyTokenByEmail(this.email, this.confirmation_token).subscribe(resp => {
      if (resp.message === 'token matches!') {
        this.fam_id = resp.fam_id;
        this.userService.activateMongoUserByEmail(this.email).subscribe((resp) => {
          if (resp.message === 'activated') {
            this.userService.activateFlexisipUser(this.fam_id).subscribe((resp) => {
              this.thankyou = true;
              this.ngxloader.stop();
            });
          }
        });
      }
      else {
        this.thankyou = false;
        this.error = true;
        this.ngxloader.stop();
      }
    });
    }
    if (this.phone) {      
      this.userService.verifyTokenByPhone(this.phone, this.confirmation_token).subscribe((resp) => {
        if (resp.message === 'token matches!') {
          this.fam_id = resp.fam_id;
          this.userService.activateMongoUserByPhone(this.phone).subscribe((resp) => {
            if (resp.message === 'activated') {
              this.userService.activateFlexisipUser(this.fam_id).subscribe((resp) => {
                this.thankyou = true;
                this.ngxloader.stop();
              });
            }
          });
        }
        else {
          this.thankyou = false;
          this.error = true;
          this.ngxloader.stop();
        }
      });
    }
  }

  goTOHome() {
    this.router.navigate(['/'])
  }

  getSubscriptionId() {
    if (this.param.includes("@")) {
      this.email = this.param;
    }
    else {
      this.phone = this.param
    }
    if (this.email) {
        this.userService.getSubscriptionIdWithEmail(this.email).subscribe(resp => {
          if (resp.message === 'success') {
            this.rate_id = resp.rate;
            this.userService.getRateAmount(this.rate_id).subscribe(rateResp => {
              this.plan_id = rateResp.PlanId;
              this.userService.getPlanName(this.plan_id).subscribe(planResp => {
                this.planName = planResp.Name;
                if (this.planName == 'Individual') {
                  this.individualPlan = true;
                }
              }, error => { })
            }, error => { })
          }
        }, error => { this.errorMessage = 'Error!' })
    }
    if (this.phone) {
      this.userService.getSubscriptionIdWithPhone(this. phone).subscribe(resp => {
        if (resp.message === 'success') {
          this.rate_id = resp.rate;
          this.userService.getRateAmount(this.rate_id).subscribe(rateResp => {
            this.plan_id = rateResp.PlanId;
            this.userService.getPlanName(this.plan_id).subscribe(planResp => {
              this.planName = planResp.Name;
              if (this.planName == 'Individual') {
                this.individualPlan = true;
              }
            }, error => { })
          }, error => { })
        }
      }, error => { this.errorMessage = 'Error!' })
    }
  }
}
