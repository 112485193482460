import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserService } from '../shared/services/user.service';
import { Location } from '@angular/common';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { Subscription, timer } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
declare function viewPassword(): any;
@Component({
  selector: 'ngbd-modal-content',
  template: `
		<div class="modal-header">
			<h4 style="color:black" class="modal-title">Profile update</h4>
			<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
		</div>
		<div class="modal-body">
			<h5 style="color:black">Your phone number has been successfully updated.</h5>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-blue submitBtn" (click)="activeModal.close('Close click')">OK</button>
		</div>
	`,
})

export class NgbdModalContent {
  constructor(public activeModal: NgbActiveModal) { }
}

@Component({
  selector: 'app-change-phonenumber-embedded',
  templateUrl: './change-phonenumber-embedded.component.html',
  styleUrls: ['./change-phonenumber-embedded.component.css'],
  animations: [
    trigger(
      'slideView',
      [
        state('true', style({ transform: 'translateX(100%)', opacity: 0 })),
        state('false', style({ transform: 'translateX(0)', opacity: 1 })),
        transition('0 => 1', animate('500ms', style({ transform: 'translateX(0)', 'opacity': 1 }))),
        transition('1 => 1', animate('500ms', style({ transform: 'translateX(100%)', 'opacity': 0 }))),
      ]),

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('600ms ease-in', style({ transform: 'translateX(0%)', 'opacity': 1 }))
      ]),

      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('0ms ease-in', style({ transform: 'translateX(100%)', 'opacity': 0 }))
      ])
    ])
  ]
})
export class ChangePhonenumberEmbeddedComponent implements OnInit {
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 4,
    autofocus: true,
    classList: {
      inputBox: "my-super-box-class",
      input: "my-super-class",
      inputFilled: "my-super-filled-class",
      inputDisabled: "my-super-disable-class",
      inputSuccess: "my-super-success-class",
      inputError: "my-super-error-class"
    }
  };
  //get from html forms
  signSubmit: boolean = false;
  newPhoneNumber: string;
  error: string;
  changePhoneForm: FormGroup;
  closeResult = '';
  emailOTP: boolean = false;
  phoneOTP: boolean = false;
  ConfirmationToken: string;
  OTPcounter: number = 0;
  response: string;
  localPhone = '';
  localEmail = '';
  //variables for int number 
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];
  ////end
  //to Display otp input
  windowRef: any;
  showOTP: boolean = false; //this should false when init
  showResendOTP: boolean = false // to show resend otp button -- should be false when init

  //for otp timer
  countDown: Subscription;
  counter = 120;
  tick = 1000;
  success: any;
  changePhoneByPhoneCheck: boolean;
  teststring = ''
  ////
  constructor(private userService: UserService, private _location: Location, private router: Router, private ngxloader: NgxUiLoaderService,
    private modalService: NgbModal) {
    this.initChangePhoneNumberForm();
  }

  ngOnInit(): void {
  }

  changePhoneNumber() {
    if (this.localPhone != null || this.localEmail != null) {
      this.ngxloader.start();
      if (localStorage.getItem('0').includes('@')) {
        var email = this.localEmail;
        let phone = this.changePhoneForm.get('phone_number').value;
        this.newPhoneNumber = this.removePlusFromstring(phone.e164Number);
        let dataFam = {
          phone_number: phone.e164Number,
          password: this.changePhoneForm.get('password').value
        }
        let dataMongo = {
          phone_number: this.removePlusFromstring(phone.e164Number),
          password: this.changePhoneForm.get('password').value
        }
        this.userService.changePhoneByEmail(email, dataMongo).subscribe(resp => {
          if (resp.message === 'Phone number changed!') {
            //fam request
            this.userService.changePhoneNumberMariaAPIByEmail(email, dataFam).subscribe(resp => {
              if (resp.message === 'Phone number changed successfully!') {
                this.ngxloader.stop();
                this.open();
                // this.userService.deleteTempPhoneRecord(this.newPhoneNumber).subscribe(resp => {

                // });
              }
            })
          }
        }, error => { this.error = 'Error changing Phone number!' })
      }

      if (!localStorage.getItem('0').includes('@')) {
        var phone = this.localPhone;
        let phoneNew = this.changePhoneForm.get('phone_number').value;
        this.newPhoneNumber = this.removePlusFromstring(phoneNew.e164Number);
        this.makeString()
        let dataFam = {
          phone_number: phoneNew.e164Number,
          token: this.result,
          password: this.changePhoneForm.get('password').value
        }
        let dataMongo = {
          phone_number: this.removePlusFromstring(phoneNew.e164Number),
          password: this.changePhoneForm.get('password').value
        }
        this.userService.changePhoneByPhone(phone, dataMongo).subscribe(resp => {
          if (resp.message === 'Phone number changed!') {
            //fam request
            this.userService.changePhoneNumberMariaAPIByPhoneProv(phone, dataFam).subscribe(resp => {
              if (resp.message === 'Phone number changed successfully!') {                
                this.openExceptionProv();
                this.ngxloader.stop();
                //localStorage.setItem('0', this.removePlusFromstring(this.newPhoneNumber));
                // this.userService.deleteTempPhoneRecord(this.newPhoneNumber).subscribe(resp => {
                // });
              }
            })
          }
        }, error => { this.error = 'Error changing Phone number!' })
      }
    }
    else {
      this.error = 'Error changing Phone number!'
    }
  }

  get f() {
    return this.changePhoneForm.controls;
  }

  initChangePhoneNumberForm() {
    this.changePhoneForm = new FormGroup({
      phone_number: new FormControl('', [Validators.required, Validators.nullValidator, Validators.maxLength(50)]),
      password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_={}|:;"'<,>.])[A-Za-z\d@$!%*?&~`#^()_={}|:;"'<,>.]{8,}$/)]),
      OtpCode: new FormControl('', [Validators.required])
    });
  }
  backClicked() {
    if (this.router.navigated) {
      this._location.back();
    }


  }

  verifyUserSubscription() {
    this.clearInfoMessages();
    if (!localStorage.getItem('0').includes('@')) {
      this.localPhone = localStorage.getItem('0')
    }
    else {
      this.localEmail = localStorage.getItem('0')
    }
    let phone = this.changePhoneForm.get('phone_number').value;
    this.newPhoneNumber = this.removePlusFromstring(phone.e164Number);
    if (phone) {
      let data = {
        phone_number: this.removePlusFromstring(phone.e164Number),
      }
      this.userService.validateUserPhoneNumber(data).subscribe(resp => {
        this.ngxloader.start();
        if (resp.message === 'success (phone number already exists)') {
          this.response = resp.message;
          this.error = "This number is already registered";
          this.ngxloader.stop();
        }
      }, err => {
        this.ngxloader.start();
        this.userService.getPhoneOTP(this.newPhoneNumber).subscribe(resp => {
          if (resp.OTP) {
            this.userService.sendPhoneOTP(this.newPhoneNumber).subscribe(resp => {
              if (resp.message === 'Phone OTP was successfully sent!') {
                this.phoneOTP = true;
                this.showOTP = true;
                this.startOTPTimer();
                var data = {
                  otp: resp.otp,
                  phone: this.newPhoneNumber,
                  otp_sent_counter: 0,
                  otp_sent_date: new Date(),
                  telesign_ref_id: resp.telesign_ref_id
                }
  
              }
              this.ngxloader.stop();
            });
          }
          else {
            this.userService.sendPhoneOTP(this.newPhoneNumber).subscribe(resp => {
              if (resp.message === 'Phone OTP was successfully sent!') {
                this.phoneOTP = true;
                this.showOTP = true;
                this.startOTPTimer();
                var data = {
                  otp: resp.otp,
                  phone_number: this.newPhoneNumber,
                  otp_sent_counter: this.OTPcounter,
                  otp_sent_date: new Date(),
                  telesign_ref_id: resp.telesign_ref_id
                }
  
              }
              this.ngxloader.stop();
            });
          }
        });
      });
    }
  }

  checkPhoneOTP() {
    
      let OTPinput = this.changePhoneForm.get('OtpCode').value;
      this.userService.checkPhoneOTP(this.newPhoneNumber, OTPinput).subscribe(resp => {
        /* if (res.OTP) {*/
        if (resp.message == 'OTP matches!') {

          this.clearInfoMessages();
          this.changePhoneNumber();
          this.showOTP = false;
          //delete temptable record
          // this.userService.deleteTempPhoneRecord(this.newPhoneNumber).subscribe(resp => {            

          // });
        }

        else {
          this.error = "Invalid Code!"
        }
      }

      )
    


  }

  resendOTP() {
    let phone = this.changePhoneForm.get('phone_number').value;

    if (phone) {
      this.ngxloader.start();
      let phone_number = this.removePlusFromstring(phone.e164Number);
      this.userService.getPhoneOTP(phone_number).subscribe(resp => {
        this.OTPcounter = resp.counter;
        if (this.OTPcounter < 3) {
          this.userService.sendPhoneOTP(phone_number).subscribe(resp => {
            if (resp.message === 'Phone OTP was successfully sent!') {
              this.showOTP = true;
              this.startOTPTimer();
              this.ngxloader.stop();
              this.OTPcounter = this.OTPcounter + 1;
              var data = {
                telesign_ref_id: resp.telesign_ref_id,
                otp: resp.otp,
                phone: phone_number,
                otp_sent_counter: this.OTPcounter,
                otp_sent_date: new Date()
              }

              this.ngxloader.stop();
            } else {
              this.ngxloader.stop();
            }
          });
        }
        else {
          var dateHours;
          var DateNow = new Date();
          var dateSpan = new Date(resp.OTPSentDate);
          if (dateSpan.getHours() != 23) {
            dateHours = dateSpan.getHours() + 1
          }
          else {
            dateHours = 0;
          }
          dateSpan.setHours(dateHours);
          if (DateNow >= dateSpan) {
            this.ngxloader.start();
            this.userService.sendPhoneOTP(phone_number).subscribe(resp => {
              if (resp.message === 'Phone OTP was successfully sent!') {
                this.showOTP = true;
                this.startOTPTimer();
                this.OTPcounter = this.OTPcounter + 1;
                var data = { otp: resp.otp, phone: phone_number, otp_sent_counter: 0, otp_sent_date: new Date() }
  
                this.ngxloader.stop();
                this.clearInfoMessages();
              }
            });
            this.ngxloader.stop();
          }
          else {
            this.error = "You must wait an hour before resending again!";
            this.ngxloader.stop();
          }
        }
      });
    }
  }

  open() {
    const modalRef = this.modalService.open(NgbdModalContent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.backClicked();
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.backClicked();
      },
    );
  }
  openException() {
    const modalRef = this.modalService.open(NgbdModalContent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        var nav = '/embedded-dashboard/' + this.newPhoneNumber
        //localStorage.clear();
        this.router.navigate([nav]);

      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        var nav = '/embedded-dashboard/' + this.newPhoneNumber
        // localStorage.clear();
        this.router.navigate([nav]);
      },
    );
  }
  openExceptionProv() {
    var phone = localStorage.getItem('0')
    const modalRef = this.modalService.open(NgbdModalContent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.ngxloader.start();
        this.userService.generateXMLFileFAM(this.localPhone, this.result)
        .subscribe((resp) => {
          this.ngxloader.stop();
           window.location.href = 'https://www.titaniumsecure.io:3000/api/xml/' + resp.filename;
          // window.location.href = 'https://sip2.titaniumsecure.io/flexiapi/provisioning/' + this.ProvisioningToken;
        });

      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.ngxloader.start(); 
        this.userService.generateXMLFileFAM(this.localPhone, this.result)
        .subscribe((resp) => {
          this.ngxloader.stop();          
           window.location.href = 'https://www.titaniumsecure.io:3000/api/xml/' + resp.filename;           
          // window.location.href = 'https://sip2.titaniumsecure.io/flexiapi/provisioning/' + this.ProvisioningToken;
        });
      },
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  removePlusFromstring(phoneNumber) {
    return phoneNumber.split('+').join('')
  }

  handeOtpChange(value: string[]): void {
    value.forEach(input => {
      if (input == '') {
        this.changePhoneForm.get('OtpCode').setValue(null);
      }
    });
  }

  handleFillEvent(value: string): void {

    this.changePhoneForm.get('OtpCode').setValue(value);

    //for auto-login 
    // setTimeout(() => {
    //   this.verifyLoginCode();  
    // });

  }

  resetOTPTimer() {
    this.showResendOTP = false;
    this.counter = 120
  }

  startOTPTimer() {
    this.resetOTPTimer();
    this.countDown = timer(0, this.tick).subscribe((count) => {
      if (count == 120) {
        this.showResendOTP = true;
        if (this.countDown) {
          this.countDown.unsubscribe();
        }
      }
      --this.counter;
    });
  }

  clearInfoMessages() {
    this.error = null;
    this.success = null;
  }

  makeString(): string {
    let outString: string = '';
    let inOptions: string = 'abcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 10; i++) {

      outString += inOptions.charAt(Math.floor(Math.random() * inOptions.length));

    }
    this.teststring = outString;
    return outString;
  }

  result: string = this.makeString();
}
